import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Functions from "../../../utils/Functions";
import {Alert, Box, Chip, Grid, Stack, Typography} from "@mui/material";
import Utils from "../../../utils/Utils";
import {
    getFreelanceAdrByJob,
    getFreelanceByJob, getFreelanceByRecommendation,
    getFreelanceBySeniority,
    getFreelanceBySkill,
    getFreelanceBySource, getFreelanceByTag,
    getFreelanceByUser,
    getFreelanceData,
    getMissionByFreelance
} from "../../../services/reportingService";
import {StackedBarChart} from "../../UI/Chart/StackedBarChart";
import WordCloudChart from "../../UI/Chart/WordCloudChart";
import {DonutChart} from "../../UI/Chart/DonutChart";

export const FreelanceBoard = () => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const sessionType = useSelector((state) => state.auth.sessionType);
    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    const [freelanceData, setFreelanceData] = useState({
        count: 0,
        useRate: 0,
        recommendation: 0,
        satisfaction: 0
    });
    const [freelanceByJob, setFreelanceByJob] = useState({
        jobs: [],
        freelance: []
    });
    const [freelanceBySkill, setFreelanceBySkill] = useState([]);
    const [adrByJob, setAdrByJob] = useState({
        jobs: [],
        rate: []
    });
    const [freelanceBySeniority, setFreelanceBySeniority] = useState({
        seniorities: [],
        freelance: []
    });
    const [freelanceByUser, setFreelanceByUser] = useState({
        users: [],
        freelance: []
    });
    const [freelanceByReportingEntity, setFreelanceByReportingEntity] = useState({
        tags: [],
        freelance: []
    });
    const [freelanceByTag, setFreelanceByTag] = useState({
        tags: [],
        freelance: []
    });
    const [freelanceBySource, setFreelanceBySource] = useState({
        sources: [],
        freelance: []
    });
    const [missionByFreelance, setMissionByFreelance] = useState({
        freelances: [],
        mission: []
    });
    const [freelanceByRecommendation, setFreelanceByRecommendation] = useState({
        nps: [],
        freelance: []
    });

    useEffect(() => {
        if (isEnterpriseSession) {
            getFreelanceData({}, token).then(data => {
                if (!data?.error) {
                    setFreelanceData(data);
                }
            });

            getFreelanceByJob({}, token).then(data => {
                if (!data?.error) {
                    setFreelanceByJob(data);
                }
            });

            getFreelanceBySkill({}, token).then(data => {
                if (!data?.error) {
                    setFreelanceBySkill(data);
                }
            });

            getFreelanceAdrByJob({}, token).then(data => {
                if (!data?.error) {
                    setAdrByJob(data);
                }
            });

            getFreelanceBySeniority({}, token).then(data => {
                if (!data?.error) {
                    setFreelanceBySeniority(data);
                }
            });

            getFreelanceByUser({}, token).then(data => {
                if (!data?.error) {
                    setFreelanceByUser(data);
                }
            });

            getFreelanceByTag({type: Functions.TAGS_TYPE.FREELANCE_REPORTING_ENTITY}, token).then(data => {
                if (!data?.error) {
                    setFreelanceByReportingEntity(data);
                }
            });

            getFreelanceByTag({type: Functions.TAGS_TYPE.FREELANCE_TAG}, token).then(data => {
                if (!data?.error) {
                    setFreelanceByTag(data);
                }
            });

            getFreelanceBySource({}, token).then(data => {
                if (!data?.error) {
                    setFreelanceBySource(data);
                }
            });

            getMissionByFreelance({}, token).then(data => {
                if (!data?.error) {
                    setMissionByFreelance(data);
                }
            });

            getFreelanceByRecommendation({}, token).then(data => {
                if (!data?.error) {
                    setFreelanceByRecommendation(data);
                }
            });
        }
    }, [token]);


    return (
        <Box id="freelanceBoard" className="w-100">
            {isEnterpriseSession && (
                <Stack direction="column" className="w-100" alignItems="start" gap={4}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} lg={4} container spacing={2}>
                            <Grid item xs={6} className="h-100">
                                <div className="reporting-card h-100" style={{justifyContent: "space-between"}}>
                                    <Typography className="title">{t("Number of contingent workers")}</Typography>
                                    <Typography variant="label" color="primary.light">
                                        {freelanceData.count || "-"}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6} className="h-100">
                                <div className="reporting-card h-100" style={{justifyContent: "space-between"}}>
                                    <Typography className="title">{t("Use rate")}</Typography>
                                    <Typography variant="label" color="primary.light">
                                        {freelanceData.useRate ? `${freelanceData.useRate.toFixed(1)}%` : "-"}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4} container spacing={2}>
                            <Grid item xs={6} className="h-100">
                                <div className="reporting-card h-100" style={{justifyContent: "space-between"}}>
                                    <Typography
                                        className="title">{t("Contingent worker's recommendation level")}</Typography>
                                    <Typography variant="label">
                                        {
                                            freelanceData.recommendation
                                                ? <Chip
                                                    label={`${freelanceData.recommendation.toFixed(1)}`}
                                                    className={`tag-recommendation-level ${Utils.getRecommendationStatus(
                                                        parseFloat(freelanceData.recommendation)
                                                    )}`}
                                                />
                                                : "-"
                                        }
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6} className="h-100">
                                <div className="reporting-card h-100" style={{justifyContent: "space-between"}}>
                                    <Typography className="title">{t("Contingent worker's satisfaction level")}</Typography>
                                    <Typography variant="label">
                                        {
                                            freelanceData.satisfaction
                                                ? <Chip
                                                    label={`${freelanceData.satisfaction.toFixed(1)}`}
                                                    className={`tag-recommendation-level ${Utils.getRecommendationStatus(
                                                        parseFloat(freelanceData.satisfaction)
                                                    )}`}
                                                />
                                                : "-"
                                        }
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Box className="w-100">
                        <Typography variant="label" color="primary.light"
                                    sx={{mb: 1}}>{t("Pool of contingent workers")}</Typography>
                        <Stack direction="column" className="w-100" alignItems="start" gap={4}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} lg={4}>
                                    <div className="reporting-card h-100">
                                        <Typography className="title">{t("Jobs represented")}</Typography>
                                        <Box className="reporting-content">
                                            {freelanceByJob.jobs.length > 0
                                                ? (
                                                    <StackedBarChart
                                                        horizontal={true}
                                                        categories={freelanceByJob.jobs}
                                                        series={[
                                                            {
                                                                name: t("Number of contingent workers"),
                                                                data: freelanceByJob.freelance,
                                                                color: 'var(--info-main-color)'
                                                            }
                                                        ]}
                                                    />
                                                )
                                                : (
                                                    <Stack justifyContent="center" className="h-100">
                                                        <Alert icon={false} severity="info" sx={{width: '100%'}}>
                                                            {t("No data available")}
                                                        </Alert>
                                                    </Stack>
                                                )
                                            }
                                        </Box>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <div className="reporting-card h-100">
                                        <Typography className="title">{t("Skills represented")}</Typography>
                                        <Box className="reporting-content">
                                            {freelanceBySkill.length > 0
                                                ? (
                                                    <WordCloudChart
                                                        data={freelanceBySkill}
                                                        height={350}
                                                    />
                                                )
                                                : (
                                                    <Stack justifyContent="center" className="h-100">
                                                        <Alert icon={false} severity="info" sx={{width: '100%'}}>
                                                            {t("No data available")}
                                                        </Alert>
                                                    </Stack>
                                                )
                                            }
                                        </Box>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={4}>
                                <Grid item xs={12} lg={4}>
                                    <div className="reporting-card h-100">
                                        <Typography
                                            className="title">{t("ADR by job")}</Typography>
                                        <Box className="reporting-content">
                                            {adrByJob.jobs.length > 0
                                                ? (
                                                    <StackedBarChart
                                                        horizontal={true}
                                                        currency="€"
                                                        categories={adrByJob.jobs}
                                                        series={[
                                                            {
                                                                name: t("ADR"),
                                                                data: adrByJob.rate,
                                                                color: 'var(--primary-light-color)'
                                                            }
                                                        ]}
                                                    />
                                                )
                                                : (
                                                    <Stack justifyContent="center" className="h-100">
                                                        <Alert icon={false} severity="info" sx={{width: '100%'}}>
                                                            {t("No data available")}
                                                        </Alert>
                                                    </Stack>
                                                )
                                            }
                                        </Box>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <div className="reporting-card h-100">
                                        <Typography
                                            className="title">{t("Seniority of contingent workers")}</Typography>
                                        <Box className="reporting-content">
                                            {freelanceBySeniority.seniorities.length > 0
                                                ? (
                                                    <StackedBarChart
                                                        chartHeight={305}
                                                        categories={freelanceBySeniority.seniorities.map(item => t(item))}
                                                        series={[
                                                            {
                                                                name: t("Number of contingent workers"),
                                                                data: freelanceBySeniority.freelance,
                                                                color: 'var(--secondary-main-color)'
                                                            }
                                                        ]}
                                                        customOptions={{
                                                            showTotal: false
                                                        }}
                                                    />
                                                )
                                                : (
                                                    <Stack justifyContent="center" className="h-100">
                                                        <Alert icon={false} severity="info" sx={{width: '100%'}}>
                                                            {t("No data available")}
                                                        </Alert>
                                                    </Stack>
                                                )
                                            }
                                        </Box>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={4}>
                                <Grid item xs={12} lg={4}>
                                    <div className="reporting-card h-100">
                                        <Typography
                                            className="title">{t("Number of contingent workers per user")}</Typography>
                                        <Box className="reporting-content">
                                            {freelanceByUser.users.length > 0
                                                ? (
                                                    <StackedBarChart
                                                        horizontal={true}
                                                        categories={freelanceByUser.users}
                                                        series={[
                                                            {
                                                                name: t("Number of contingent workers"),
                                                                data: freelanceByUser.freelance,
                                                                color: '#919191'
                                                            }
                                                        ]}
                                                    />
                                                )
                                                : (
                                                    <Stack justifyContent="center" className="h-100">
                                                        <Alert icon={false} severity="info" sx={{width: '100%'}}>
                                                            {t("No data available")}
                                                        </Alert>
                                                    </Stack>
                                                )
                                            }
                                        </Box>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <div className="reporting-card h-100">
                                        <Typography
                                            className="title">{t("Number of contingent workers per entity/team")}</Typography>
                                        <Box className="reporting-content">
                                            {freelanceByReportingEntity.tags.length > 0
                                                ? (
                                                    <StackedBarChart
                                                        horizontal={true}
                                                        categories={freelanceByReportingEntity.tags}
                                                        series={[
                                                            {
                                                                name: t("Number of contingent workers"),
                                                                data: freelanceByReportingEntity.freelance,
                                                                color: 'var(--info-main-color)'
                                                            }
                                                        ]}
                                                    />
                                                )
                                                : (
                                                    <Stack justifyContent="center" className="h-100">
                                                        <Alert icon={false} severity="info" sx={{width: '100%'}}>
                                                            {t("No data available")}
                                                        </Alert>
                                                    </Stack>
                                                )
                                            }
                                        </Box>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={4}>
                                <Grid item xs={12} lg={4}>
                                    <div className="reporting-card h-100">
                                        <Typography
                                            className="title">{t("Number of contingent workers per tag")}</Typography>
                                        <Box className="reporting-content">
                                            {freelanceByTag.tags.length > 0
                                                ? (
                                                    <StackedBarChart
                                                        horizontal={true}
                                                        categories={freelanceByTag.tags}
                                                        series={[
                                                            {
                                                                name: t("Number of contingent workers"),
                                                                data: freelanceByTag.freelance,
                                                                color: 'var(--info-main-color)'
                                                            }
                                                        ]}
                                                    />
                                                )
                                                : (
                                                    <Stack justifyContent="center" className="h-100">
                                                        <Alert icon={false} severity="info" sx={{width: '100%'}}>
                                                            {t("No data available")}
                                                        </Alert>
                                                    </Stack>
                                                )
                                            }
                                        </Box>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <div className="reporting-card h-100">
                                        <Typography
                                            className="title">{t("Source of contingent worker")}</Typography>
                                        <Box className="reporting-content">
                                            {freelanceBySource.sources.length > 0
                                                ? (
                                                    <DonutChart
                                                        chartHeight={305}
                                                        categories={freelanceBySource.sources.map(
                                                            item => t(Utils.getParameterValue(item, "ProfileSourceType"))
                                                        )}
                                                        series={freelanceBySource.freelance}
                                                    />
                                                )
                                                : (
                                                    <Stack justifyContent="center" className="h-100">
                                                        <Alert icon={false} severity="info" sx={{width: '100%'}}>
                                                            {t("No data available")}
                                                        </Alert>
                                                    </Stack>
                                                )
                                            }
                                        </Box>
                                    </div>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>

                    <Box className="w-100">
                        <Typography variant="label" color="primary.light"
                                    sx={{mb: 1}}>{t("Contingent worker's assignments")}</Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={4}>
                                <div className="reporting-card h-100">
                                    <Typography
                                        className="title">{t("Number of assignments per contingent worker")}</Typography>
                                    <Box className="reporting-content">
                                        {missionByFreelance.freelances.length > 0
                                            ? (
                                                <StackedBarChart
                                                    horizontal={true}
                                                    categories={missionByFreelance.freelances}
                                                    series={[
                                                        {
                                                            name: t("Number of contingent workers"),
                                                            data: missionByFreelance.mission,
                                                            color: 'var(--primary-light-color)'
                                                        }
                                                    ]}
                                                />
                                            )
                                            : (
                                                <Stack justifyContent="center" className="h-100">
                                                    <Alert icon={false} severity="info" sx={{width: '100%'}}>
                                                        {t("No data available")}
                                                    </Alert>
                                                </Stack>
                                            )
                                        }
                                    </Box>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <div className="reporting-card h-100">
                                    <Typography
                                        className="title">{t("Contingent workers by average rating received")}</Typography>
                                    <Box className="reporting-content">
                                        {freelanceByRecommendation.nps.length > 0
                                            ? (
                                                <StackedBarChart
                                                    chartHeight={305}
                                                    categories={freelanceByRecommendation.nps}
                                                    series={[
                                                        {
                                                            name: t("Number of contingent workers"),
                                                            data: freelanceByRecommendation.freelance
                                                        }
                                                    ]}
                                                    customOptions={{
                                                        showTotal: false,
                                                        colors: ['#FD6939', '#F4C741', '#6BCBA0']
                                                    }}
                                                />
                                            )
                                            : (
                                                <Stack justifyContent="center" className="h-100">
                                                    <Alert icon={false} severity="info" sx={{width: '100%'}}>
                                                        {t("No data available")}
                                                    </Alert>
                                                </Stack>
                                            )
                                        }
                                    </Box>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
            )}
        </Box>
    )
};