import React, {useEffect, useState} from 'react';
import {FieldHolder} from "../FieldHolder";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {MessageComponent} from "../../UI/MessageComponent";
import {fetchCompany, updateCompany} from "../../../services/companyService";

export const AssignmentManagement = ({message, setMessage}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const companyId = useSelector((state) => state.auth.companyId);

    const [formData, setFormData] = useState({});

    useEffect(() => {
        fetchCompany(companyId, token).then((fetchedCompany) => {
            if (!fetchedCompany?.error) {
                setFormData(fetchedCompany);
            }
        });
    }, [token, companyId]);

    const handleMissionContractInvoiceChange = async (e) => {
        const newFormData = {
            missionContractInvoiceCreation: e.target.value
        };

        try {
            const company = await updateCompany({
                companyId: companyId,
                companyData: newFormData,
                token: token
            });
            if (!company.error) {
                setFormData(newFormData);
            }
        } catch (error) {
            setMessage({type: "error", message: "Error saving data"});
        }
    };

    return <>
        <div className="standardform standardform-w55 card transparent">
            <div className="w-100">
                <h3 className="text-title text-theme">
                    {t("Assignment management")}
                </h3>
            </div>

            <MessageComponent
                type={message.type}
                message={message.message}
                width="90%"
            />

            <FieldHolder
                fielddesc={t("Create assignment with contracts and invoices")}
                type="switch"
                name="missionContractInvoiceCreation"
                id="missionContractInvoiceCreation"
                value={formData.missionContractInvoiceCreation}
                onChange={handleMissionContractInvoiceChange}
            />
        </div>
    </>
};