import {Grid} from "@mui/material";
import {FieldHolder} from "../../FieldHolder";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CustomCard} from "../../../UI/CustomCard";

export const ContractualisationSection = (
    {
        formData,
        onSave,
        canModify
    }
) => {
    const {t} = useTranslation();

    const [editMode, setEditMode] = useState(false);
    const [sectionFormData, setSectionFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        if (editMode) {
            setFormErrors({});
        }
    }, [editMode]);

    useEffect(() => {
        setSectionFormData({...formData});
    }, [formData]);

    const handleSave = async () => {
        const result = onSave && await onSave({...formData, ...sectionFormData});
        if (result?.error) {
            setFormErrors(result.data);
        } else {
            setEditMode(false);
        }
    };

    return <>
        <CustomCard
            className="justify-content-center"
            editMode={editMode}
            setEditMode={setEditMode}
            canModify={canModify}
            onSave={handleSave}
        >
            <Grid container spacing={{xs: 2, md: 3}}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Type of contractualization")}
                        type="dropdown"
                        name="contractualisation_type"
                        id="contractualisation_type"
                        set="ContractualisationType"
                        value={sectionFormData?.contractualisation_type}
                        formData={sectionFormData}
                        setFormData={setSectionFormData}
                        canModify={editMode}
                        mandatoryFlag={true}
                        error={formErrors.contractualisation_type}
                    />
                </Grid>
            </Grid>
        </CustomCard>
    </>
};