import {Box, Grid, Stack, Typography} from "@mui/material";
import {FieldHolder} from "../../FieldHolder";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CustomCard} from "../../../UI/CustomCard";
import {UserCard} from "../../../UI/UserCard";
import {useSelector} from "react-redux";
import {fetchBasicFreelances} from "../../../../services/freelanceProfileService";

export const ContingentWorkerSection = (
    {
        formData,
        onSave,
        canModify
    }
) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [editMode, setEditMode] = useState(false);
    const [sectionFormData, setSectionFormData] = useState({});
    const [missionStaffedFreelances, setMissionStaffedFreelances] = useState([]);
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        setSectionFormData({...formData});
    }, [formData]);

    useEffect(() => {
        if (editMode) {
            setFormErrors({});
        }
    }, [editMode]);

    useEffect(() => {
        fetchBasicFreelances({}, token).then((fetchedData) => {
            if (!fetchedData?.error) {
                const fetchedFreelanceProfilesData = fetchedData.data.map(({User, ...rest}) => ({
                    id: rest.id,
                    name: User.fullName,
                    User
                }));
                setMissionStaffedFreelances(fetchedFreelanceProfilesData);
            }
        });
    }, [token, t]);

    const handleSave = async () => {
        const result = onSave && await onSave({...formData, ...sectionFormData});
        if (result?.error) {
            setFormErrors(result.data);
        } else {
            setEditMode(false);
        }
    };

    return <>
        <CustomCard
            className="justify-content-center"
            editMode={editMode}
            setEditMode={setEditMode}
            canModify={canModify && formData?.id && !formData?.staffedFreelanceId}
            onSave={handleSave}
        >
            <Grid container spacing={{xs: 2, md: 3}}>
                <Grid item container justifyContent="start" xs={12}>
                    {canModify && !formData?.staffedFreelanceId ? (
                        <FieldHolder
                            fielddesc={t("Staffed contingent worker")}
                            type="dropdown"
                            name="staffedFreelanceId"
                            id="staffedFreelanceId"
                            value={sectionFormData?.staffedFreelanceId}
                            datas={missionStaffedFreelances}
                            formData={sectionFormData}
                            setFormData={setSectionFormData}
                            canModify={editMode}
                            mandatoryFlag={true}
                            error={formErrors.staffedFreelanceId}
                            renderOption={(props, option) => (
                                <Box component="li" key={option.id}
                                     sx={{display: 'flex', alignItems: 'center'}} {...props}>
                                    <UserCard user={option.User} displaySkeleton={true}/>
                                </Box>
                            )}
                        />
                    ) : (
                        <>
                            <Stack direction="column" justifyContent="start" spacing={1} sx={{width: "100%"}}>
                                <Typography variant="label">{t("Staffed contingent worker")}</Typography>
                                <Stack alignItems="start">
                                    {formData.StaffedFreelance
                                        ? <UserCard user={formData?.StaffedFreelance?.User} displaySkeleton={true}/>
                                        : "-"
                                    }
                                </Stack>
                            </Stack>
                        </>
                    )}
                </Grid>
            </Grid>
        </CustomCard>
    </>
};