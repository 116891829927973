import React, {memo, useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Box, Stack, Typography} from "@mui/material";
import _ from "lodash";
import Utils from "../../../utils/Utils";
import {getInvoicesByCompany} from "../../../services/invoiceService";
import Functions from "../../../utils/Functions";
import {Kanban} from "../../../components/UI/Kanban";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {Display} from "../../../components/Inputs/Display";
import {FaFileInvoice, FaUser} from "react-icons/fa6";
import {useTranslation} from "react-i18next";
import {PiClockClockwiseBold} from "react-icons/pi";

export const InvoiceKanban = ({onListChange}) => {
    const token = useSelector((state) => state.auth.token);
    const navigate = useNavigate();

    const [invoices, setInvoices] = useState({});
    const columns = Object.entries(Functions.STATUS_INVOICE)
        .filter(([key]) => key !== "Canceled")
        .map(([key, title]) => ({
            key,
            title,
            color: Functions.STATUS_INVOICE_BG[key],
            isReadOnly: true
        }));

    useEffect(() => {
        const date60DaysAgo = moment().subtract(60, 'days').format('YYYY-MM-DD');
        const filter = {
            createdAt: {cond: 'and',operator: '>=',value: date60DaysAgo}
        };
        loadInvoices({filter: JSON.stringify(filter)});
    }, []);

    const loadInvoices = useCallback((query = {}) => {
        try {
            getInvoicesByCompany(query, token).then((fetchedData) => {
                if (!fetchedData?.error) {
                    const groupedInvoices = _.groupBy(fetchedData.data, 'status');
                    setInvoices(groupedInvoices);
                    onListChange?.(fetchedData.count);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }, [token]);

    const handleItemClick = (id) => {
        navigate(`/invoices/${id}`);
    };

    return (
        <Kanban columns={columns} datas={invoices} onItemClick={handleItemClick} KanbanItemComponent={KanbanItem}/>
    );
};

const KanbanItem = memo(({data}) => {
    const {t} = useTranslation();
    const mission = data?.Mission;
    const freelanceName = mission?.StaffedFreelance?.User?.fullName;
    const name = data?.name + " - " + mission?.name;


    return (
        <Box className="kanban-item" title={name}>
            <Display type="textTruncate" className="title" value={name}/>
            <Stack direction="column" alignItems="start" gap={1}>
                <Stack direction="row" className="w-100" alignItems="center" title={t("Contingent worker")} gap={1}>
                    <FaUser size={20} style={{color: "var(--info-main-color)", minWidth: "20px"}}/>
                    <Typography className="Mui-textTruncate">{freelanceName}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" title={t("Amount")} gap={1}>
                    <FaFileInvoice size={20} style={{color: "var(--info-main-color)"}}/>
                    <Typography>{`${data.amount} ${Utils.getCurrencySymbol(data.devise)}`}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" title={t("Due date")} gap={1}>
                    <PiClockClockwiseBold size={24} style={{color: "var(--info-main-color)"}}/>
                    <Typography>{Utils.formatDate(data.dueDate)}</Typography>
                </Stack>
            </Stack>
            <Box sx={{position: "absolute", top: '3px', right: '4px'}}>
                <Typography color="info.main" sx={{fontSize: '12px'}}>
                    {Utils.timeAgo(data.createdAt)}
                </Typography>
            </Box>
        </Box>
    );
}, (prevProps, nextProps) => {
    return prevProps.id === nextProps.id;
});