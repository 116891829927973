import {Alert, Box, Button, Grid, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {FieldHolder} from "../../../FieldHolder";
import {UserCard} from "../../../../UI/UserCard";
import {
    fetchMissionShortlistedFreelances,
    sendProposalAssignment
} from "../../../../../services/missionShortlistedFreelance";
import {useSelector} from "react-redux";

export const SendProposalAssignmentForm = ({missionId, selectedFreelances, onBack, onSaveFinish}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [loading, setLoading] = useState(false);
    const [shortlistedFreelances, setShortlistedFreelances] = useState([]);
    const [data, setData] = useState({
        message: ''
    });

    useEffect(() => {
        loadMissionShortlistedFreelances();
    }, []);

    const loadMissionShortlistedFreelances = useCallback(() => {
        try {
            if (selectedFreelances.length) {
                const query = {
                    filter: JSON.stringify({
                        FreelanceProfileId: {cond: 'and', operator: 'in', value: selectedFreelances}
                    })
                };
                fetchMissionShortlistedFreelances(missionId, query, token).then((fetchedData) => {
                    if (!fetchedData?.error) {
                        setShortlistedFreelances(fetchedData.data);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [token, missionId, selectedFreelances]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const response = await sendProposalAssignment({
                MissionId: missionId,
                MissionShortlistedFreelances: shortlistedFreelances.map((s) => ({
                    id: s.id, FreelanceProfileId: s.FreelanceProfileId
                })),
                message: data.message,
                notification: true
            }, token);
            setLoading(false);
            if (!response?.error) {
                onSaveFinish && onSaveFinish(true);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="label">{t("Contingent workers")}</Typography>
                <List>
                    {!loading && shortlistedFreelances.length === 0 && (
                        <Alert icon={false} severity="info">{t("No contingent worker")}</Alert>
                    )}
                    {shortlistedFreelances.map((shortlistedFreelance) => {
                        const freelance = shortlistedFreelance.FreelanceProfile;
                        return (
                            <ListItem key={freelance.id} sx={{pl: 0}}>
                                <ListItemAvatar>
                                    <UserCard user={freelance.User} displaySkeleton={true} hideLabel={true}/>
                                </ListItemAvatar>
                                <ListItemText primary={freelance.User.fullName}/>
                            </ListItem>
                        );
                    })}
                </List>
            </Grid>
            <Grid item xs={12}>
                <FieldHolder
                    fielddesc={t("Message to contingent workers")}
                    type="multiline"
                    name="message"
                    id="message"
                    value={data.message}
                    onChange={handleChange}
                    canModify={true}
                />
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="center">
                    {onBack && (
                        <>
                            <Button
                                variant="contained"
                                color="light"
                                onClick={onBack}
                            >
                                {t("Previous")}
                            </Button>
                            <Box sx={{flex: '1 1 auto'}}/>
                        </>
                    )}
                    <Button
                        disabled={loading}
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmit}
                    >{t("Propose the assignment")}</Button>
                </Stack>
            </Grid>
        </Grid>
    );
};