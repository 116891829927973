import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {useTranslation} from "react-i18next";

import './../index.css';
import './../page.css';
import "../../components/Forms/form.css";
import {MessageComponent} from "../../components/UI/MessageComponent";
import {
    fetchShortlistedFreelance,
    sendFreelanceFeedback
} from "../../services/missionShortlistedFreelance";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import {MissionDetailSection} from "../../components/Forms/Mission/Sections/MissionDetailSection/MissionDetailSection";
import Functions from "../../utils/Functions";
import ResponsiveComponent from "../../components/UI/ResponsiveComponent";
import logosmall from "../../assets/smarteem_logo_small.png";
import TagService from "../../services/tagService";
import {LanguageSelector} from "../../components/UI/LanguageSelector";
import {useFormStore} from "../../store/formStore";

export const PublicMission = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();

    const [message, setMessage] = useState({});
    const [shortlistedFreelance, setShortlistedFreelance] = useState({});
    const {setShowMandatoryMark} = useFormStore();

    useEffect(() => {
        (async () => {
            try {
                const fetchedData = await fetchShortlistedFreelance(id);
                if (!fetchedData.error) {
                    if (fetchedData.freelanceInterest) {
                        setMessage({type: 'success', message: t("You have already responded to this proposal.")});
                    }

                    if (fetchedData?.Mission.MissionTags) {
                        const missionTags = fetchedData.Mission.MissionTags.map(mt => mt.Tag);
                        fetchedData.Mission.MissionTags = TagService.groupTagsByType(missionTags);
                    }

                    setShortlistedFreelance(fetchedData);
                } else {
                    setMessage({type: 'error', message: fetchedData?.error});
                }
            } catch (error) {
                setMessage({type: 'error', message: error.message});
            }
        })();

        setShowMandatoryMark(false);
        return () => {
            setShowMandatoryMark(true);
        };
    }, []);

    const sendResponse = async (interest) => {
        try {
            setMessage({type: "loading", message: t("Saving data")});

            const newShortlistedFreelanceData = {...shortlistedFreelance, freelanceInterest: interest};

            const result = await sendFreelanceFeedback(shortlistedFreelance.id, newShortlistedFreelanceData);
            if (result.error) {
                setMessage({type: "error", message: result.error});
            } else {
                setShortlistedFreelance(newShortlistedFreelanceData);
                setMessage({
                    type: "success",
                    message: t("Your reply has been successfully sent to the assignment manager.")
                });
            }
        } catch (error) {

        }
    }

    return (
        <>
            <div className="onboarding-row-container layout">
                <ResponsiveComponent mobile>
                    <Box sx={{width: "100%", padding: "10px"}}>
                        <img
                            src={logosmall}
                            alt="Smarteem Logo"
                            className="app-logo"
                            onClick={() => navigate("/")}
                        />
                    </Box>
                </ResponsiveComponent>

                <ResponsiveComponent tablet desktop>
                    <div className="onboarding-column-container onboarding-column-left">
                        <img
                            src={logosmall}
                            alt="Smarteem Logo"
                            className="app-logo"
                            onClick={() => navigate("/")}
                        />
                        <div className="onboarding-welcome-message fsize-25">{t("Welcome to Smarteem")}</div>
                        <div className="onboarding-column-container">
                            <LanguageSelector/>
                        </div>
                    </div>
                </ResponsiveComponent>

                <div className="onboarding-column-container onboarding-column-right">
                    <div className="onboarding-card-w100">
                        <Grid container spacing={2}>
                            {Object.keys(message).length > 0 && (
                                <Grid item xs={12}>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <MessageComponent type={message.type} message={message.message} width="90%"/>
                                    </div>
                                </Grid>
                            )}

                            {shortlistedFreelance?.id && (
                                <>
                                    <Grid item xs={12}>
                                        <Stack alignItems="center">
                                            <Typography variant="h6" color="primary" sx={{fontWeight: "bold"}}>
                                                {`${shortlistedFreelance.Mission.Company.name} ${t("offers you the assignment")} "${shortlistedFreelance.Mission.name}"`}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item container justifyContent="center" xs={12}>
                                        <Box className="standardform standardform-w70">
                                            <MissionDetailSection
                                                formData={shortlistedFreelance.Mission}
                                                sessionType={Functions.SESSION_TYPE_FREELANCE}
                                                canModify={false}
                                            />
                                        </Box>
                                    </Grid>
                                    {!shortlistedFreelance?.freelanceInterest &&
                                        <Grid item xs={12}>
                                            <Stack direction={{xs: "column", md: "row"}} justifyContent="center"
                                                   spacing={2}>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => sendResponse("Interested")}
                                                >{t("Yes, I am interested")}</Button>
                                                <Button
                                                    variant="contained"
                                                    color="light"
                                                    onClick={() => sendResponse("Not interested")}
                                                >{t("No, I'm not interested")}</Button>
                                            </Stack>
                                        </Grid>
                                    }
                                </>
                            )}
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    );
};