import React, {memo, useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Box, Stack, Typography} from "@mui/material";
import _ from "lodash";
import Utils from "../../../utils/Utils";
import Functions from "../../../utils/Functions";
import {Kanban} from "../../../components/UI/Kanban";
import {getQuotesByCompany} from "../../../services/quoteService";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Display} from "../../../components/Inputs/Display";
import {FaFileInvoice, FaUser} from "react-icons/fa6";

export const QuoteKanban = ({onListChange}) => {
    const token = useSelector((state) => state.auth.token);
    const navigate = useNavigate();

    const [quotes, setQuotes] = useState({});
    const columns = Object.entries(Functions.STATUS_QUOTE)
        .map(([key, title]) => ({
            key,
            title,
            color: Functions.STATUS_QUOTE_BG[key],
            isReadOnly: true
        }));

    useEffect(() => {
        loadQuotes();
    }, []);

    const loadQuotes = useCallback((query = {}) => {
        try {
            getQuotesByCompany(query, token).then((fetchedData) => {
                if (!fetchedData?.error) {
                    const groupedQuotes = _.groupBy(fetchedData.data, 'status');
                    setQuotes(groupedQuotes);
                    onListChange?.(fetchedData.count);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }, [token]);

    const handleItemClick = (id) => {
        navigate(`/quotes/${id}`);
    };

    return (
        <Kanban columns={columns} datas={quotes} onItemClick={handleItemClick} KanbanItemComponent={KanbanItem}/>
    );
};

const KanbanItem = memo(({data}) => {
    const {t} = useTranslation();
    const mission = data?.Mission;
    const freelanceName = mission?.StaffedFreelance?.User?.fullName;
    const name = data?.name + " - " + mission?.name;


    return (
        <Box className="kanban-item" title={name}>
            <Display type="textTruncate" className="title" value={name}/>
            <Stack direction="column" alignItems="start" gap={1}>
                <Stack direction="row" className="w-100" alignItems="center" title={t("Contingent worker")} gap={1}>
                    <FaUser size={20} style={{color: "var(--info-main-color)", minWidth: "20px"}}/>
                    <Typography className="Mui-textTruncate">{freelanceName}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" title={t("Amount")} gap={1}>
                    <FaFileInvoice size={20} style={{color: "var(--info-main-color)"}}/>
                    <Typography>{`${data.amount} ${Utils.getCurrencySymbol(data.devise)}`}</Typography>
                </Stack>
            </Stack>
            <Box sx={{position: "absolute", top: '3px', right: '4px'}}>
                <Typography color="info.main" sx={{fontSize: '12px'}}>
                    {Utils.timeAgo(data.createdAt)}
                </Typography>
            </Box>
        </Box>
    );
}, (prevProps, nextProps) => {
    return prevProps.id === nextProps.id;
});