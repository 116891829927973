export const saveToken = (
    {
        token,
        userId,
        userFirstName,
        userLastName,
        userEmail,
        freelanceId,
        enterpriseId,
        companyId,
        companyLogo,
        companyName,
        role,
        plan,
        userType,
        displayConfig,
        fullAccess
    }
) => ({
    type: 'SAVE_TOKEN',
    payload: token,
    userId: userId,
    userFirstName: userFirstName,
    userLastName: userLastName,
    userEmail: userEmail,
    freelanceId: freelanceId,
    enterpriseId: enterpriseId,
    companyId: companyId,
    companyLogo: companyLogo,
    companyName: companyName,
    role: role,
    plan: plan,
    userType: userType,
    displayConfig: displayConfig ? JSON.stringify(displayConfig) : "",
    fullAccess: fullAccess
});

export const updateDisplayConfig = (displayConfig) => ({
   type: 'UPDATE_DISPLAY_CONFIG',
    displayConfig: displayConfig ? JSON.stringify(displayConfig) : ""
});

export const logout = () => {
    return {
        type: 'LOGOUT',
    };
};
