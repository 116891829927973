import React from "react";
import {useTranslation} from "react-i18next";
import {FieldHolder} from "../../FieldHolder";
import {Button, Grid} from "@mui/material";
import {FaThumbsUp} from "react-icons/fa";
import {HelperCard} from "../../../UI/HelperCard";
import {MessageComponent} from "../../../UI/MessageComponent";

export const FreelancePersonalInfoStep = ({formData, setFormData, message, onSave, canModify}) => {
    const {t} = useTranslation();

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    return (
        <Grid container columns={12} spacing={3}>
            {formData.existingUser && (
                <>
                    <Grid item xs={12}>
                        <HelperCard
                            icon={<FaThumbsUp/>}
                            headerText={t("Good news! {{freelanceFullName}} already has an account on Smarteem", {
                                freelanceFullName: `${formData.firstName} ${formData.lastName}`
                            })}
                            descriptionText={"You can now add the contingent worker to your pool and access their profile."}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MessageComponent
                            type={message.type}
                            message={message.message}
                            width="100%"
                        />
                    </Grid>
                    <Grid item xs={12} className="text-center">
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={onSave}
                        >
                            {t("Access the contingent worker's profile")}
                        </Button>
                    </Grid>
                </>
            )}
            {!formData.existingUser && (
                <>
                    <Grid item xs={12}>
                        <HelperCard
                            headerText={t("The contingent worker does not have an account on Smarteem")}
                            descriptionText={"Complete his information to create his account and add him to your pool of contingent workers."}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MessageComponent
                            type={message.type}
                            message={message.message}
                            width="100%"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Email")}
                            type="text"
                            name="email"
                            id="email"
                            placeholder={t("Email")}
                            value={formData?.email}
                            onChange={handleChange}
                            canModify={canModify}
                            mandatoryFlag={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("First name")}
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder={t("First name")}
                            value={formData?.firstName}
                            onChange={handleChange}
                            canModify={canModify}
                            mandatoryFlag={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Last name")}
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder={t("Last name")}
                            value={formData?.lastName}
                            onChange={handleChange}
                            canModify={canModify}
                            mandatoryFlag={true}
                        />
                    </Grid>
                    <Grid item xs={12} className="text-center" sx={{mt: 2}}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={onSave}
                        >
                            {t("Create the contingent worker's account")}
                        </Button>
                    </Grid>
                </>
            )}
        </Grid>
    );
};
