import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {AppLayout} from "../layouts/AppLayout";
import {DisplayBar} from "../components/UI/DisplayBar";
import {FreelanceBoard} from "../components/Forms/Reporting/FreelanceBoard";
import {ExpenseBoard} from "../components/Forms/Reporting/ExpenseBoard";
import "./page.css";
import "./reportingboard.css";
import {useSelector} from "react-redux";
import PermissionService from "../services/permissionService";
import Functions from "../utils/Functions";

export const ReportingBoard = () => {
    const {t} = useTranslation();
    const connectedUser = useSelector((state) => state.auth);

    const displayItems = [];
    if (PermissionService.isGranted(
        connectedUser,
        Functions.MODULE.REPORTING,
        [Functions.PLAN.STAFFING, Functions.PLAN.TALENT, Functions.PLAN.INTEGRAL]
    )
    ) {
        displayItems.push({key: 'ContingentWorkers', name: 'Contingent workers'});
    }

    if (
        PermissionService.isGranted(
            connectedUser,
            Functions.MODULE.REPORTING,
            [Functions.PLAN.PROJECT, Functions.PLAN.INTEGRAL]
        ) &&
        PermissionService.hasRole(connectedUser, Functions.USER_ROLE.ADMINISTRATIVE_FINANCIAL)
    ) {
        displayItems.push({key: 'Expenses', name: 'Expenses'});
    }

    const [displayType, setDisplayType] = useState(displayItems.length ? displayItems[0].key : "");

    const handleDisplayMenuClick = (newDisplay) => {
        setDisplayType(newDisplay);
    };

    return (
        <AppLayout>
            <div className="pageheader">
                <h1 className="pagetitle">{t("Reporting")}</h1>
            </div>
            <DisplayBar
                displayItems={displayItems}
                displayType={displayType}
                onDisplayMenuClick={handleDisplayMenuClick}
            />
            <div className="content-layout" style={{gap: "10px"}}>
                {displayType === 'ContingentWorkers' && <FreelanceBoard/>}
                {displayType === 'Expenses' && <ExpenseBoard/>}
            </div>
        </AppLayout>
    );
};
