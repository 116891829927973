import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {AppLayout} from "../../../layouts/AppLayout";
import {useParams} from "react-router-dom";
import {fetchOffer} from "../../../services/recruitment";
import {DisplayBar} from "../../../components/UI/DisplayBar";
import {JobApplicationDetailKanban} from "./JobApplicationDetailKanban";
import {JobApplicationDetailList} from "./JobApplicationDetailList";
import {Box} from "@mui/material";
import "./jobapplicationdetail.css";

export const JobApplicationDetail = () => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const {id} = useParams();

    const [rowCount, setRowCount] = useState(0);
    const [displayType, setDisplayType] = useState("Kanban");
    const [offer, setOffer] = useState({});

    const displayItems = [
        {key: 'Kanban', name: 'Kanban'},
        {key: 'List', name: 'List'}
    ];

    useEffect(() => {
        try {
            if (id) {
                fetchOffer(id, token).then((fetchedOffer) => {
                    if (!fetchedOffer?.error) {
                        if (fetchedOffer.RecruitmentOfferSkills) {
                            fetchedOffer.RecruitmentOfferSkills = fetchedOffer.RecruitmentOfferSkills.map(os => os.Skill);
                        }
                        setOffer(fetchedOffer);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [id, token]);

    const handleDisplayMenuClick = (newDisplay) => {
        setDisplayType(newDisplay);
    };

    return (
        <AppLayout className="content-full-height">
            <div className="pageheader">
                <h1 className="pagetitle">
                    {offer && offer.title ? `${t("Job applications")} - ${offer.title}` : ""}
                    <p className="header-data-row-count">{rowCount}</p>
                </h1>
            </div>
            <DisplayBar
                displayItems={displayItems}
                displayType={displayType}
                onDisplayMenuClick={handleDisplayMenuClick}
            />
            <Box className="table-layout h-100">
                {displayType === 'Kanban' && (
                    <JobApplicationDetailKanban offer={offer} onListChange={setRowCount}/>
                )}
                {displayType === 'List' && (
                    <JobApplicationDetailList offer={offer} onListChange={setRowCount}/>
                )}
            </Box>
        </AppLayout>
    );
};