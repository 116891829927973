import React, {useEffect, useState, useMemo} from "react";
import {Alert, Box, Button, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {FieldHolder} from "../../FieldHolder";
import Utils from "../../../../utils/Utils";
import {fetchCompanyFreelanceNote, saveCompanyFreelanceNote} from "../../../../services/freelanceProfileService";
import {useSelector} from "react-redux";
import Functions from "../../../../utils/Functions";
import {fetchEnterpriseProfiles} from "../../../../services/enterpriseProfileService";
import "./companyfreelancenote.css";
import TagService from "../../../../services/tagService";
import {CompanyFreelanceInteraction} from "./CompanyFreelanceInteraction";
import {Display} from "../../../Inputs/Display";
import {CustomCard} from "../../../UI/CustomCard";
import {saveApplication} from "../../../../services/recruitment";
import PermissionService from "../../../../services/permissionService";

export const CompanyFreelanceNote = (
    {
        activeSection,
        freelanceProfile,
        freelanceApplication,
        editPermissions
    }
) => {
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const {t} = useTranslation();

    const [activeTab, setActiveTab] = useState("");
    const [editMode, setEditMode] = useState(false);
    const [triggerApi, setTriggerApi] = useState(true);
    const [tags, setTags] = useState([]);
    const [contactOwners, setContactOwners] = useState([]);
    const [companyFreelanceNoteData, setCompanyFreelanceNoteData] = useState({
        contactOwner: null,
        profileSourceType: '',
        profileSourceComment: '',
        profileNote: '',
        availabilityNote: '',
        FreelanceProfileTags: []
    });

    const APPLICATION_TABS = [
        {key: "ApplicationTab", label: t("Application")},
        {key: "InteractionTab", label: t("Interactions")},
        {key: "NoteTab", label: t("Notes")}
    ];

    const DETAIL_TABS = [
        {key: "DetailTab", label: t("Detail")},
        {key: "OrganisationTab", label: t("Organisation")},
        {key: "InteractionTab", label: t("Interactions")},
        {key: "NoteTab", label: t("Notes")}
    ];

    const offer = freelanceApplication?.RecruitmentOffer || {};
    const offerPipelines = offer?.RecruitmentPipelines || [];
    const isSuperAdministrator = PermissionService.hasRole(connectedUser, Functions.USER_ROLE.ADMIN);

    const isApplicationTab = useMemo(() => {
        setCompanyFreelanceNoteData((prevState) => ({
            ...prevState,
            RecruitmentPipelineId: freelanceApplication?.RecruitmentPipelineId
        }));
        return freelanceApplication?.id && !editPermissions?.length;
    }, [freelanceApplication, editPermissions]);

    const tabs = useMemo(
        () => (isApplicationTab ? APPLICATION_TABS : DETAIL_TABS),
        [isApplicationTab]
    );

    useEffect(() => {
        setActiveTab(isApplicationTab ? "ApplicationTab" : "DetailTab");
        setTriggerApi(true);
    }, [isApplicationTab]);

    useEffect(() => {
        (async () => {
            if (freelanceProfile?.id && triggerApi) {
                const fetchedData = await fetchCompanyFreelanceNote(freelanceProfile?.id, token);
                if (fetchedData?.FreelanceProfileTags) {
                    fetchedData.FreelanceProfileTags = fetchedData.FreelanceProfileTags.map(item => item.Tag);
                    fetchedData.FreelanceProfileTags = TagService.groupTagsByType(fetchedData.FreelanceProfileTags);
                }
                setCompanyFreelanceNoteData((prevState) => ({...prevState, ...fetchedData}));
                setTriggerApi(false);
            }
        })();
    }, [token, freelanceProfile, triggerApi]);

    useEffect(() => {
        fetchEnterpriseProfiles({}, token).then((fetchedData) => {
            if (!fetchedData?.error) {
                const fetchedEnterpriseProfilesData = fetchedData.data.map(({User, ...rest}) => ({
                    id: rest.id,
                    name: User.fullName
                }));
                setContactOwners(fetchedEnterpriseProfilesData);
            }
        });
    }, [token]);

    useEffect(() => {
        if (editMode) {
            try {
                TagService.getFreelanceProfileTags({}, token).then((fetchedTags) => {
                    if (!fetchedTags?.error) {
                        const fetchedTagsData = TagService.groupTagsByType(fetchedTags.data);
                        setTags(fetchedTagsData);
                    }
                });
            } catch (error) {
            }
        }
    }, [token, editMode]);

    const handleChange = (e) => {
        const {name, value} = e.target;

        setCompanyFreelanceNoteData((prevFormData) =>
            Utils.updateNestedField(prevFormData, name, value)
        );
    };

    const handleSave = async () => {
        try {
            let result;
            if (isApplicationTab) {
                result = await saveApplication({
                    id: freelanceApplication?.id,
                    RecruitmentPipelineId: companyFreelanceNoteData.RecruitmentPipelineId
                }, token);
            } else {
                result = await saveCompanyFreelanceNote({
                    ...companyFreelanceNoteData,
                    FreelanceProfileId: freelanceProfile?.id,
                }, token);
            }
            if (!result.error) {
                setEditMode(false);
                setTriggerApi(true);
            }
        } catch (error) {

        }
    };

    const handleEditOption = async (option, newLabel) => {
        if (option.value !== option.label) {
            await TagService.modifyTag(option.value, {name: newLabel}, token);
        }
    };

    const handleDeleteOption = async (option) => {
        if (option.value !== option.label) {
            await TagService.deleteTag(option.value, token);
        }
    };


    return <Box className="company-freelance-note" sx={{width: "100%"}}>
        {activeSection === Functions.FREELANCE_PROFILE_SECTION.PROFILE && (
            <Stack direction="column" gap={2}>
                <Alert variant="outlined" severity="info" color="primary">
                    {t("This section is not visible to the contingent worker")}
                </Alert>
                <Stack direction="row" className="company-freelance-note-tab" spacing={0.5} sx={{mb: 1}}>
                    {tabs.map((tab) => (
                        <Button
                            key={tab.key}
                            variant={activeTab === tab.key ? "contained" : "text"}
                            color="light"
                            onClick={() => setActiveTab(tab.key)}
                            size="small"
                            fullWidth={false}
                        >
                            {tab.label}
                        </Button>
                    ))}
                </Stack>
            </Stack>
        )}
        <CustomCard
            className="card-padding-wide custom-card"
            editMode={editMode}
            setEditMode={setEditMode}
            canModify={!["InteractionTab", "NoteTab"].includes(activeTab)}
            onSave={handleSave}
            style={{height: `calc(100% - 100px) !important`}}
        >
            {activeSection === Functions.FREELANCE_PROFILE_SECTION.PROFILE && (
                <>
                    {activeTab === "DetailTab" && (
                        <>
                            <FieldHolder
                                fielddesc={t("Contingent worker type")}
                                type="dropdown"
                                set="ContingentWorkerType"
                                name="contingentWorkerType"
                                id="contingentWorkerType"
                                value={companyFreelanceNoteData.contingentWorkerType}
                                formData={companyFreelanceNoteData}
                                setFormData={setCompanyFreelanceNoteData}
                                canModify={editMode}
                            />
                            <FieldHolder
                                fielddesc={t("Tags")}
                                type="dropdown"
                                name="FreelanceProfileTags.freelance_tag"
                                id="FreelanceProfileTags.freelance_tag"
                                value={companyFreelanceNoteData?.FreelanceProfileTags?.freelance_tag}
                                datas={tags?.freelance_tag}
                                formData={companyFreelanceNoteData}
                                setFormData={setCompanyFreelanceNoteData}
                                canModify={editMode}
                                ismulti={true}
                                allowAdd={isSuperAdministrator}
                                allowManageOption={isSuperAdministrator}
                                onEditOption={handleEditOption}
                                onDeleteOption={handleDeleteOption}
                            />
                            <FieldHolder
                                fielddesc={t("Negotiated rate")}
                                type="text"
                                name="negotiatedRate"
                                id="negotiatedRate"
                                value={companyFreelanceNoteData.negotiatedRate}
                                onChange={handleChange}
                                canModify={editMode}
                            />
                            <FieldHolder
                                fielddesc={`${t("ADR")} minimum`}
                                type="text"
                                name="averageDailyRateMin"
                                id="averageDailyRateMin"
                                value={companyFreelanceNoteData.averageDailyRateMin}
                                onChange={handleChange}
                                canModify={editMode}
                            />
                            <FieldHolder
                                fielddesc={`${t("ADR")} maximum`}
                                type="text"
                                name="averageDailyRateMax"
                                id="averageDailyRateMax"
                                value={companyFreelanceNoteData.averageDailyRateMax}
                                onChange={handleChange}
                                canModify={editMode}
                            />
                            <FieldHolder
                                fielddesc={t("Contact owner")}
                                type="dropdown"
                                name="contactOwner"
                                id="contactOwner"
                                value={companyFreelanceNoteData.contactOwner}
                                datas={contactOwners}
                                formData={companyFreelanceNoteData}
                                setFormData={setCompanyFreelanceNoteData}
                                canModify={editMode}
                            />
                            <FieldHolder
                                fielddesc={t("Profile source")}
                                type="dropdown"
                                set="ProfileSourceType"
                                name="profileSourceType"
                                id="profileSourceType"
                                value={companyFreelanceNoteData.profileSourceType}
                                formData={companyFreelanceNoteData}
                                setFormData={setCompanyFreelanceNoteData}
                                canModify={editMode}
                            />
                            <FieldHolder
                                fielddesc={t("Comment on profile source")}
                                type="text"
                                name="profileSourceComment"
                                id="profileSourceComment"
                                value={companyFreelanceNoteData.profileSourceComment}
                                onChange={handleChange}
                                canModify={editMode}
                            />
                        </>
                    )}
                    {activeTab === "ApplicationTab" && (
                        <>
                            <Display
                                label={t("Offer")}
                                value={offer.title}
                            />
                            <Display
                                label={t("Application date")}
                                value={Utils.formatDate(freelanceApplication.createdAt)}
                            />
                            <FieldHolder
                                fielddesc={t("Status")}
                                type="dropdown"
                                name="RecruitmentPipelineId"
                                id="RecruitmentPipelineId"
                                value={companyFreelanceNoteData.RecruitmentPipelineId}
                                datas={offerPipelines.map(item => ({id: item.id, name: t(item.title)}))}
                                formData={companyFreelanceNoteData}
                                setFormData={setCompanyFreelanceNoteData}
                                canModify={editMode}
                            />
                        </>
                    )}
                    {activeTab === "OrganisationTab" && (
                        <>
                            <FieldHolder
                                fielddesc={t("Team / Department")}
                                type="dropdown"
                                name="FreelanceProfileTags.freelance_reporting_entity"
                                id="FreelanceProfileTags.freelance_reporting_entity"
                                value={companyFreelanceNoteData?.FreelanceProfileTags?.freelance_reporting_entity}
                                datas={tags?.freelance_reporting_entity}
                                formData={companyFreelanceNoteData}
                                setFormData={setCompanyFreelanceNoteData}
                                canModify={editMode}
                                ismulti={true}
                                allowAdd={isSuperAdministrator}
                                allowManageOption={isSuperAdministrator}
                                onEditOption={handleEditOption}
                                onDeleteOption={handleDeleteOption}
                            />
                            <FieldHolder
                                fielddesc={t("Legal entity")}
                                type="dropdown"
                                name="FreelanceProfileTags.freelance_legal_entity"
                                id="FreelanceProfileTags.freelance_legal_entity"
                                value={companyFreelanceNoteData?.FreelanceProfileTags?.freelance_legal_entity}
                                datas={tags?.freelance_legal_entity}
                                formData={companyFreelanceNoteData}
                                setFormData={setCompanyFreelanceNoteData}
                                canModify={editMode}
                                ismulti={true}
                                allowAdd={isSuperAdministrator}
                                allowManageOption={isSuperAdministrator}
                                onEditOption={handleEditOption}
                                onDeleteOption={handleDeleteOption}
                            />
                            <FieldHolder
                                fielddesc={t("Country")}
                                type="dropdown"
                                name="FreelanceProfileTags.freelance_country"
                                id="FreelanceProfileTags.freelance_country"
                                value={companyFreelanceNoteData?.FreelanceProfileTags?.freelance_country}
                                datas={tags?.freelance_country}
                                formData={companyFreelanceNoteData}
                                setFormData={setCompanyFreelanceNoteData}
                                canModify={editMode}
                                ismulti={true}
                                allowAdd={isSuperAdministrator}
                                allowManageOption={isSuperAdministrator}
                                onEditOption={handleEditOption}
                                onDeleteOption={handleDeleteOption}
                            />
                        </>
                    )}
                    {activeTab === "InteractionTab" && (
                        <CompanyFreelanceInteraction
                            freelanceProfile={freelanceProfile}
                            type="interaction"
                            canModify={true}
                        />
                    )}
                    {activeTab === "NoteTab" && (
                        <CompanyFreelanceInteraction
                            freelanceProfile={freelanceProfile}
                            type="note"
                            canModify={true}
                        />
                    )}
                </>
            )}
            {activeSection === Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY && (
                <FieldHolder
                    fielddesc={t("Notes")}
                    type="multiline"
                    name="availabilityNote"
                    id="availabilityNote"
                    value={companyFreelanceNoteData.availabilityNote}
                    onChange={handleChange}
                    canModify={editMode}
                />
            )}
        </CustomCard>
    </Box>
};