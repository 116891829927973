import React, {forwardRef, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {FieldHolder} from "../../../FieldHolder";
import {FaFileContract, FaFileInvoice} from "react-icons/fa";
import Functions from "../../../../../utils/Functions";
import missionRxjs from "../../../../../services/missionRxjs";
import {createFile} from "../../../../../services/fileService";
import {useSelector} from "react-redux";
import {MessageComponent} from "../../../../UI/MessageComponent";
import {Box, Button, Chip, Grid, Stack, Typography} from "@mui/material";
import Utils from "../../../../../utils/Utils";

import {
    createContract,
    createContractSmarteem,
    getContractsByCompany,
    getContractsByMission,
} from "../../../../../services/contractService";
import filesFreelanceProfile from "../../../../../services/filesFreelanceProfile";
import "../missioncontent.css";
import ResponsiveStepper from "../../../../UI/ResponsiveStepper";
import Popup from "../../../../Popup/Popup";
import {HelperCard} from "../../../../UI/HelperCard";
import {SlEnvolopeLetter} from "react-icons/sl";
import {Table} from "../../../../Tables/Table";
import {ContractDetailSection} from "./ContractDetailSection";
import {useNavigate} from "react-router-dom";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const ContractListSection = forwardRef((
    {
        formData,
        setFormData,
        objectAttachedType,
        isSection = true,
        sessionType,
        canModify,
        onListChange
    },
    ref
) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const navigate = useNavigate();

    const [message, setMessage] = useState({});
    const [showContractDialog, setShowContractDialog] = useState(false);
    const [selectedContract, setSelectedContract] = useState({});
    const [stepContract, setStepContract] = useState(Functions.TEXT_CREATION);
    const [contractFile, setContractFile] = useState(null);

    const defaultFormContract = {
        status: Functions.TEXT_TO_SIGN,
        currency: "EUR",
    };
    const [formContract, setFormContract] = useState(defaultFormContract);
    const deleteContractFileRef = useRef();

    const [contracts, setContracts] = useState([]);
    const tableRef = useRef();
    const fetchContractsRef = useRef();
    const [rowCount, setRowCount] = useState(0);
    const defaultFilterConfigs = {
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    };
    const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);

    const steps = [
        {key: Functions.TEXT_CREATION, name: 'Creation'},
        {key: Functions.TEXT_DETAILS, name: 'Details'},
        {key: Functions.TEXT_MESSAGE, name: 'Message to the contingent worker'},
    ];

    const refContract = useRef();
    const refAmendment = useRef();
    const refContractSmarteem = useRef();
    const refCustomContract = useRef();

    const columns = [
        {
            field: 'Contract.name',
            type: 'string',
            headerName: t("Name"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                return <Box sx={{textWrap: "wrap"}}>
                    {row.name}
                </Box>;
            }
        },
        {
            field: 'ContractCreatorUser.fullName',
            type: 'string',
            headerName: t("Created by"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                return row.ContractCreatorUser?.fullName;
            }
        },
        {
            field: 'Contract.createdAt',
            type: 'date',
            headerName: t("Created on"),
            minWidth: 100,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => row.createdAt && new Date(row.createdAt),
            renderCell: ({row}) => {
                return Utils.formatDate(row.createdAt);
            }
        },
        {
            field: 'status',
            type: 'singleSelect',
            headerName: t("Status"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'center',
            valueOptions: Object.entries(Functions.STATUS_CONTRACTS).map(([value, label]) => ({
                value: value,
                label: t(label)
            })),
            renderCell: ({row}) => {
                const status = t(Functions.STATUS_CONTRACTS[row.status]);
                return <Chip
                    label={status}
                    title={status}
                    size="small"
                    className={Functions.STATUS_CONTRACT_BG[row.status]}
                    sx={{whiteSpace: "nowrap"}}
                />
            }
        },
    ];

    if (!isSection) {
        columns.splice(1, 0,
            {
                field: 'Mission.name',
                type: 'string',
                headerName: t("Assignment"),
                minWidth: 150,
                flex: 1.5,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return row.Mission.name;
                }
            },
            {
                field: 'Mission.StaffedFreelance.User.fullName',
                type: 'string',
                headerName: t("Contingent worker"),
                minWidth: 150,
                flex: 1.5,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return row.Mission.StaffedFreelance?.User?.fullName;
                }
            },
        );
    }

    useEffect(() => {
        const contractFileObservable = missionRxjs.getContratFileObservable();
        const subscriptionContractFile = contractFileObservable.subscribe((data) => {
                if (data) {
                    setContractFile(data);
                }
            }
        );

        return () => {
            subscriptionContractFile.unsubscribe();
        };
    }, []);

    useEffect(() => {
        fetchContractsRef.current(filterConfigs);
    }, [formData?.id, filterConfigs]);

    fetchContractsRef.current = async (query = {}) => {
        try {
            if (formData?.contractualisation_type !== Functions.TEXT_QUOTE_ONLY) {
                let fetchedContracts;
                if (isSection) {
                    if (formData?.id) {
                        fetchedContracts = await getContractsByMission(formData.id, query, token);
                    }
                } else {
                    fetchedContracts = await getContractsByCompany(query, token);
                }

                if (fetchedContracts && !fetchedContracts?.error) {
                    setRowCount(fetchedContracts.count);
                    setContracts(fetchedContracts.data);
                    onListChange?.(fetchedContracts.count);
                }
            }
        } catch (error) {
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormContract({
            ...formContract, [name]: value
        });
    };

    const selectOption = (type, ref1, ref2, property, value1, value2) => {
        const selectedClass = "selected";
        switch (type) {
            case value1:
                ref1.current.classList.add(selectedClass);
                ref2.current.classList.remove(selectedClass);
                setFormContract({
                    ...formContract,
                    [property]: value1,
                });
                break;
            case value2:
                ref1.current.classList.remove(selectedClass);
                ref2.current.classList.add(selectedClass);
                setFormContract({
                    ...formContract,
                    [property]: value2,
                });
                break;
            default:
                break;
        }
    };

    const handleBack = () => {
        switch (stepContract) {
            case Functions.TEXT_DETAILS:
                setStepContract(Functions.TEXT_CREATION);
                break;
            case Functions.TEXT_MESSAGE:
                setStepContract(Functions.TEXT_DETAILS);
                break;
            default:
                break;
        }
    };

    const handleNext = async () => {
        let currentStep = stepContract;
        let datas;
        let verifyData;

        const msgError = "Error saving data";

        verifyData = Utils.verifyDataContract(formContract, currentStep);
        if (verifyData.type === "error") {
            setMessage(verifyData);
            return;
        }

        switch (currentStep) {
            case Functions.TEXT_CREATION:
            case Functions.TEXT_DETAILS:
                setStepContract(
                    currentStep === Functions.TEXT_CREATION
                        ? Functions.TEXT_DETAILS : Functions.TEXT_MESSAGE
                );
                setMessage({});
                break;
            case Functions.TEXT_MESSAGE:
                if (
                    formContract.contract_type === Functions.TEXT_CUSTOM_CONTRACT &&
                    contractFile.type !== "application/pdf"
                ) {
                    setMessage({
                        type: "error",
                        message: t("Format allowed") + " , PDF"
                    });
                } else {
                    //Save datas for Freelance
                    datas = formContract;
                    datas.missionId = formData.id;
                    datas.companyId = formData.CompanyId;
                    datas.freelanceProfileId = formData.staffedFreelanceId;
                    datas.creatorUser = sessionType;

                    try {
                        let createdContract;

                        if (formContract.contract_type === Functions.TEXT_SMARTEEM_CONTRACT) {
                            setMessage({type: "loading", message: t("Saving data")});

                            datas["paymentMethod"] = formData.paymentMethod;
                            datas["customPaymentMethod"] = formData.customPaymentMethod;
                            datas["missionDeadline"] = formData.deadline;
                            datas["missionEstimatedEndDate"] = formData.estimated_end_date;

                            createdContract = await createContractSmarteem(datas, token);
                        } else {
                            if (contractFile) {
                                const fileData = {
                                    typeFile: Functions.TEXT_DOCUMENT,
                                    file: contractFile.theFile,
                                };
                                datas["file"] = await createFile(fileData, "", token);
                            }
                            createdContract = await createContract(datas, token);
                        }

                        if (createdContract?.error) {
                            setMessage({type: "error", message: msgError});
                        } else {
                            handleContractDialogClose();

                            setStepContract(Functions.TEXT_CREATION);
                            setMessage({});
                            missionRxjs.setContratFile(null);
                            filesFreelanceProfile.setFiles([]);

                            handleInitList();

                            const newSectionStatus = Utils.updateStatusSection(
                                formData.status_section,
                                Functions.SECTION_CONTRACT
                            );
                            setFormData({...formData, status_section: newSectionStatus});
                        }
                    } catch (error) {
                        console.error("the error: ", error.message);
                    }
                }
                break;
            default:
                break;
        }
    };

    const handleContractDialogClose = () => {
        if (deleteContractFileRef.current) {
            deleteContractFileRef.current.click();
        }
        setShowContractDialog(false);
    };

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    const handleContractCellClick = (row) => {
        if (isSection) {
            setSelectedContract(row);
        } else {
            navigate(`/contracts/${row.id}`);
        }
    };

    const handleInitList = () => {
        setSelectedContract({});
        setFormContract(defaultFormContract);
        tableRef.current && tableRef.current.initTable();
        setFilterConfigs(defaultFilterConfigs);
    };

    if (ref) {
        ref.current = handleInitList;
    }

    return <>
        {formData?.contractualisation_type === Functions.TEXT_QUOTE_ONLY &&
            <div
                className="card standardform-card card-padding-wide"
                style={{flex: "none", alignItems: "start", width: "64%"}}
            >
                <Typography variant="label">{t("Assignment without contract")}</Typography>
            </div>
        }
        {formData?.contractualisation_type !== Functions.TEXT_QUOTE_ONLY &&
            <div className={`h-100 ${isSection ? "card standardform-card card-padding-wide" : "w-100"}`}>
                {Object.keys(selectedContract).length === 0 && (
                    <Box className="common-board-container">
                        {isSection && (
                            <Stack direction="row" justifyContent="space-between" spacing={1}>
                                <Typography variant="labelInfo">
                                    {t("Contracts and amendments list")}
                                </Typography>
                                {canModify && (
                                    <>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => setShowContractDialog(true)}
                                        >
                                            {t("Add a contract or an amendment")}
                                        </Button>

                                        <Popup
                                            open={showContractDialog}
                                            justifyDialogAction={"space-between"}
                                            onDialogClose={handleContractDialogClose}
                                        >
                                            <Box sx={{width: {md: "30vw"}}}>
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid item xs={12}>
                                                        <ResponsiveStepper
                                                            steps={steps}
                                                            stepKey={stepContract}
                                                            onStepClick={(index) => (
                                                                setStepContract(steps[index]?.key)
                                                            )}
                                                        />

                                                        <MessageComponent
                                                            type={message?.type}
                                                            message={message?.message}
                                                            width="100%"
                                                        />
                                                    </Grid>

                                                    {stepContract === Functions.TEXT_CREATION && (
                                                        <>
                                                            <Grid item xs={12}>
                                                                <div className="bloc-select">
                                                                    <div className="conteneur-select">
                                                                        <div
                                                                            className={
                                                                                formContract?.contractModifType === Functions.TEXT_CONTRAT
                                                                                    ? "option-select selected"
                                                                                    : "option-select"
                                                                            }
                                                                            ref={refContract}
                                                                            onClick={() => selectOption(
                                                                                Functions.TEXT_CONTRAT,
                                                                                refContract,
                                                                                refAmendment,
                                                                                'contractModifType',
                                                                                Functions.TEXT_CONTRAT,
                                                                                Functions.TEXT_AMENDMENT
                                                                            )}
                                                                        >
                                                                            <div>
                                                                                <FaFileInvoice size={20}/>
                                                                            </div>
                                                                            <div className="text-wrap-70">
                                                                                {t("Contract")}
                                                                            </div>
                                                                        </div>

                                                                        <div
                                                                            className={
                                                                                formContract?.contractModifType === Functions.TEXT_AMENDMENT
                                                                                    ? "option-select selected"
                                                                                    : "option-select"
                                                                            }
                                                                            ref={refAmendment}
                                                                            onClick={() => selectOption(
                                                                                Functions.TEXT_AMENDMENT,
                                                                                refContract,
                                                                                refAmendment,
                                                                                'contractModifType',
                                                                                Functions.TEXT_CONTRAT,
                                                                                Functions.TEXT_AMENDMENT
                                                                            )}
                                                                        >
                                                                            <div className="box-superpose">
                                                                                <div className="svg-container">
                                                                                    <FaFileContract size={20}
                                                                                                    className="svg-icon"/>
                                                                                    <FaFileInvoice size={20}
                                                                                                   className="svg-icon second-svg"/>
                                                                                </div>
                                                                            </div>
                                                                            <div className="text-wrap-70">
                                                                                {t("Amendment to an existing contract")}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Grid>

                                                            {formContract?.contractModifType &&
                                                                <Grid item xs={12}>
                                                                    <div className="bloc-select">
                                                                        <div className="conteneur-select">
                                                                            <div
                                                                                className={
                                                                                    formContract?.contract_type === Functions.TEXT_SMARTEEM_CONTRACT
                                                                                        ? "option-select selected"
                                                                                        : "option-select"
                                                                                }
                                                                                ref={refContractSmarteem}
                                                                                onClick={() => selectOption(
                                                                                    Functions.TEXT_SMARTEEM_CONTRACT,
                                                                                    refContractSmarteem,
                                                                                    refCustomContract,
                                                                                    'contract_type',
                                                                                    Functions.TEXT_SMARTEEM_CONTRACT,
                                                                                    Functions.TEXT_CUSTOM_CONTRACT
                                                                                )}
                                                                            >
                                                                                <div>
                                                                                    <FaFileInvoice size={20}/>
                                                                                </div>
                                                                                <div className="text-wrap-70">
                                                                                    {t("Use the freelancing contract template")}
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className={
                                                                                    formContract?.contract_type === Functions.TEXT_CUSTOM_CONTRACT
                                                                                        ? "option-select selected"
                                                                                        : "option-select"
                                                                                }
                                                                                ref={refCustomContract}
                                                                                onClick={() => selectOption(
                                                                                    Functions.TEXT_CUSTOM_CONTRACT,
                                                                                    refContractSmarteem,
                                                                                    refCustomContract,
                                                                                    'contract_type',
                                                                                    Functions.TEXT_SMARTEEM_CONTRACT,
                                                                                    Functions.TEXT_CUSTOM_CONTRACT
                                                                                )}
                                                                            >
                                                                                <div className="box-superpose">
                                                                                    <div className="svg-container">
                                                                                        <FaFileContract size={20}
                                                                                                        className="svg-icon"/>
                                                                                        <FaFileInvoice size={20}
                                                                                                       className="svg-icon second-svg"/>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="text-wrap-70">
                                                                                    {t("Use my contract")}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            }

                                                            {formContract?.contract_type === Functions.TEXT_CUSTOM_CONTRACT && (
                                                                <Grid item xs={12}>
                                                                    <FieldHolder
                                                                        ref={deleteContractFileRef}
                                                                        fielddesc={t("File")}
                                                                        type="file"
                                                                        name="file"
                                                                        id="contract_file"
                                                                        placeholder={t("File")}
                                                                        value={formContract?.file}
                                                                        sibling="missionContrat"
                                                                        objectAttachedType={objectAttachedType}
                                                                        canModify={true}
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </>
                                                    )}

                                                    {
                                                        stepContract === Functions.TEXT_DETAILS &&
                                                        (formContract.contractModifType === Functions.TEXT_CONTRAT ? (
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <FieldHolder
                                                                        fielddesc={t("Document name")}
                                                                        type="text"
                                                                        name="name"
                                                                        id="contract_name"
                                                                        placeholder={t("Document name")}
                                                                        value={formContract?.name}
                                                                        onChange={handleChange}
                                                                        canModify={true}
                                                                    />
                                                                </Grid>
                                                                {formContract?.contract_type === Functions.TEXT_SMARTEEM_CONTRACT &&
                                                                    <Grid item xs={12}>
                                                                        <FieldHolder
                                                                            fielddesc={t("Additional clauses")}
                                                                            type="multiline"
                                                                            name="additionalClauses"
                                                                            id="additionalClauses"
                                                                            placeholder={t("Additional clauses")}
                                                                            value={
                                                                                formContract?.additionalClauses === null
                                                                                    ? ""
                                                                                    : formContract.additionalClauses
                                                                            }
                                                                            onChange={handleChange}
                                                                            canModify={canModify}
                                                                            className={"input-mission-hw45"}
                                                                        />
                                                                    </Grid>
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Grid item xs={6} className={"mb-2"}>
                                                                    <FieldHolder
                                                                        fielddesc={t("Name")}
                                                                        type="text"
                                                                        name="name"
                                                                        id="contract_name"
                                                                        placeholder={t("Name")}
                                                                        value={formContract?.name}
                                                                        onChange={handleChange}
                                                                        canModify={true}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6} className={"mb-2"}>
                                                                    <FieldHolder
                                                                        fielddesc={t("Reason for contract amendment")}
                                                                        type="text"
                                                                        name="amendmentMotif"
                                                                        id="amendment_motif"
                                                                        placeholder={t("Reason for contract amendment")}
                                                                        value={formContract.amendmentMotif}
                                                                        onChange={handleChange}
                                                                        canModify={canModify}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} className={"mb-2"}>
                                                                    <FieldHolder
                                                                        fielddesc={t("Amendment effective date")}
                                                                        type="date"
                                                                        name="amendmentEffectiveDate"
                                                                        id="amendment_effective_date"
                                                                        placeholder={t("Amendment effective date")}
                                                                        value={formContract.amendmentEffectiveDate}
                                                                        onChange={handleChange}
                                                                        canModify={canModify}
                                                                    />
                                                                </Grid>
                                                                {formContract?.contract_type === Functions.TEXT_SMARTEEM_CONTRACT &&
                                                                    <Grid item xs={12}>
                                                                        <FieldHolder
                                                                            fielddesc={t("Amendment clauses")}
                                                                            type="multiline"
                                                                            name="amendmentClause"
                                                                            id="amendment_clause"
                                                                            placeholder={t("Amendment clauses")}
                                                                            value={
                                                                                formContract?.amendmentClause === null
                                                                                    ? ""
                                                                                    : formContract?.amendmentClause
                                                                            }
                                                                            onChange={handleChange}
                                                                            canModify={canModify}
                                                                            className={"input-mission-hw45"}
                                                                        />
                                                                    </Grid>
                                                                }
                                                            </>
                                                        ))
                                                    }

                                                    {stepContract === Functions.TEXT_MESSAGE && (
                                                        <>
                                                            <Grid item xs={12}>
                                                                <FieldHolder
                                                                    fielddesc={t("Message")}
                                                                    type="multiline"
                                                                    name="commentEnterprise"
                                                                    id="comment_commentEnterprise"
                                                                    placeholder={t("Message")}
                                                                    value={formContract?.commentEnterprise ?? ""}
                                                                    onChange={handleChange}
                                                                    canModify={canModify}
                                                                    className={"input-mission-hw45"}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <HelperCard
                                                                    icon={<SlEnvolopeLetter/>}
                                                                    headerText={"You are about to send the contract to the contingent worker"}
                                                                    descriptionText={"Once you've pressed the \"validate\" button, the contingent worker will receive a notification inviting him to sign the contract from his account."}
                                                                />
                                                            </Grid>
                                                        </>
                                                    )}

                                                    <Grid item xs={12}
                                                          sx={{
                                                              display: "flex",
                                                              justifyContent: "space-between",
                                                              mt: 2
                                                          }}>
                                                        <Button
                                                            variant="contained"
                                                            color="light"
                                                            onClick={handleBack}
                                                        >
                                                            {t("Previous")}
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={handleNext}>
                                                            {stepContract === Functions.TEXT_MESSAGE ? t("Validate") : t("Next")}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Popup>
                                    </>
                                )}
                            </Stack>
                        )}
                        <Box className="h-100">
                            <Table
                                fullHeight={isSection}
                                ref={tableRef}
                                columns={columns}
                                data={contracts}
                                rowCount={rowCount}
                                pageSize={pageSize}
                                filterConfigs={filterConfigs}
                                onChange={handleDataGridChange}
                                onCellClick={handleContractCellClick}
                            />
                        </Box>
                    </Box>
                )}
                {Object.keys(selectedContract).length > 0 &&
                    <ContractDetailSection
                        formData={formData}
                        selectedContract={selectedContract}
                        setSelectedContract={setSelectedContract}
                        sessionType={sessionType}
                        isSection={isSection}
                    />
                }
            </div>
        }
    </>
});
