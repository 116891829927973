import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {FieldHolder} from "../../../FieldHolder";
import Functions from "../../../../../utils/Functions";
import {PDFViewer2} from "../../../../Pdf/PDFViewer2";
import {PDFDocument, rgb, StandardFonts} from "pdf-lib";
import missionRxjs from "../../../../../services/missionRxjs";
import {createFile, fetchFile} from "../../../../../services/fileService";
import {fetchUser} from "../../../../../services/userService";
import {useSelector} from "react-redux";
import {MessageComponent} from "../../../../UI/MessageComponent";
import {Box, Button, Chip, Grid, IconButton, Stack, Typography} from "@mui/material";
import Utils from "../../../../../utils/Utils";

import {
    modifyManyContractStatus,
    sendNotification,
    updateContract,
} from "../../../../../services/contractService";
import "../missioncontent.css";
import Popup from "../../../../Popup/Popup";
import {fetchEnterpriseProfiles} from "../../../../../services/enterpriseProfileService";
import {Display} from "../../../../Inputs/Display";
import {MdOutlineDelete, MdOutlineFileDownload} from "react-icons/md";
import {SignaturePadPopup} from "../../../../Popup/SignaturePadPopup";
import useFileOpener from "../../../../../hooks/customHooks";
import {useToast} from "../../../../../context/ToastProvider";

export const ContractDetailSection = (
    {
        formData,
        selectedContract,
        setSelectedContract,
        isSection = true,
        sessionType
    }
) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const showToast = useToast();
    const {loading, openFileInNewTab} = useFileOpener();

    const [showSignaturePadDialog, setShowSignaturePadDialog] = useState(false);
    const [showInviteMemberDialog, setShowInviteMemberDialog] = useState(false);

    const [message, setMessage] = useState({});
    const [contractFile, setContractFile] = useState(null);
    const [activeFile, setActiveFile] = useState(null);
    const [activeFileName, setActiveFileName] = useState(null);

    const [enterpriseUsers, setEnterpriseUsers] = useState([]);
    const [inviteToSignData, setInviteToSignData] = useState({
        user: null,
        message: ''
    });
    const [inviteToSignLoading, setInviteToSignLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;
    const isFreelanceSession = sessionType === Functions.SESSION_TYPE_FREELANCE;

    useEffect(() => {
        const contractFileObservable = missionRxjs.getContratFileObservable();
        const subscriptionContractFile = contractFileObservable.subscribe(
            (data) => {
                if (data !== null) {
                    setContractFile(data);
                    const urlFileBlob = URL.createObjectURL(data.theFile);
                    setActiveFile(urlFileBlob);
                }
            }
        );

        (async () => {
            await getEnterpriseProfileCompany();
        })();

        return () => {
            subscriptionContractFile.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (selectedContract?.id) {
            (async () => {
                await loadContractFile(selectedContract);
            })();
        }
    }, [selectedContract.id]);

    const getEnterpriseProfileCompany = async () => {
        const fetchedEnterpriseProfiles = await fetchEnterpriseProfiles({}, token);
        const fetchedEnterpriseProfilesData = fetchedEnterpriseProfiles.data
            .map(({User, ...rest}) => ({
                id: rest.id,
                name: User.fullName
            }));
        setEnterpriseUsers(fetchedEnterpriseProfilesData);
    };

    const handleInviteToSignChange = (e) => {
        const {name, value} = e.target;
        setInviteToSignData({
            ...inviteToSignData, [name]: value
        });
    };

    const handleSignatureSave = async (blobSignature) => {
        missionRxjs.setContratSignatureFile(blobSignature);
        setShowSignaturePadDialog(false);
        await createPdfMergeFile(blobSignature);
    };

    const handleClickAction = async (e, action) => {
        e.preventDefault();
        let contractDatas = [];

        if (!selectedContract) {
            setMessage({
                type: "error",
                message: "Aucun contrat sélectionnée.",
            });
        } else {
            setMessage({});
            const item = {
                id: selectedContract.id,
                status: action,
            };
            contractDatas = [...contractDatas, item];

            await handleSaveContratData(contractDatas, action);
        }
    };

    const handleSaveContratData = async (contractDatas, status) => {
        const updatedContract = await modifyManyContractStatus(
            {contracts: contractDatas},
            token
        );

        if (updatedContract?.error) {
            showToast(t("Error saving data"), "error");
        } else {
            setSelectedContract({
                ...selectedContract,
                status: status
            });

            showToast(t("Contract updated"));
        }
    };

    const controlStatusSignature = (contract) => {
        const statusMap = {
            [Functions.SESSION_TYPE_ENTERPRISE]: {
                [Functions.TEXT_TO_SIGN]: Functions.TEXT_FREELANCE_SIGNATURE,
                [Functions.TEXT_ENTERPRISE_SIGNATURE]: Functions.TEXT_SIGNED
            },
            [Functions.SESSION_TYPE_FREELANCE]: {
                [Functions.TEXT_TO_SIGN]: Functions.TEXT_ENTERPRISE_SIGNATURE,
                [Functions.TEXT_FREELANCE_SIGNATURE]: Functions.TEXT_SIGNED
            }
        };

        return statusMap[sessionType][contract.status] || "";
    };

    const createPdfMergeFile = async (blobSignature) => {
        if (isEnterpriseSession) {
            if (!contractFile && !activeFile) {
                setMessage({type: "error", message: t("Upload a PDF file")});
                return false;
            }
        }

        // Créer un nouveau document PDF vide
        const now = new Date();
        const textSize = 8;
        let xText, yText, xSignImage, ySignImage;

        const fetchedUser = await fetchUser(token);

        let updatedDataContrat = {
            ...selectedContract,
            notification: true
        };

        const text =
            t("Electronically signed on Smarteem") + " " + "\n" + t("the") + " " +
            now.toISOString().split("T")[0] + " " +
            t("By").toString().toLowerCase() + " " + fetchedUser?.fullName;

        if (isEnterpriseSession) {
            updatedDataContrat = {
                ...updatedDataContrat,
                enterprise_signature: true,
                enterprise_signature_date: now,
                enterpriseSignatureUserId: fetchedUser.id,
                status: controlStatusSignature(selectedContract),
            };

            xText = 50;
            yText = 155;
            xSignImage = 50;
            ySignImage = 25;
        } else {
            updatedDataContrat = {
                ...updatedDataContrat,
                freelance_signature: true,
                freelance_signature_date: now,
                freelanceSignatureUserId: fetchedUser.id,
                status: controlStatusSignature(selectedContract),
            };

            xText = 400;
            yText = 155;
            xSignImage = 400;
            ySignImage = 25;
        }

        // Charger le document PDF existant
        const existingPdfBytes = await contractFile.arrayBuffer();
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        if (selectedContract?.additionalClauses) {
            // Ajouter une nouvelle page au document
            // var pdfDocNewPage = await pdfDoc.addPage();
            // Sauvegarder le document mis à jour dans un fichier
            // pdfDoc = await pdfDoc.save();
            if (isEnterpriseSession) {
                xText = 50;
                yText = 780;
                xSignImage = 50;
                ySignImage = 630;
            }
            if (isFreelanceSession) {
                xText = 400;
                yText = 780;
                xSignImage = 400;
                ySignImage = 630;
            }
        }

        //Font du text
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

        // Récupérer la dernière page du document
        const lastPageIndex = pdfDoc.getPageCount() - 1;
        const lastPage = pdfDoc.getPages()[lastPageIndex];

        lastPage.drawText(text, {
            x: xText,
            y: yText,
            font: helveticaFont,
            size: textSize,
            color: rgb(0, 0, 0),
        });

        // Charger l'image blob
        const imageBytes = await new Response(blobSignature).arrayBuffer();
        // Ajouter l'image à la page
        const image = await pdfDoc.embedPng(imageBytes);
        const imageSize = 100;
        lastPage.drawImage(image, {
            x: xSignImage,
            y: ySignImage,
            width: imageSize,
            height: imageSize,
        });
        // Convertir le document PDF en tableau de bytes
        const pdfBytes = await pdfDoc.save();
        // Créer un objet Blob à partir des bytes
        const pdfBlob = new Blob([pdfBytes], {type: "application/pdf"});
        // Créer un objet URL pour le Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        let contractFiles;
        if (selectedContract.enterprise_signature && selectedContract.freelance_signature) {
            contractFiles = [{name: "final_file", theFile: pdfBlob}];
        } else {
            contractFiles = [{name: "merge_file", theFile: pdfBlob}];
        }

        if (isEnterpriseSession && selectedContract.contract_type === Functions.TEXT_CUSTOM_CONTRACT) {
            contractFiles.push({name: "file", theFile: contractFile.theFile});
        }

        //## Update last pdf modified file
        setActiveFile(pdfUrl);

        for (const value of contractFiles) {
            const property = value.name;
            const file = value.theFile;
            const fileData = {
                typeFile: Functions.TEXT_DOCUMENT,
                [value.name]: file,
            };
            if (file !== null && file !== undefined) {
                updatedDataContrat[property] = await createFile(fileData, "", token);
            }
        }

        const statusError = false;

        if (!statusError) {
            const contratUpdated = await updateContract(
                selectedContract.id,
                updatedDataContrat,
                token
            );

            setMessage({});
            if (contratUpdated) {
                showToast(t("The document has been signed"));
                setSelectedContract(updatedDataContrat);
                missionRxjs.setContractFiles([]);
            } else {
                setMessage(contratUpdated);
            }
        }
    };

    const loadContractFile = async (contract) => {
        const displayFile = contract.final_file || contract.merge_file || contract.file;

        try {
            setMessage({type: "loading", message: t("Fetching data")});

            const fetchedFile = await fetchFile(displayFile, Functions.TEXT_DOCUMENT, token);
            setTimeout(() => {
                if (fetchedFile?.error) {
                    setMessage({type: "error", message: fetchedFile.error});
                } else {
                    setContractFile(fetchedFile);
                    setActiveFile(URL.createObjectURL(fetchedFile));
                    setActiveFileName(displayFile);
                    setMessage({});
                }
            }, 1000);
        } catch (error) {
            setMessage({type: "error", message: t("File removed or moved")});
        }
    };

    const sendInviteToSignEmail = async () => {
        const {valid, errors} = Utils.validateRequiredFields(inviteToSignData, ['user']);
        setFormErrors(errors);

        if (!valid) {
            return;
        }

        try {
            //Send email to the enterprise member for the signature
            setInviteToSignLoading(true);
            const notification = await sendNotification({
                ...inviteToSignData,
                contractId: selectedContract.id,
            }, token);
            setInviteToSignLoading(false);
            if (!notification.error) {
                showToast(t("The document has been sent for signature"));
                setInviteToSignData({user: '', message: ''});
                setShowInviteMemberDialog(false);
            }
        } catch (error) {

        }
    };

    const getContractHeader = () => {
        const isSignatureMissing =
            selectedContract.id &&
            ![Functions.TEXT_REJECTED, Functions.TEXT_CANCELED].includes(selectedContract.status) &&
            (
                (isEnterpriseSession && !selectedContract.enterprise_signature) ||
                (isFreelanceSession && !selectedContract.freelance_signature)
            );

        if (!isSection || isSignatureMissing) {
            return (
                <Stack
                    direction={{xs: "column", md: "row"}}
                    alignItems="start"
                    justifyContent="space-between"
                    sx={{width: "100%"}}
                    spacing={!isSection ? 2 : 0}
                >
                    <h1 className="pagetitle mb-0">{!isSection ? selectedContract.name : ""}</h1>
                    {isSignatureMissing && (
                        <Stack direction="row" justifyContent="end" columnGap={1}>
                            {isEnterpriseSession &&
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={() => setShowInviteMemberDialog(true)}
                                >
                                    {t("Invite a user to sign")}
                                </Button>
                            }
                            <Button
                                type="button"
                                variant="contained"
                                color="secondary"
                                onClick={() => setShowSignaturePadDialog(true)}
                            >
                                {t("Sign")}
                            </Button>
                            <SignaturePadPopup
                                open={showSignaturePadDialog}
                                title={false}
                                onConfirm={handleSignatureSave}
                                onDialogClose={() => setShowSignaturePadDialog(false)}
                            />
                        </Stack>
                    )}
                </Stack>
            );
        }

        return null;
    };

    const handleFileOpen = async (value) => {
        await openFileInNewTab(value, Functions.TEXT_DOCUMENT, token);
    };

    return <>
        {formData?.contractualisation_type !== Functions.TEXT_QUOTE_ONLY &&
            <div style={{width: "100%", height: "100%", overflow: "auto"}}>
                <div className="common-board-container">
                    {getContractHeader()}

                    <Grid container direction="row" columnSpacing={3} className="h-100">
                        <Grid item xs={3} className="h-100">
                            <div className="box-item standardform-card card-padding-wide h-100">
                                {selectedContract.id && (
                                    <>
                                        <Stack spacing={1} alignItems="center" sx={{width: "100%"}}>
                                            <Typography variant="labelInfo">{selectedContract.name}</Typography>
                                            <Chip
                                                label={t(Functions.STATUS_CONTRACTS[selectedContract.status])}
                                                className={Functions.STATUS_CONTRACT_BG[selectedContract.status]}
                                                sx={{minWidth: "8rem"}}
                                            />
                                        </Stack>
                                        <Display
                                            label={t("Created on")}
                                            value={Utils.formatDate(selectedContract.createdAt)}
                                        />
                                        {selectedContract.ContractCreatorUser &&
                                            <Display
                                                label={t("Created by")}
                                                value={selectedContract.ContractCreatorUser?.fullName}
                                            />
                                        }
                                        <Display
                                            label={t("Company signature")}
                                            value={
                                                selectedContract.enterprise_signature
                                                    ? `${t("Yes")} - ${t("the")} ${Utils.formatDate(selectedContract.enterprise_signature_date)}`
                                                    : t("No")
                                            }
                                        />
                                        <Display
                                            label={t("Signed by the contingent worker")}
                                            value={
                                                selectedContract.freelance_signature
                                                    ? `${t("Yes")} - ${t("the")} ${Utils.formatDate(selectedContract.freelance_signature_date)}`
                                                    : t("No")
                                            }
                                        />
                                        {selectedContract.commentEnterprise &&
                                            <Display
                                                label={t("Message")}
                                                value={selectedContract.commentEnterprise}
                                            />
                                        }
                                    </>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs className="h-100">
                            <div className="box-item standardform-card card-padding-wide h-100"
                                 style={{alignItems: "center"}}
                            >
                                {selectedContract.status && (
                                    <>
                                        <Box sx={{width: "92%"}}>
                                            <MessageComponent
                                                type={message.type}
                                                message={message.message}
                                                width="100%"
                                            />
                                            {
                                                activeFileName &&
                                                ![
                                                    Functions.TEXT_REJECTED,
                                                    Functions.TEXT_CANCELED
                                                ].includes(selectedContract.status) && (
                                                    <Stack direction="row" justifyContent="start"
                                                           spacing={0.5}
                                                           sx={{width: "100%"}}>
                                                        <IconButton
                                                            disabled={loading}
                                                            color="primary"
                                                            title={t("Download")}
                                                            onClick={() => handleFileOpen(activeFileName)}
                                                        >
                                                            <MdOutlineFileDownload size={30}/>
                                                        </IconButton>
                                                        {
                                                            !selectedContract.enterprise_signature &&
                                                            !selectedContract.freelance_signature &&
                                                            <>
                                                                {
                                                                    selectedContract?.creatorUser !== sessionType &&
                                                                    <IconButton
                                                                        color="secondary"
                                                                        title={t("Reject the contract")}
                                                                        onClick={(e) => handleClickAction(e, "Rejected")}
                                                                    >
                                                                        <MdOutlineDelete size={30}/>
                                                                    </IconButton>
                                                                }
                                                                {
                                                                    selectedContract?.creatorUser === sessionType &&
                                                                    <IconButton
                                                                        color="secondary"
                                                                        title={t("Cancel the contract")}
                                                                        onClick={(e) => handleClickAction(e, "Canceled")}
                                                                    >
                                                                        <MdOutlineDelete size={30}/>
                                                                    </IconButton>
                                                                }
                                                            </>
                                                        }
                                                    </Stack>
                                                )
                                            }
                                        </Box>
                                        <div className="common-board-pdfviewerbox">
                                            <PDFViewer2
                                                activeFile={activeFile}
                                                fileName={activeFileName}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </Grid>
                    </Grid>

                    <Popup
                        open={showInviteMemberDialog}
                        title={t("Invite user to sign contract")}
                        onDialogClose={() => setShowInviteMemberDialog(false)}
                    >
                        <Box sx={{width: {md: "20vw"}}}>
                            <Grid container rowSpacing={2}>
                                <Grid item xs={12}>
                                    <FieldHolder
                                        fielddesc={t("{{company}} user", {company: selectedContract?.Company?.name})}
                                        type="dropdown"
                                        name="user"
                                        id="user"
                                        isMulti={false}
                                        value={inviteToSignData.user}
                                        formData={inviteToSignData}
                                        setFormData={setInviteToSignData}
                                        datas={enterpriseUsers}
                                        canModify={true}
                                        mandatoryFlag={true}
                                        error={formErrors.user}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FieldHolder
                                        fielddesc={t("Message")}
                                        type="multiline"
                                        name="message"
                                        id="message"
                                        value={inviteToSignData.message}
                                        onChange={handleInviteToSignChange}
                                        canModify={true}
                                    />
                                </Grid>
                                <Grid item container justifyContent="space-between" xs={12}>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="light"
                                        onClick={() => setShowInviteMemberDialog(false)}
                                    >
                                        {t("Cancel")}
                                    </Button>

                                    <Button
                                        disabled={inviteToSignLoading}
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        onClick={sendInviteToSignEmail}
                                    >
                                        {t("Send")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Popup>
                </div>
            </div>
        }
    </>
};
