import {AppLayout} from "../../../layouts/AppLayout";
import {useSelector} from "react-redux";
import {QuoteListSection} from "../../../components/Forms/Mission/Sections/QuoteSection/QuoteListSection";
import {useTranslation} from "react-i18next";
import Functions from "../../../utils/Functions";
import {useState} from "react";
import {DisplayBar} from "../../../components/UI/DisplayBar";
import {Box} from "@mui/material";
import {QuoteKanban} from "./QuoteKanban";

export const QuotesBoard = () => {
    const {t} = useTranslation();
    const sessionType = useSelector((state) => state.auth.sessionType);
    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    const [rowCount, setRowCount] = useState(0);
    const [displayType, setDisplayType] = useState(isEnterpriseSession ? "Kanban" : "List");

    const displayItems = [
        {key: 'Kanban', name: 'Kanban'},
        {key: 'List', name: 'List'}
    ];

    const handleDisplayMenuClick = (newDisplay) => {
        setDisplayType(newDisplay);
    };

    return (
        <AppLayout className="content-full-height">
            <div className="pageheader">
                <h1 className="pagetitle">
                    {isEnterpriseSession ? t("Quotes") : t("My quotes")}
                    <p className="header-data-row-count">{rowCount}</p>
                </h1>
            </div>
            {isEnterpriseSession && (
                <DisplayBar
                    displayItems={displayItems}
                    displayType={displayType}
                    onDisplayMenuClick={handleDisplayMenuClick}
                />
            )}
            <Box className="table-layout h-100">
                {displayType === 'Kanban' && isEnterpriseSession && (
                    <QuoteKanban onListChange={setRowCount}/>
                )}
                {displayType === 'List' && (
                    <QuoteListSection sessionType={sessionType} isSection={false} onListChange={setRowCount}/>
                )}
            </Box>
        </AppLayout>
    );
};

export default QuotesBoard;