import filesFreelanceProfile from "./filesFreelanceProfile";
import skillService from "./skillService";
import preferenceService from "./preferenceService";
import {requestApi} from "./apiService";
import Functions from "../utils/Functions";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/freelances/";

export const fetchFreelanceProfiles = async (query, token, abortSignal) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token, abortSignal);
    } catch (error) {
        console.error("Error fetching freelances profiles :", error);
    }
};

export const fetchBasicFreelances = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}basic?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching basic freelances profiles :", error);
        throw error;
    }
};

export const fetchFreelanceProfile = async (freelanceId, token) => {
    try {
        const data = await requestApi(`${BASE_URL}${freelanceId}`, 'GET', {}, token);
        if (data?.User) {
            data.User.picture = data.User?.picture ?? "default";
        }
        if (data?.Preferences) {
            data.Preferences = preferenceService.groupPreferencesByType(data.Preferences);
        }
        if (data?.FreelanceProfileSkills) {
            const skills = data.FreelanceProfileSkills.map(item => item.Skill);
            data.FreelanceProfileSkills = skillService.groupSkillsByType(skills);
        }
        if (data?.FreelanceProfileJobKeywords) {
            data.FreelanceProfileJobKeywords = data.FreelanceProfileJobKeywords?.map(item => item.JobKeyword);
        }
        filesFreelanceProfile.setFreelanceProfile(data);

        return data;
    } catch (error) {
        console.error("Error fetching freelance profile:", error);
        throw error;
    }
};

export const updateFreelanceProfile = async (freelanceId, freelanceData, token) => {
    try {
        return requestApi(`${BASE_URL}modify/${freelanceId}`, 'PUT', freelanceData, token);
    } catch (error) {
        console.error("Error updating freelance profile:", error);
        throw error;
    }
};

export const fetchDefaultJobKeywords = async (token) => {
    try {
        return requestApi(`${BASE_URL}job-keywords/get-defaults`, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching preferences:', error)
        throw error
    }
}

export const fetchCompanyFreelanceNote = async (freelanceId, token) => {
    try {
        return requestApi(`${BASE_URL}notes/${freelanceId}`, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching freelance profile note :", error);
        throw error;
    }
}

export const saveCompanyFreelanceNote = async (data, token) => {
    try {
        return requestApi(`${BASE_URL}notes/save`, 'POST', data, token);
    } catch (error) {
        console.error("Error creating company freelance note:", error);
        throw error;
    }
};

export const groupFreelanceProfileTagsByType = (tags) => {
    const tagTypes = Object.values(Functions.FREELANCE_PROFILE_TAGS_TYPE);

    return tagTypes.reduce((groupedTags, type) => {
        groupedTags[type] = tags.filter(
            (value) => value.type === type
        )
        return groupedTags;
    }, {});
}