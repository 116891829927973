import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import {MessageComponent} from "../../../../UI/MessageComponent";
import {FieldHolder} from "../../../FieldHolder";
import RatingSelector from "../../../../Inputs/RatingSelector";
import Functions from "../../../../../utils/Functions";
import Utils from "../../../../../utils/Utils";
import {
    createEvaluation,
    updateCustomerEvaluation,
    updateEvaluation
} from "../../../../../services/freelanceEvaluationService";


export const AssessmentForm = ({assessment, canModify, onSaveFinish}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [message, setMessage] = useState({});
    const [assessmentData, setAssessmentData] = useState(assessment);

    const handleAssessmentChange = (e) => {
        const {name, value} = e.target;
        setAssessmentData({...assessmentData, [name]: value});
    };

    const handleRatingClick = (name, rating) => {
        if (canModify) {
            setAssessmentData({...assessmentData, [name]: rating});
        }
    };

    const handleAssessmentSave = async (e) => {
        e.preventDefault();

        let data = {...assessmentData};

        const verifyData = Utils.verifyDataAssessment(t, data, false);
        if (verifyData.type === "error") {
            setMessage(verifyData);
        } else {
            try {
                setMessage({type: "loading", message: t("Saving data")});
                let result;
                if (data.id) {
                    if (data.assessorUser === Functions.SESSION_TYPE_ENTERPRISE) {
                        result = await updateEvaluation(data.id, data, token);
                    } else {
                        result = await updateCustomerEvaluation(data.id, data);
                    }
                } else {
                    result = await createEvaluation(data, token);
                }
                if (result.error) {
                    setMessage({type: "error", message: result.error});
                } else {
                    setMessage({type: "success", message: t("Data saved successfully")});
                    onSaveFinish && onSaveFinish(true);
                }
            } catch (error) {

            }
        }
    };

    return <Box>
        <Grid container direction="column" spacing={2}>
            {message.type &&
                <Grid item xs={12}>
                    <MessageComponent
                        type={message.type}
                        message={message.message}
                        width="100%"
                    />
                </Grid>
            }

            {assessmentData?.assessorUser === Functions.SESSION_TYPE_ENTERPRISE && (
                <>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Assessment Manager 2")}
                            type="text"
                            value={`${assessmentData?.assessorFirstName} ${assessmentData?.assessorLastName}`}
                            canModify={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t('Date')}
                            type="date"
                            name="date"
                            id="evaluation_date"
                            placeholder="Date"
                            value={assessmentData?.date}
                            onChange={handleAssessmentChange}
                            canModify={true}
                        />
                    </Grid>
                </>
            )}

            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                    <RatingSelector
                        label={t("On a scale of 0 to 10, how likely are you to recommend the contingent worker to a colleague?")}
                        name="nps"
                        onChange={handleRatingClick}
                        value={assessmentData?.nps}
                        min={0}
                        max={10}
                        canModify={canModify}
                        justifyContent="start"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t('Why?')}
                        type="multiline"
                        name="comment"
                        id="evaluation_comment"
                        placeholder={t('Write here')}
                        value={assessmentData?.comment}
                        onChange={handleAssessmentChange}
                        canModify={canModify}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <FieldHolder
                    fielddesc={t("Share the evaluation with the contingent worker")}
                    type="checkbox"
                    name="sharedWithTheFreelancer"
                    id="shared_with_the_freelancer"
                    value={assessmentData?.sharedWithTheFreelancer}
                    onChange={handleAssessmentChange}
                    canModify={canModify}
                />
            </Grid>

            {canModify && (
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleAssessmentSave}
                    >
                        {
                            assessmentData?.assessorUser === Functions.SESSION_TYPE_ENTERPRISE
                                ? t("Save")
                                : t("Validate")
                        }
                    </Button>
                </Grid>
            )}
        </Grid>
    </Box>
};

