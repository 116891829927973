import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {addUserToCompany} from "../../services/userService";
import {FieldHolder} from "../Forms/FieldHolder";
import {MessageComponent} from "../UI/MessageComponent";
import Utils from "../../utils/Utils";
import Popup from "./Popup";
import {Box, Button, Grid} from "@mui/material";

export const EnterprisePopup = ({plans, roles, open, onClose}) => {
    const token = useSelector((state) => state.auth.token);
    const {t} = useTranslation();

    const defaultData = {
        UserRoleId: null,
        UserPlanId: null,
        firstName: "",
        lastName: "",
        email: "",
        main_type: "Enterprise",
        password: Utils.generateRandomPassword(15),
    };
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(defaultData);
    const [userPlans, setUserPlans] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [message, setMessage] = useState({});
    const [formErrors, setFormErrors] = useState({});

    const formRef = useRef(null);
    const requiredFields = ['firstName', 'lastName', 'email', 'UserRoleId', 'UserPlanId'];

    useEffect(() => {
        setUserPlans(plans);
    }, [plans]);

    useEffect(() => {
        setUserRoles(roles);
    }, [roles]);

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = (e) => {
        e?.preventDefault();
        e?.stopPropagation();

        const {valid, errors} = Utils.validateRequiredFields(formData, requiredFields);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return;
        }

        (async () => {
            try {
                setLoading(true);

                const responseData = await addUserToCompany({
                    userData: {userData: formData},
                    token: token,
                });
                setLoading(false);
                if (responseData.success) {
                    setMessage({});
                    setFormData(defaultData);
                    onClose();
                } else {
                    setMessage({type: "error", message: responseData.error});
                }
            } catch (error) {
                console.error(error);
            }
        })();
    };

    return (
        <Popup
            open={open}
            title={t("Add a user")}
            onDialogClose={onClose}
        >
            <Box sx={{width: {md: "30vw"}}}>
                <form ref={formRef} onSubmit={handleSubmit} noValidate>
                    <Grid container columns={12} spacing={2}>
                        {message.type && (
                            <Grid item xs={12}>
                                <MessageComponent
                                    type={message?.type}
                                    message={message?.message}
                                    width="100%"
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} md={6}>
                            <FieldHolder
                                fielddesc={t("First name")}
                                type="text"
                                name="firstName"
                                id="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                mandatoryFlag={true}
                                error={formErrors.firstName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FieldHolder
                                fielddesc={t("Last name")}
                                type="text"
                                name="lastName"
                                id="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                mandatoryFlag={true}
                                error={formErrors.lastName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FieldHolder
                                fielddesc={t("Email")}
                                type="email"
                                name="email"
                                id="lastName"
                                value={formData.email}
                                onChange={handleChange}
                                mandatoryFlag={true}
                                error={formErrors.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FieldHolder
                                fielddesc={t("Plan")}
                                type="dropdown"
                                name="UserPlanId"
                                id="userPlanId"
                                value={formData?.UserPlanId}
                                datas={userPlans}
                                formData={formData}
                                setFormData={setFormData}
                                mandatoryFlag={true}
                                error={formErrors.UserPlanId}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FieldHolder
                                fielddesc={t("Role")}
                                type="dropdown"
                                name="UserRoleId"
                                id="userRoleId"
                                value={formData?.UserRoleId}
                                datas={userRoles}
                                formData={formData}
                                setFormData={setFormData}
                                mandatoryFlag={true}
                                error={formErrors.UserRoleId}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{textAlign: "center"}}>
                                <Button
                                    disabled={loading}
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                >{t("Save")}</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Popup>
    );
};
