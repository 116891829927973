import {AppLayout} from "../../../layouts/AppLayout";
import {ContractListSection} from "../../../components/Forms/Mission/Sections/ContractSection/ContractListSection";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Functions from "../../../utils/Functions";
import {useState} from "react";
import {DisplayBar} from "../../../components/UI/DisplayBar";
import {Box} from "@mui/material";
import {ContractKanban} from "./ContractKanban";

export const ContractsBoard = () => {
    const {t} = useTranslation();
    const sessionType = useSelector((state) => state.auth.sessionType);
    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    const [rowCount, setRowCount] = useState(0);
    const [displayType, setDisplayType] = useState(isEnterpriseSession ? "Kanban" : "List");

    const displayItems = [
        {key: 'Kanban', name: 'Kanban'},
        {key: 'List', name: 'List'}
    ];

    const handleDisplayMenuClick = (newDisplay) => {
        setDisplayType(newDisplay);
    };

    return (
        <AppLayout className="content-full-height">
            <div className="pageheader">
                <h1 className="pagetitle">
                    {isEnterpriseSession ? t("Contracts") : t("My contracts")}
                    <p className="header-data-row-count">{rowCount}</p>
                </h1>
            </div>
            {isEnterpriseSession && (
                <DisplayBar
                    displayItems={displayItems}
                    displayType={displayType}
                    onDisplayMenuClick={handleDisplayMenuClick}
                />
            )}
            <Box className="table-layout h-100">
                {displayType === 'Kanban' && isEnterpriseSession && (
                    <ContractKanban onListChange={setRowCount}/>
                )}
                {displayType === 'List' && (
                    <ContractListSection sessionType={sessionType} isSection={false} onListChange={setRowCount}/>
                )}
            </Box>
        </AppLayout>
    );
};

export default ContractsBoard;