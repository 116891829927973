import React, {useEffect, useState} from "react";
import {AppLayout} from "../../../layouts/AppLayout";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {fetchInvoice} from "../../../services/invoiceService";
import {InvoiceDetailSection} from "../../../components/Forms/Mission/Sections/InvoiceSection/InvoiceDetailSection";

export const InvoiceDetail = () => {
    const {id} = useParams();
    const token = useSelector((state) => state.auth.token);
    const sessionType = useSelector((state) => state.auth.sessionType);

    const [invoice, setInvoice] = useState({});

    useEffect(() => {
        (async () => {
            if (typeof id !== "undefined") {
                const fetchedInvoice = await fetchInvoice(id, token);
                setInvoice(fetchedInvoice);
            }
        })();
    }, [id, token]);

    return (
        <>
            <AppLayout>
                <InvoiceDetailSection
                    selectedInvoice={invoice}
                    setSelectedInvoice={setInvoice}
                    sessionType={sessionType}
                    isSection={false}
                />
            </AppLayout>
        </>
    );
};