import CryptoJS from "crypto-js";
import {requestApi} from "./apiService";

const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY;
const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/auth/";

export const createUser = async (userData, companyData) => {
    const encryptedUserData = CryptoJS.AES.encrypt(JSON.stringify(userData), secretKey).toString();
    const encryptedCompanyData = CryptoJS.AES.encrypt(JSON.stringify(companyData), secretKey).toString();

    const combinedObj = {encryptedUserData, encryptedCompanyData};

    try {
        const response = await fetch(`${BASE_URL}signup`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(combinedObj),
        });
        return await response.json();
    } catch (error) {
        console.error("Error creating user:", error);
        throw error;
    }
};

export const verifyUser = async ({email, token}) => {
    try {
        const response = await fetch(`${BASE_URL}verifyuser`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: token,
            },
            body: JSON.stringify({email}),
        });

        // Vérifier le statut de la réponse
        if (response.status === 204) {
            return null; // Aucune donnée n'a été trouvée
        }

        return await response.json();
    } catch (error) {
        console.error("Error verifying user:", error);
        throw error;
    }
};

export const addUserToCompany = async ({userData, token}) => {
    const encryptedUserData = CryptoJS.AES.encrypt(
        JSON.stringify(userData),
        secretKey
    ).toString();
    const combinedObj = {encryptedUserData};
    try {
        const response = await fetch(`${BASE_URL}addusertocompany`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: token,
            },
            body: JSON.stringify(combinedObj),
        });
        return await response.json();
    } catch (error) {
        console.error("Error creating user:", error);
        throw error;
    }
};

export const loginUser = async (userData) => {
    const encryptedUserData = CryptoJS.AES.encrypt(
        JSON.stringify(userData),
        secretKey
    ).toString();
    try {
        const response = await fetch(`${BASE_URL}login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({encryptedUserData}),
        });
        return await response.json();
    } catch (error) {
        console.error("Error retrieving user:", error);
        throw error;
    }
};

export const directAuthentification = async (encryptedToken) => {
    try {
        const response = await fetch(`${BASE_URL}directauthentification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({encryptedToken}),
        });
        return await response.json();
    } catch (error) {
        console.error('Error retrieving user:', error);
        throw error;
    }
};

export const fetchUser = async (token) => {
    try {
        const response = await fetch(`${BASE_URL}get`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: token,
            },
        });

        const data = await response.json();
        return await JSON.parse(
            CryptoJS.AES.decrypt(data.encryptedUser, secretKey).toString(
                CryptoJS.enc.Utf8
            )
        );
    } catch (error) {
        console.error("Error retrieving user:", error.message);
        throw error;
    }
};

export const findUser = async (userId, token) => {
    try {
        const response = await fetch(`${BASE_URL}find/${userId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: token,
            },
        });

        const data = await response.json();
        return await JSON.parse(
            CryptoJS.AES.decrypt(data.encryptedUser, secretKey).toString(
                CryptoJS.enc.Utf8
            )
        );
    } catch (error) {
        console.error("Error retrieving user:", error.message);
        throw error;
    }
};

export const updateUser = async ({userData, token}) => {
    try {
        const response = await fetch(`${BASE_URL}modify`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: token,
            },
            body: JSON.stringify(userData),
        });
        return await response.json();
    } catch (error) {
        console.error("Error updating user:", error);
        throw error;
    }
};

export const sendResetPasswordEmail = async (email) => {
    try {
        const response = await fetch(`${BASE_URL}send-reset-password-email`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({email}),
        });
        return await response.json();
    } catch (error) {
        console.error("Error in resset password api", error);
        throw error;
    }
};

export const resetPassword = async (token, password) => {
    try {
        const response = await fetch(`${BASE_URL}reset-password`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({token, password}),
        });
        return await response.json();
    } catch (error) {
        console.error("Error in resset password api", error);
        throw error;
    }
};

export const saveDisplayConfig = async (data, token) => {
    try {
        return requestApi(`${BASE_URL}save-display-config`, 'PUT', data, token);
    } catch (error) {
        console.error("Error saving user display config:", error);
        throw error;
    }
};

export const fetchUserPlans = async (token) => {
    try {
        return requestApi(`${BASE_URL}plans`, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching user plans:', error)
        throw error
    }
}

export const fetchUserRoles = async (token) => {
    try {
        return requestApi(`${BASE_URL}roles`, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching user roles:', error)
        throw error
    }
}