import React, {forwardRef, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, Grid} from "@mui/material";
import {useSelector} from "react-redux";
import Utils from "../../../../../utils/Utils";
import {createMission, updateMission} from "../../../../../services/missionService";
import {FieldHolder} from "../../../FieldHolder";
import Functions from "../../../../../utils/Functions";
import TagService from "../../../../../services/tagService";
import PermissionService from "../../../../../services/permissionService";

export const OtherAssignmentForm = forwardRef(({assignment, canModify, onSaveFinish}, ref) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(assignment);
    const [tags, setTags] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const formRef = useRef(null);
    const descriptionRef = useRef(null);
    const isSuperAdministrator = PermissionService.hasRole(connectedUser, Functions.USER_ROLE.ADMIN);

    const requiredFields = ['name'];

    useEffect(() => {
        setFormErrors({});

        (async () => {
            const fetchedTags = await TagService.getMissionTags({}, token);
            if (!fetchedTags?.error) {
                const fetchedTagsData = TagService.groupTagsByType(fetchedTags.data);
                setTags(fetchedTagsData);
            }
        })();
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    };

    const handleSubmit = (e) => {
        e?.preventDefault();
        e?.stopPropagation();

        const {valid, errors} = Utils.validateRequiredFields(data, requiredFields);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return;
        }

        (async () => {
            setLoading(true);

            if (descriptionRef.current) {
                const editor = descriptionRef.current.getEditor();
                editor.blur();
            }

            let response;
            if (data.id) {
                response = await updateMission(data.id, data, token);
            } else {
                response = await createMission(data, token);
            }
            onSaveFinish && onSaveFinish(!response?.error);

            setLoading(false);
        })()
    };

    const handleEditOption = async (option, newLabel) => {
        if (option.value !== option.label) {
            await TagService.modifyTag(option.value, {name: newLabel}, token);
        }
    };

    const handleDeleteOption = async (option) => {
        if (option.value !== option.label) {
            await TagService.deleteTag(option.value, token);
        }
    };

    if (ref) {
        ref.current = handleSubmit;
    }

    return (
        <form ref={formRef} onSubmit={handleSubmit} noValidate>
            <Box sx={{display: "flex", flexDirection: "column", gap: "21px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Assignment name")}
                            type="text"
                            name="name"
                            id="name"
                            defaultValue={data?.name}
                            onBlur={handleChange}
                            canModify={canModify}
                            mandatoryFlag={true}
                            error={formErrors.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Assignment Manager")}
                            type="text"
                            name="customOperationalManager"
                            id="customOperationalManager"
                            defaultValue={data?.customOperationalManager}
                            onBlur={handleChange}
                            canModify={canModify}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Assignment dates")}
                            type="text"
                            name="customDate"
                            id="customDate"
                            defaultValue={data?.customDate}
                            onBlur={handleChange}
                            canModify={canModify}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Assignment location")}
                            type="text"
                            name="address"
                            id="address"
                            defaultValue={data?.address}
                            onBlur={handleChange}
                            canModify={canModify}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            ref={descriptionRef}
                            fielddesc={t("Activity deliverable")}
                            type="wysiwyg"
                            name="description"
                            id="description"
                            value={data?.description}
                            onBlur={handleChange}
                            canModify={canModify}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Final customer")}
                            type="dropdown"
                            name="MissionTags.mission_final_customer"
                            id="MissionTags.mission_final_customer"
                            value={data?.MissionTags?.mission_final_customer}
                            datas={tags?.mission_final_customer}
                            formData={data}
                            setFormData={setData}
                            canModify={canModify}
                            ismulti={true}
                            allowAdd={isSuperAdministrator}
                            allowManageOption={isSuperAdministrator}
                            onEditOption={handleEditOption}
                            onDeleteOption={handleDeleteOption}
                        />
                    </Grid>
                </Grid>
                {canModify && !ref && (
                    <Box sx={{textAlign: "center"}}>
                        <Button
                            disabled={loading}
                            type="submit"
                            variant="contained"
                            color="secondary"
                        >{t("Save")}</Button>
                    </Box>
                )}
            </Box>
        </form>
    );
});