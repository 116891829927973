import React, {memo, useEffect, useMemo, useState} from "react";

import {useNavigate, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
    FaUser,
    FaBriefcase,
    FaFileInvoice,
    FaChartBar,
    FaCog,
    FaFileContract,
    FaCreditCard, FaRegQuestionCircle, FaRegListAlt, FaRegIdCard, FaBuilding, FaHandshake, FaUsers,
} from "react-icons/fa";

import smarteemLogo from "../../assets/smarteem_logo.svg";
import smarteemLogoWhite from "../../assets/smarteem_logo_white.svg";
import {LogoutComponent} from "./LogoutComponent";

import "./appbar.css";
import {AppBar, Box, Drawer, IconButton, Stack, Toolbar} from "@mui/material";
import {MdMenu} from "react-icons/md";
import ResponsiveComponent from "./ResponsiveComponent";
import Functions from "../../utils/Functions";
import PermissionService from "../../services/permissionService";
import {Display} from "../Inputs/Display";

export const Menu = memo(() => {
    const navigate = useNavigate();
    const location = useLocation();
    const connectedUser = useSelector((state) => state.auth);
    const sessionType = useSelector((state) => state.auth.sessionType);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const {t} = useTranslation();

    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;
    const homePageLink = isEnterpriseSession ? "/pool" : "/profile";
    const path = location.pathname;

    useEffect(() => {
        let bodyClassName;
        if (sessionType) {
            bodyClassName = `body-${sessionType.toString().toLowerCase()}`
            document.body.classList.add(bodyClassName);
        }

        return () => {
            document.body.classList.remove(bodyClassName);
        };
    }, [sessionType]);

    const isPathActive = (path, prefix) => path.startsWith(prefix);

    const handleMenuClick = (url, module = "") => {
        if (isEnterpriseSession) {
            if (!PermissionService.isGranted(connectedUser, module)) {
                return;
            }
        }
        navigate(url);
    };

    const MenuItem = ({prefix, icon: Icon, label, tag, onClick, children}) => (
        <li>
            <div
                style={{display: "flex"}}
                onClick={onClick}
                className={
                    isPathActive(path, prefix)
                        ? "active-navigation-link navigation-link"
                        : "navigation-link"
                }
            >
                <Icon className="app-bar-menu-logo-small"/>
                <span>{t(label)}</span>
                {tag}
            </div>
            {children}
        </li>
    );

    const logoDisplay = useMemo(() => {
        return connectedUser.companyLogo ? (
            <Display
                type="image"
                value={connectedUser.companyLogo}
                displaySkeleton={false}
            />
        ) : (
            <img src={smarteemLogo} alt="Smarteem Logo" className="file-image"/>
        );
    }, [connectedUser?.companyLogo]);

    let menu;
    const isMissionSubMenuActive = [
        "/missions",
        "/quotes",
        "/contracts",
        "/invoices",
    ].some(prefix => isPathActive(path, prefix));

    if (isEnterpriseSession) {
        const isRecruitmentSubMenuActive = isPathActive(path, '/recruitment');

        menu = (
            <div className="bloc-nav">
                <MenuItem
                    prefix="/pool"
                    icon={FaUser}
                    label="Pool"
                    onClick={() => navigate("/pool")}
                />
                {/*<MenuItem
                    prefix="/sourcing"
                    icon={FaSearch}
                    label="Sourcing"
                    tag={<Tag text="To come"/>}
                    onClick={() => handleMenuClick("/sourcing/sourcingboard", "sourcingBoard")}
                />*/}
                <MenuItem
                    prefix="/recruitment/applications"
                    icon={FaRegIdCard}
                    label="Job applications"
                    onClick={() => handleMenuClick("/recruitment/applications", Functions.MODULE.ATS)}
                >
                    {isRecruitmentSubMenuActive && (
                        <ul className="submenu-bloc-nav">
                            <MenuItem
                                prefix="/recruitment/offers"
                                icon={FaRegListAlt}
                                label="Offers"
                                onClick={() => handleMenuClick("/recruitment/offers", Functions.MODULE.ATS)}
                            />
                            <MenuItem
                                prefix="/recruitment/candidates"
                                icon={FaUser}
                                label="Candidates"
                                onClick={() => handleMenuClick("/recruitment/candidates", Functions.MODULE.ATS)}
                            />
                        </ul>
                    )}
                </MenuItem>
                <MenuItem
                    prefix="/missions"
                    icon={FaBriefcase}
                    label="Assignments"
                    onClick={() => handleMenuClick("/missions/missionsboard", Functions.MODULE.ASSIGNMENT)}
                >
                    {isMissionSubMenuActive && (
                        <ul className="submenu-bloc-nav">
                            <MenuItem
                                prefix="/quotes"
                                icon={FaFileInvoice}
                                label="Quotes"
                                onClick={() => handleMenuClick("/quotes/quotesboard", Functions.MODULE.ASSIGNMENT)}
                            />
                            <MenuItem
                                prefix="/contracts"
                                icon={FaFileContract}
                                label="Contracts"
                                onClick={() => handleMenuClick("/contracts/contractsboard", Functions.MODULE.ASSIGNMENT)}
                            />
                            <MenuItem
                                prefix="/invoices"
                                icon={FaCreditCard}
                                label="Invoices"
                                onClick={() => handleMenuClick("/invoices/invoicesboard", Functions.MODULE.ASSIGNMENT)}
                            />
                        </ul>
                    )}
                </MenuItem>
                <MenuItem
                    prefix="/reporting"
                    icon={FaChartBar}
                    label="Reporting"
                    onClick={() => handleMenuClick("/reporting", Functions.MODULE.REPORTING)}
                />
            </div>
        );
    } else {
        menu = (
            <div className="bloc-nav">
                <MenuItem
                    prefix="/profile"
                    icon={FaUser}
                    label="My profile"
                    onClick={() => navigate("/profile")}
                />
                <MenuItem
                    prefix="/companies"
                    icon={FaBuilding}
                    label="My companies"
                    onClick={() => navigate("/companies/companiesboard")}
                />
                <MenuItem
                    prefix="/missions"
                    icon={FaBriefcase}
                    label="My assignments"
                    onClick={() => navigate("/missions/missionsboard")}
                >
                    {isMissionSubMenuActive && (
                        <ul className="submenu-bloc-nav">
                            <MenuItem
                                prefix="/quotes"
                                icon={FaFileInvoice}
                                label="My quotes"
                                onClick={() => navigate("/quotes/quotesboard")}
                            />
                            <MenuItem
                                prefix="/invoices"
                                icon={FaCreditCard}
                                label="My invoices"
                                onClick={() => navigate("/invoices/invoicesboard")}
                            />
                        </ul>
                    )}
                </MenuItem>
                <MenuItem
                    prefix="/applications"
                    icon={FaRegIdCard}
                    label="My applications"
                    onClick={() => navigate("/applications/applicationsboard")}
                />
            </div>
        );
    }

    const menuAside = <aside className="menu">
        <Stack alignItems="center" className="child-content" sx={{p: 1}}>
            <Box className="app-bar-logo" onClick={() => navigate(homePageLink)}>
                {logoDisplay}
            </Box>
        </Stack>
        <Box className="child-content" style={{marginTop: "10px"}}>
            <nav>
                <ul>{menu}</ul>
            </nav>
        </Box>
        <Box className="child-content">
            <nav>
                <ul>
                    <div className="bloc-nav">
                        {/*<Support/>*/}
                        <li>
                            <a
                                style={{display: "flex", alignItems: "center"}}
                                className="navigation-link"
                                href={
                                    isEnterpriseSession
                                        ? "https://transparent-walk-a0b.notion.site/Vid-os-explicatives-Smarteem-668726e90be34ad1b18c8e13275fddaf"
                                        : "https://transparent-walk-a0b.notion.site/Vid-os-explicatives-Smarteem-02d14a589d774cff84e3237bcfc40757?pvs=4"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaRegQuestionCircle className="app-bar-menu-logo-small"/>
                                <span className="">{t("Help and support")}</span>
                            </a>
                        </li>
                        {!isEnterpriseSession && (
                            <>
                                <MenuItem
                                    prefix="/affiliation"
                                    icon={FaHandshake}
                                    label="Affiliation"
                                    onClick={() => navigate("/affiliation")}
                                />
                                {/*<MenuItem
                                    prefix="/cooptation"
                                    icon={FaUsers}
                                    label="Cooptation"
                                    onClick={() => navigate("/cooptation")}
                                />*/}
                            </>
                        )}
                        <MenuItem
                            prefix="/configuration"
                            icon={FaCog}
                            label="Configuration"
                            onClick={() => navigate("/configuration")}
                        />
                        <li>
                            <LogoutComponent display="link"/>
                        </li>
                    </div>
                </ul>
            </nav>
        </Box>
    </aside>

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <div>
            <ResponsiveComponent mobile tablet>
                <AppBar position="static" color="primary">
                    <Toolbar sx={{width: "100%"}}>
                        <div className="app-bar-logo-container">
                            <img
                                src={smarteemLogoWhite}
                                alt="Smarteem Logo"
                                className="app-bar-logo"
                                onClick={() => navigate(homePageLink)}
                            />
                        </div>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer}
                        >
                            <MdMenu size={28}/>
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Drawer
                    anchor="left"
                    open={drawerOpen}
                    onClose={toggleDrawer}
                >
                    {menuAside}
                </Drawer>
            </ResponsiveComponent>

            <ResponsiveComponent desktop>{menuAside}</ResponsiveComponent>
        </div>
    );
});
