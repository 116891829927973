import React, {useCallback, useEffect, useRef, useState} from "react";
import SectionMenu from "../../UI/SectionMenu";
import Functions from "../../../utils/Functions";
import {MissionDetailSection} from "./Sections/MissionDetailSection/MissionDetailSection";
import Utils from "../../../utils/Utils";
import {useTranslation} from "react-i18next";
import {createMission, updateMission, updateMissionFormat} from "../../../services/missionService";
import {useSelector} from "react-redux";
import {ContractualisationSection} from "./Sections/ContractualisationSection";
import {PaymentModeSection} from "./Sections/PaymentModeSection";
import {ContractListSection} from "./Sections/ContractSection/ContractListSection";
import {MissionMissingFieldAlert} from "./MissionMissingFieldAlert";

import "../form.css";
import {useToast} from "../../../context/ToastProvider";
import {InvoiceListSection} from "./Sections/InvoiceSection/InvoiceListSection";
import {QuoteListSection} from "./Sections/QuoteSection/QuoteListSection";
import {fetchCompany} from "../../../services/companyService";
import {AssessmentListSection} from "./Sections/AssessmentSection/AssessmentListSection";
import {
    FreelanceSatisfactionListSection
} from "./Sections/FreelanceSatisfactionSection/FreelanceSatisfactionListSection";
import {OnboardingSection} from "./Sections/OnboardingSection/OnboardingSection";
import {ContingentWorkerSection} from "./Sections/ContingentWorkerSection";

export const MissionForm = (
    {
        mission,
        missionSection,
        setMissionSection,
        closedMission,
        proposal,
        ...props
    }
) => {
    const {t} = useTranslation();
    const showToast = useToast();
    const token = useSelector((state) => state.auth.token);
    const companyId = useSelector((state) => state.auth.companyId);
    const sessionType = useSelector((state) => state.auth.sessionType);

    const defaultMissingFieldsData = {
        section: {},
        errorTemplate: "",
        fields: []
    };
    const [company, setCompany] = useState({});
    const [formData, setFormData] = useState({});
    const [missingFields, setMissingFields] = useState(defaultMissingFieldsData);

    const formRef = useRef(null);
    const sectionQuoteRef = useRef(null);
    const sectionContractRef = useRef(null);
    const sectionInvoiceRef = useRef(null);
    const sectionAssessmentRef = useRef(null);
    const sectionSatisfactionRef = useRef(null);
    const objectAttachedType = "Mission";

    let sectionMenuItems;
    if ([
        Functions.MISSION_FORMAT.CONTRACT_INVOICE,
        Functions.MISSION_FORMAT.CONTRACT_WITHOUT_INVOICE
    ].includes(formData?.format)) {
        sectionMenuItems = [
            {key: Functions.SECTION_DETAIL_MISSION, name: 'Assignment detail'},
            {key: Functions.SECTION_CONTINGENT_WORKER, name: 'Contingent worker'},
            {key: Functions.SECTION_CONTRACTUALISATION, name: 'Type of contractualization'},
            {key: Functions.SECTION_QUOTE, name: 'Quotes'},
            {key: Functions.SECTION_PAYMENT_METHOD, name: 'Payment terms'},
            {key: Functions.SECTION_CONTRACT, name: 'Contract'},
            {key: Functions.SECTION_ONBOARDING, name: 'Onboarding'},
            {key: Functions.SECTION_FREELANCE_EVALUATION, name: 'Performance assessment'},
            {key: Functions.SECTION_FREELANCE_SATISFACTION, name: 'Contingent worker satisfaction'},
        ];

        if (formData?.format === Functions.MISSION_FORMAT.CONTRACT_INVOICE) {
            sectionMenuItems.splice(6, 0, {key: Functions.SECTION_INVOICE_AND_PAYMENT, name: 'Invoices'});
        }
    } else {
        sectionMenuItems = [
            {key: Functions.SECTION_DETAIL_MISSION, name: 'Assignment detail'},
            {key: Functions.SECTION_CONTINGENT_WORKER, name: 'Contingent worker'},
            {key: Functions.SECTION_PAYMENT_METHOD, name: 'Payment terms'},
            {key: Functions.SECTION_ONBOARDING, name: 'Onboarding'},
            {key: Functions.SECTION_FREELANCE_EVALUATION, name: 'Performance assessment'},
            {key: Functions.SECTION_FREELANCE_SATISFACTION, name: 'Contingent worker satisfaction'},
        ];
    }

    useEffect(() => {
        fetchCompany(companyId, token).then((fetchedCompany) => {
            if (!fetchedCompany?.error) {
                setCompany(fetchedCompany);
            }
        });
    }, [token, companyId]);

    useEffect(() => {
        if (Object.keys(mission).length) {
            setFormData(mission);
        } else if (!props.missionId) {
            if (company.id) {
                setFormData({
                    ...formData,
                    format: company?.missionContractInvoiceCreation
                        ? Functions.MISSION_FORMAT.CONTRACT_INVOICE
                        : Functions.MISSION_FORMAT.OPERATIONAL
                });
            }
        }
    }, [mission, company]);

    const getFinishedSections = useCallback(() => {
        const {contractualisation_type, status_section, staffedFreelanceId} = formData;
        let finishedSections = status_section ?? [];

        if (staffedFreelanceId) {
            finishedSections = Utils.updateStatusSection(finishedSections, Functions.SECTION_CONTINGENT_WORKER);
        }

        const sectionMapping = {
            [Functions.TEXT_CONTRAT_ONLY]: Functions.SECTION_QUOTE,
            [Functions.TEXT_QUOTE_ONLY]: Functions.SECTION_CONTRACT
        };

        if (contractualisation_type && sectionMapping[contractualisation_type]) {
            finishedSections = Utils.updateStatusSection(finishedSections, sectionMapping[contractualisation_type]);
        }

        return finishedSections;
    }, [formData?.contractualisation_type, formData?.status_section, formData?.staffedFreelanceId]);

    const handleSectionClick = (section) => {
        setMissionSection(section);

        // Vérifier l'accès à la section
        validateSection(section);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevFormData) => Utils.updateNestedField(prevFormData, name, value));
    };

    const getMissionRequiredFields = (section, formData) => {
        const fieldsMap = {
            [Functions.SECTION_DETAIL_MISSION]: () => {
                let fields = ['deadline', 'start_date', 'address', 'description'];
                if (formData.format === Functions.MISSION_FORMAT.CONTRACT_INVOICE) {
                    fields.push('hr_contact', 'invoicing_contact');
                }
                if (formData.deadline === Functions.TEXT_CDD) {
                    fields = ['estimated_end_date'];
                }

                return ['name', 'operational_manager', ...fields];
            },
            [Functions.SECTION_CONTRACTUALISATION]: () => ['contractualisation_type'],
            [Functions.SECTION_PAYMENT_METHOD]: () => {
                let fields = [];
                if (formData.paymentMethod === Functions.TEXT_STANDARD_PAYMENT_METHOD) {
                    fields = ['payment_amount', 'currency', 'payment_frequency'];
                } else if (formData.paymentMethod === Functions.TEXT_CUSTOM_PAYMENT_METHOD) {
                    fields = ['customPaymentMethod'];
                }
                return ['paymentMethod', ...fields];
            }
        };

        return fieldsMap[section] ? fieldsMap[section]() : [];
    };

    const validateSection = (section) => {
        let _missingFields = {...defaultMissingFieldsData};

        const updateMissingFields = (sectionKey, fields, errorTemplate = null) => {
            _missingFields = {
                ..._missingFields,
                section: getSection(sectionKey),
                fields,
                ...(errorTemplate && {errorTemplate}),
            };
        };

        switch (section) {
            case Functions.SECTION_CONTINGENT_WORKER:
                _missingFields = validateMissionRequiredFields(
                    getSection(Functions.SECTION_DETAIL_MISSION),
                    _missingFields,
                    ['name']
                );
                break;
            case Functions.SECTION_CONTRACTUALISATION:
                _missingFields = validateMissionRequiredFields(
                    getSection(Functions.SECTION_DETAIL_MISSION),
                    _missingFields
                );
                if (!_missingFields.fields.length && !formData?.staffedFreelanceId) {
                    updateMissingFields(
                        Functions.SECTION_CONTINGENT_WORKER,
                        ['staffedFreelanceId'],
                        "The <0>{{field}}</0> field must be filled in"
                    );
                }
                break;
            case Functions.SECTION_PAYMENT_METHOD:
                if (formData?.format === Functions.MISSION_FORMAT.OPERATIONAL) {
                    _missingFields = validateMissionRequiredFields(
                        getSection(Functions.SECTION_DETAIL_MISSION),
                        _missingFields
                    );
                } else if (
                    (!formData.status_section ||
                        !formData?.status_section.includes(Functions.SECTION_QUOTE)) &&
                    formData?.contractualisation_type !== Functions.TEXT_CONTRAT_ONLY
                ) {
                    updateMissingFields(Functions.SECTION_QUOTE, ['noQuote']);
                }
                break;
            case Functions.SECTION_QUOTE:
                sectionQuoteRef.current?.();
                if (!formData.staffedFreelanceId) {
                    updateMissingFields(
                        Functions.SECTION_CONTINGENT_WORKER,
                        ['staffedFreelanceId'],
                        "The <0>{{field}}</0> field must be filled in"
                    );
                }
                break;
            case Functions.SECTION_CONTRACT:
                sectionContractRef.current?.();
                if (formData?.contractualisation_type !== Functions.TEXT_QUOTE_ONLY) {
                    _missingFields = validatePrevSection(section, _missingFields);
                }
                break;
            case Functions.SECTION_ONBOARDING:
                break;
            case Functions.SECTION_INVOICE_AND_PAYMENT:
                sectionInvoiceRef.current?.();
                break;
            case Functions.SECTION_FREELANCE_EVALUATION:
            case Functions.SECTION_FREELANCE_SATISFACTION:
                const ref = section === Functions.SECTION_FREELANCE_EVALUATION
                    ? sectionAssessmentRef
                    : sectionSatisfactionRef;
                ref.current?.();

                if (!formData.staffedFreelanceId) {
                    updateMissingFields(
                        Functions.SECTION_CONTINGENT_WORKER,
                        ['staffedFreelanceId'],
                        "The <0>{{field}}</0> field must be filled in"
                    );
                }
                break;
            default:
                _missingFields = validatePrevSection(section, _missingFields);
                break;
        }

        setMissingFields(_missingFields);
    };

    const validatePrevSection = (section, _missingFields) => {
        const prevSection = getPrevSection(section);
        if (prevSection) {
            _missingFields = validateMissionRequiredFields(prevSection, _missingFields);
        }
        return _missingFields;
    };

    const validateMissionRequiredFields = (section, _missingFields, requiredFields = []) => {
        if (requiredFields.length === 0) {
            requiredFields = getMissionRequiredFields(section.key, formData);
        }
        const {valid, errors} = Utils.validateRequiredFields(formData, requiredFields);
        if (!valid) {
            return {
                ..._missingFields,
                section: section,
                errorTemplate: "The <0>{{field}}</0> field must be filled in",
                fields: Object.keys(errors)
            };
        }

        return _missingFields;
    }

    const getSection = (section) => {
        const index = sectionMenuItems.findIndex(item => item.key === section);
        return sectionMenuItems[index];
    };

    const getPrevSection = (section) => {
        const index = sectionMenuItems.findIndex(item => item.key === section);
        return sectionMenuItems[index - 1];
    };

    const handleMissionFormatChange = async (event, value) => {
        if (value !== null && value !== undefined && sessionType === Functions.SESSION_TYPE_ENTERPRISE) {
            if (!closedMission) {
                if (!mission?.staffedFreelanceId) {
                    setFormData({...formData, format: value});
                    handleSectionClick(Functions.SECTION_DETAIL_MISSION);
                    if (mission?.id) {
                        await updateMissionFormat(mission.id, {format: value}, token);
                    }
                } else {
                    showToast(
                        t("You can no longer change the type of assignment once a contingent worker has been assigned to it. If you wish to change the assignment type, you must archive this assignment and create a new one."),
                        "warning",
                        10000
                    );
                }
            }
        }
    };

    const handleSubmit = async (data = {}) => {
        if (!Object.keys(data).length) {
            data = {...formData};
        }
        const {valid, errors} = Utils.validateRequiredFields(data, ['name']);
        if (!valid) {
            return {error: true, data: errors};
        }

        try {
            let savedMission;

            if (valid) {
                data.status_section = Utils.updateStatusSection(data.status_section, missionSection);
            } else if (data.status_section) {
                data.status_section = data.status_section.filter(item => item !== missionSection);
            }

            if (typeof mission.id !== "undefined" && mission.id) {
                // Update mission
                delete data?.Brief;
                delete data?.Company;
                delete data?.Quotes;
                delete data?.Contracts;
                delete data?.Invoices;
                delete data?.OperationalManager;
                delete data?.StaffedFreelance;

                savedMission = await updateMission(mission.id, data, token);
            } else {
                // Create a new assignment
                savedMission = await createMission(data, token);
                props.setMissionId && props.setMissionId(savedMission.id);
            }

            if (savedMission.error) {
                return {success: false, message: t("Error saving data")};
            } else {
                props.setTriggerApi && props.setTriggerApi(true);
                return {success: true, data: data};
            }
        } catch (error) {
            return {success: false};
        }
    };

    let helperContent;

    const standardFormClass = proposal
        ? "standardform-w70"
        : (
            missionSection === Functions.SECTION_QUOTE ||
            missionSection === Functions.SECTION_CONTRACT ||
            missionSection === Functions.SECTION_INVOICE_AND_PAYMENT ||
            missionSection === Functions.SECTION_FREELANCE_EVALUATION ||
            missionSection === Functions.SECTION_FREELANCE_SATISFACTION
                ? "w-100 standardform-fixedheight-100"
                : "standardform-w55"
        );

    return <>
        <div className="form-layout">
            {!proposal && (
                <div className="workflowmenu">
                    <SectionMenu
                        color="info"
                        sectionMenuItems={sectionMenuItems}
                        activeSection={missionSection}
                        onSectionMenuClick={handleSectionClick}
                        finishedSections={getFinishedSections()}
                    />
                </div>
            )}
            <form
                ref={formRef}
                className={`standardform ${standardFormClass}`}
                id="missionform"
                style={{alignItems: "start"}}
                noValidate
            >
                {missingFields.fields.length > 0 && <MissionMissingFieldAlert missingFields={missingFields}/>}
                {missingFields.fields.length === 0 &&
                    <>
                        {missionSection === Functions.SECTION_DETAIL_MISSION && (
                            <MissionDetailSection
                                missionId={props.missionId}
                                formData={formData}
                                setFormData={setFormData}
                                company={company}
                                onSave={handleSubmit}
                                sessionType={sessionType}
                                onMissionFormatChange={handleMissionFormatChange}
                                canModify={(
                                    !closedMission &&
                                    sessionType === Functions.SESSION_TYPE_ENTERPRISE
                                )}
                                proposal={proposal}
                            />
                        )}
                        {missionSection === Functions.SECTION_CONTINGENT_WORKER && (
                            <ContingentWorkerSection
                                formData={formData}
                                setFormData={setFormData}
                                onSave={handleSubmit}
                                canModify={(
                                    !closedMission &&
                                    sessionType === Functions.SESSION_TYPE_ENTERPRISE
                                )}
                            />
                        )}
                        {missionSection === Functions.SECTION_CONTRACTUALISATION && (
                            <ContractualisationSection
                                formData={formData}
                                setFormData={setFormData}
                                onSave={handleSubmit}
                                canModify={(
                                    !closedMission &&
                                    sessionType === Functions.SESSION_TYPE_ENTERPRISE
                                )}
                            />
                        )}
                        {missionSection === Functions.SECTION_QUOTE && (
                            <QuoteListSection
                                ref={sectionQuoteRef}
                                formData={formData}
                                setFormData={setFormData}
                                objectAttachedType={objectAttachedType}
                                sessionType={sessionType}
                                canModify={!closedMission}
                            />
                        )}
                        {missionSection === Functions.SECTION_PAYMENT_METHOD && (
                            <PaymentModeSection
                                formData={formData}
                                setFormData={setFormData}
                                handleChange={handleChange}
                                onSave={handleSubmit}
                                canModify={(
                                    !closedMission &&
                                    sessionType === Functions.SESSION_TYPE_ENTERPRISE
                                )}
                            />
                        )}
                        {missionSection === Functions.SECTION_CONTRACT && (
                            <ContractListSection
                                ref={sectionContractRef}
                                formData={formData}
                                setFormData={setFormData}
                                objectAttachedType={objectAttachedType}
                                sessionType={sessionType}
                                canModify={(
                                    !closedMission &&
                                    sessionType === Functions.SESSION_TYPE_ENTERPRISE
                                )}
                            />
                        )}
                        {missionSection === Functions.SECTION_ONBOARDING && (
                            <OnboardingSection
                                formData={formData}
                                setFormData={setFormData}
                                canModify={(
                                    !closedMission &&
                                    sessionType === Functions.SESSION_TYPE_ENTERPRISE
                                )}
                            />
                        )}
                        {missionSection === Functions.SECTION_INVOICE_AND_PAYMENT && (
                            <InvoiceListSection
                                ref={sectionInvoiceRef}
                                formData={formData}
                                setFormData={setFormData}
                                objectAttachedType={objectAttachedType}
                                sessionType={sessionType}
                                canModify={(
                                    !closedMission &&
                                    sessionType === Functions.SESSION_TYPE_FREELANCE
                                )}
                            />
                        )}
                        {missionSection === Functions.SECTION_FREELANCE_EVALUATION && (
                            <AssessmentListSection
                                ref={sectionAssessmentRef}
                                formData={formData}
                                setFormData={setFormData}
                                sessionType={sessionType}
                                canModify={(
                                    !closedMission &&
                                    sessionType === Functions.SESSION_TYPE_ENTERPRISE
                                )}
                            />
                        )}
                        {missionSection === Functions.SECTION_FREELANCE_SATISFACTION && (
                            <FreelanceSatisfactionListSection
                                ref={sectionSatisfactionRef}
                                formData={formData}
                                setFormData={setFormData}
                                sessionType={sessionType}
                                canModify={(
                                    !closedMission &&
                                    sessionType === Functions.SESSION_TYPE_ENTERPRISE
                                )}
                            />
                        )}
                    </>
                }
            </form>
            {helperContent && <div className="helpercard-container">{helperContent}</div>}
        </div>
    </>
};