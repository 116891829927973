import React, {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import "../form.css";
import "../../../pages/page.css";

import {
    fetchEnterpriseProfiles,
    updateEnterpriseProfile
} from "../../../services/enterpriseProfileService";
import {EnterprisePopup} from "../../Popup/EnterprisePopup";
import {Table} from "../../Tables/Table";
import Popup from "../../Popup/Popup";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import {FieldHolder} from "../FieldHolder";
import Functions from "../../../utils/Functions";
import PermissionService from "../../../services/permissionService";
import {fetchUserPlans, fetchUserRoles} from "../../../services/userService";
import {fetchCompany} from "../../../services/companyService";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const OrganizationSection = () => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const companyId = connectedUser.companyId;

    const [data, setData] = useState([]);
    const [triggerApi, setTriggerApi] = useState(true);
    const [showEnterprisePopup, setShowEnterprisePopup] = useState(false);
    const [showRolePopup, setShowRolePopup] = useState(false);
    const fetchEnterpriseProfilesRef = useRef();
    const [rowCount, setRowCount] = useState(0);
    const [filterConfigs, setFilterConfigs] = useState({
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    });
    const dataRole = {id: null, UserRoleId: null, activatedAccount: true};
    const [formData, setFormData] = useState(dataRole);
    const [company, setCompany] = useState({});
    const [userPlans, setUserPlans] = useState([]);
    const [userRoles, setUserRoles] = useState([]);

    const columns = useMemo(() => {
        const _columns = [
            {
                field: 'User.fullName',
                type: 'string',
                headerName: t("Name"),
                minWidth: 150,
                flex: 1.5,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return row.User.fullName;
                }
            },
            {
                field: 'User.email',
                type: 'string',
                headerName: t("Email"),
                minWidth: 150,
                flex: 1.5,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    const email = row.User.email
                    return (
                        <Box className="MuiDataGrid-cellContent" title={email} role="presentation">
                            {email}
                        </Box>
                    );
                }
            },
            {
                field: 'userRole',
                headerName: t("Role"),
                minWidth: 150,
                flex: 1.5,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    const role = t(row?.User?.UserRole?.name);
                    return (
                        <Box className="MuiDataGrid-cellContent text-center" title={role} role="presentation">
                            {role}
                        </Box>
                    );
                }
            },
            {
                field: 'activatedAccount',
                headerName: t("Active account"),
                minWidth: 100,
                flex: 1.0,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    const isActivated = row.User.activatedAccount;
                    return (
                        <Typography color={isActivated ? "info.main" : "light.main"} sx={{fontWeight: "bold"}}>
                            {isActivated ? t("Yes") : t("No")}
                        </Typography>
                    )
                }
            },
        ];
        if (PermissionService.hasRole(connectedUser, Functions.USER_ROLE.ADMIN)) {
            _columns.splice(2, 0, {
                field: 'userPlan',
                headerName: t("Plan"),
                minWidth: 100,
                flex: 1.0,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    const plan = t(row?.User?.UserPlan?.name);
                    return (
                        <Box className="MuiDataGrid-cellContent text-center" title={plan} role="presentation">
                            {plan}
                        </Box>
                    );
                }
            });
            _columns.push({
                field: 'action',
                headerName: '',
                minWidth: 100,
                flex: 1.0,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    if (row.userId !== row?.Company?.superAdministratorUserId) {
                        return (
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={() => handleUserEdit(row)}
                            >
                                {t("Edit")}
                            </Button>
                        );
                    }
                    return null;
                }
            });
        }
        return _columns;
    }, [company]);

    useEffect(() => {
        fetchUserPlans(token).then((fetchedData) => {
            if (!fetchedData?.error) {
                const _userPlans = fetchedData.data
                    .map(plan => ({...plan, name: t(plan.name)}))
                setUserPlans(_userPlans);
            }
        });

        fetchUserRoles(token).then((fetchedData) => {
            if (!fetchedData?.error) {
                const _userRoles = fetchedData.data
                    .filter(role => ![
                        Functions.USER_ROLE.SUPER_ADMIN,
                        Functions.USER_ROLE.FREELANCER
                    ].includes(role.identifier))
                    .map(role => ({...role, name: t(role.name)}))
                setUserRoles(_userRoles);
            }
        });
    }, [token]);

    useEffect(() => {
        fetchCompany(companyId, token).then((fetchedCompany) => {
            if (!fetchedCompany?.error) {
                setCompany(fetchedCompany);
            }
        });
    }, [token, companyId]);

    useEffect(() => {
        fetchEnterpriseProfilesRef.current(filterConfigs);
    }, [filterConfigs, triggerApi]);

    fetchEnterpriseProfilesRef.current = (query = {}) => {
        try {
            if (triggerApi) {
                fetchEnterpriseProfiles(query, token).then((fetchedData) => {
                    if (!fetchedData?.error) {
                        setRowCount(fetchedData.count);
                        setData(fetchedData.data);
                    }
                });
                setTriggerApi(false);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleDataGridChange = (filterConfigs) => {
        setTriggerApi(true);
        setFilterConfigs(filterConfigs);
    };

    const toggleRolePopupVisibility = () => setShowRolePopup(prev => !prev);

    const onEnterprisePopupOpen = () => {
        setShowEnterprisePopup(true);
    };

    const onEnterprisePopupClose = () => {
        setShowEnterprisePopup(false);
        setTriggerApi(true);
    };

    const handleUserEdit = (row) => {
        const user = row?.User;
        setFormData({
            ...formData,
            id: row.id,
            userId: row.userId,
            UserPlanId: user?.UserPlan?.id,
            UserRoleId: user?.UserRole?.id,
            activatedAccount: user?.activatedAccount,
        });
        toggleRolePopupVisibility();
    };

    let button;
    if (PermissionService.hasRole(connectedUser, Functions.USER_ROLE.ADMIN)) {
        button = (
            <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={onEnterprisePopupOpen}
            >
                {t("Add a user")}
            </Button>
        );
    }

    const onDialogRolePopupClose = () => {
        setShowRolePopup(false);
    }

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        (async () => {
            try {
                const response = await updateEnterpriseProfile(formData.id, formData, token);
                if (!response?.error) {
                    setTriggerApi(true);
                    onDialogRolePopupClose();
                }
            } catch (error) {
                console.error(error);
            }
        })();
    };


    return (
        <div className="standardform w-100">
            <div className="card transparent">
                <div className="pageheader">
                    <h3 className="text-title text-theme">
                        {t("My company's users")}
                    </h3>
                    <div className="pageaction">
                        {button}
                    </div>
                </div>

                <Table
                    fullHeight={true}
                    columns={columns}
                    data={data}
                    rowCount={rowCount}
                    pageSize={pageSize}
                    filterConfigs={filterConfigs}
                    onChange={handleDataGridChange}
                />

                {showEnterprisePopup && (
                    <EnterprisePopup
                        plans={userPlans}
                        roles={userRoles}
                        open={showEnterprisePopup}
                        onClose={onEnterprisePopupClose}
                    />
                )}

                <Popup
                    open={showRolePopup}
                    title={t("Access Levels and Roles")}
                    onDialogClose={onDialogRolePopupClose}
                >
                    <Box sx={{width: {md: "25vw"}}}>
                        <Grid container columns={12} spacing={2}>
                            <Grid item xs={12}>
                                <FieldHolder
                                    fielddesc={t("Plan")}
                                    type="dropdown"
                                    name="UserPlanId"
                                    id="userPlanId"
                                    value={formData?.UserPlanId}
                                    datas={userPlans}
                                    formData={formData}
                                    setFormData={setFormData}
                                    mandatoryFlag={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FieldHolder
                                    fielddesc={t("Role")}
                                    type="dropdown"
                                    name="UserRoleId"
                                    id="userRoleId"
                                    value={formData?.UserRoleId}
                                    datas={userRoles}
                                    formData={formData}
                                    setFormData={setFormData}
                                    mandatoryFlag={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FieldHolder
                                    fielddesc={t('Active account')}
                                    type="checkbox"
                                    name="activatedAccount"
                                    id="activated_account"
                                    value={formData?.activatedAccount}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{textAlign: "center"}}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleSubmit}
                                    >{t("Save")}</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Popup>
            </div>
        </div>
    );
};
