import React, {useEffect, useState} from "react";
import {Alert, Box, Button, Grid, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {
    getTotalExpense,
    getTotalExpenseByFreelance, getTotalExpenseByJob,
    getTotalExpenseByMission,
    getTotalExpenseByMonth, getTotalExpenseByUser
} from "../../../services/reportingService";
import {useSelector} from "react-redux";
import Functions from "../../../utils/Functions";
import Utils from "../../../utils/Utils";
import {MdCalendarToday, MdOutlineArrowBack, MdOutlineArrowForward} from "react-icons/md";
import dayjs from "dayjs";
import {FieldHolder} from "../FieldHolder";
import moment from "moment";
import {StackedBarChart} from "../../UI/Chart/StackedBarChart";
import TagService from "../../../services/tagService";

export const ExpenseBoard = () => {
    const {t, i18n} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const sessionType = useSelector((state) => state.auth.sessionType);
    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    moment.locale(i18n.language);

    const [tags, setTags] = useState([]);
    const [dateFilter, toggleDateFilter] = useState(false);
    const [filterConfigs, setFilterConfigs] = useState({
        date: {referenceMonth: '', startDate: '', endDate: ''}
    });
    const currentMonth = moment().format('YYYY-MM');
    const [referenceMonth, setReferenceMonth] = useState(currentMonth);
    const [totalExpense, setTotalExpense] = useState({paid: 0, pending: 0});
    const [totalExpenseByMonth, setTotalExpenseByMonth] = useState({
        months: [],
        paid: [],
        pending: []
    });
    const [totalExpenseByMission, setTotalExpenseByMission] = useState({
        missions: [],
        paid: [],
        pending: []
    });
    const [totalExpenseByJob, setTotalExpenseByJob] = useState({
        jobs: [],
        paid: []
    });
    const [totalExpenseByFreelance, setTotalExpenseByFreelance] = useState({
        freelances: [],
        paid: []
    });
    const [totalExpenseByUser, setTotalExpenseByUser] = useState({
        users: [],
        paid: []
    });

    useEffect(() => {
        if (isEnterpriseSession) {
            TagService.getFreelanceProfileTags({}, token).then((fetchedTags) => {
                if (!fetchedTags?.error) {
                    const fetchedTagsData = TagService.groupTagsByType(fetchedTags.data);
                    setTags(fetchedTagsData);
                }
            });
        }
    }, [token, isEnterpriseSession]);

    useEffect(() => {
        if (isEnterpriseSession) {
            const filter = {filter: JSON.stringify(filterConfigs)};

            getTotalExpense(filter, token).then(data => {
                if (!data?.error) {
                    setTotalExpense(data);
                }
            });

            getTotalExpenseByMission(filter, token).then(data => {
                if (!data?.error) {
                    setTotalExpenseByMission(data);
                }
            });

            getTotalExpenseByJob(filter, token).then(data => {
                if (!data?.error) {
                    setTotalExpenseByJob(data);
                }
            });

            getTotalExpenseByFreelance(filter, token).then(data => {
                if (!data?.error) {
                    setTotalExpenseByFreelance(data);
                }
            });

            getTotalExpenseByUser(filter, token).then(data => {
                if (!data?.error) {
                    setTotalExpenseByUser(data);
                }
            });
        }
    }, [token, isEnterpriseSession, filterConfigs]);

    useEffect(() => {
        if (isEnterpriseSession) {
            filterConfigs.date.referenceMonth = referenceMonth;
            const filter = {filter: JSON.stringify(filterConfigs)};

            getTotalExpenseByMonth(filter, token).then(data => {
                if (!data?.error) {
                    setTotalExpenseByMonth(data);
                }
            });
        }
    }, [token, isEnterpriseSession, referenceMonth, filterConfigs]);

    const handleDateFilterChange = (e) => {
        const {name, value} = e.target;
        const newFormData = Utils.updateNestedField(filterConfigs, name, value);
        setFilterConfigs(newFormData);

        if (name === "date.endDate") {
            setReferenceMonth(
                value ? moment(value).format('YYYY-MM') : currentMonth
            );
        }
    };

    const handlePrevMonth = () => {
        setReferenceMonth(moment(referenceMonth).subtract(1, 'months').format('YYYY-MM'));
    };

    const handleCurrentMonth = () => {
        let currMonth = currentMonth;
        if (filterConfigs.date.endDate && filterConfigs.date.endDate < currentMonth) {
            currMonth = filterConfigs.date.endDate;
        }
        setReferenceMonth(moment(currMonth).format('YYYY-MM'));
    };

    const handleNextMonth = () => {
        setReferenceMonth(moment(referenceMonth).add(1, 'months').format('YYYY-MM'));
    };

    return (
        <Box id="expenseBoard" sx={{width: "100%"}}>
            {isEnterpriseSession && (
                <Stack direction="column" className="w-100" alignItems="start" gap={4}>
                    <Stack direction="column" className="w-100" alignItems="start" gap={2}>
                        <Grid container alignItems="center" spacing={2}>
                            {
                                [
                                    {key: Functions.TAGS_TYPE.FREELANCE_REPORTING_ENTITY, name: t("Team / Department")},
                                    {key: Functions.TAGS_TYPE.FREELANCE_LEGAL_ENTITY, name: t("Legal entity")},
                                    {key: Functions.TAGS_TYPE.FREELANCE_COUNTRY, name: t("Country")},
                                ].map((tagFilter) => {
                                    return (
                                        <Grid item xs={6} md={4} lg={2}>
                                            <FieldHolder
                                                key={tagFilter.key}
                                                fielddesc={tagFilter.name}
                                                type="dropdown"
                                                name={`FreelanceProfileTags.${tagFilter.key}`}
                                                id={`FreelanceProfileTags.${tagFilter.key}`}
                                                value={filterConfigs?.FreelanceProfileTags?.[tagFilter.key]}
                                                datas={tags?.[tagFilter.key]}
                                                formData={filterConfigs}
                                                setFormData={setFilterConfigs}
                                                ismulti={true}
                                            />
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                        <Button
                            variant={!dateFilter ? "contained" : "outlined"}
                            endIcon={<MdCalendarToday/>}
                            color="info"
                            size="small"
                            onClick={() => toggleDateFilter((v) => !v)}
                        >
                            {t("Date")}
                        </Button>
                        {
                            dateFilter && (
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={4} lg={2}>
                                        <FieldHolder
                                            fielddesc={t("Start date 2")}
                                            type="date"
                                            name="date.startDate"
                                            id="date.startDate"
                                            value={filterConfigs.date.startDate}
                                            onChange={handleDateFilterChange}
                                            canModify={true}
                                            minDate={null}
                                            maxDate={filterConfigs.date.endDate ? dayjs(filterConfigs.date.endDate) : null}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={4} lg={2}>
                                        <FieldHolder
                                            fielddesc={t("End date")}
                                            type="date"
                                            name="date.endDate"
                                            id="date.endDate"
                                            value={filterConfigs.date.endDate}
                                            onChange={handleDateFilterChange}
                                            canModify={true}
                                            minDate={filterConfigs.date.startDate ? dayjs(filterConfigs.date.startDate) : null}
                                            maxDate={null}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        }

                        <Grid container spacing={2}>
                            <Grid item xs={6} md={4} lg={2}>
                                <div className="reporting-card h-100" style={{justifyContent: "space-between"}}>
                                    <Typography className="title">{t("Amount of invoices paid")}</Typography>
                                    <Typography variant="label" color="primary.light">
                                        {Utils.showAmount(totalExpense.paid)}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={4} lg={2}>
                                <div className="reporting-card h-100" style={{justifyContent: "space-between"}}>
                                    <Typography
                                        className="title">{t("Amount of validated and pending invoices")}</Typography>
                                    <Typography variant="label">
                                        {Utils.showAmount(totalExpense.pending)}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Stack>

                    <Grid container spacing={4}>
                        <Grid item xs={12} lg={8}>
                            <Stack spacing={1.5}>
                                <Stack direction="row" justifyContent="center" spacing={1}>
                                    <Button
                                        disabled={referenceMonth < filterConfigs.date.startDate}
                                        variant="outlined"
                                        className="btn-navigation"
                                        color="light"
                                        size="small"
                                        onClick={handlePrevMonth}
                                    ><MdOutlineArrowBack size={20} color="primary"/></Button>
                                    <Button
                                        variant="outlined"
                                        className="btn-current-month"
                                        color="light"
                                        size="small"
                                        onClick={handleCurrentMonth}
                                    >{t("Current month")}</Button>
                                    <Button
                                        disabled={currentMonth === referenceMonth}
                                        variant="outlined"
                                        className="btn-navigation"
                                        color="light"
                                        size="small"
                                        onClick={handleNextMonth}
                                    ><MdOutlineArrowForward size={20} color="primary"/></Button>
                                </Stack>
                                <div className="reporting-card h-100">
                                    <Typography
                                        className="title">{t("Amount of invoices paid, validated and awaiting validation per month")}</Typography>
                                    <Box className="w-100">
                                        {totalExpenseByMonth.months.length > 0 && (
                                            <StackedBarChart
                                                currency="€"
                                                chartHeight={350}
                                                categories={totalExpenseByMonth.months.map(month => {
                                                    const formattedMonth = moment(month).format('MMMM YYYY');
                                                    return formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1);
                                                })}
                                                series={[
                                                    {
                                                        name: t("Amount of invoices paid"),
                                                        data: totalExpenseByMonth.paid,
                                                        color: 'var(--primary-light-color)'
                                                    },
                                                    {
                                                        name: t("Amount of validated and pending invoices"),
                                                        data: totalExpenseByMonth.pending,
                                                        color: 'var(--info-main-color)'
                                                    }
                                                ]}
                                            />
                                        )}
                                    </Box>
                                </div>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item xs={12} lg={8}>
                            <div className="reporting-card h-100">
                                <Typography
                                    className="title">{t("Invoices paid, validated and awaiting validation by assignment")}</Typography>
                                <Box className="reporting-content">
                                    {totalExpenseByMission.missions.length > 0
                                        ? (
                                            <StackedBarChart
                                                horizontal={true}
                                                currency="€"
                                                categories={totalExpenseByMission.missions}
                                                series={[
                                                    {
                                                        name: t("Amount of invoices paid"),
                                                        data: totalExpenseByMission.paid,
                                                        color: 'var(--primary-light-color)'
                                                    },
                                                    {
                                                        name: t("Amount of validated and pending invoices"),
                                                        data: totalExpenseByMission.pending,
                                                        color: 'var(--info-main-color)'
                                                    }
                                                ]}
                                            />
                                        )
                                        : (
                                            <Alert icon={false} severity="info" sx={{width: '100%'}}>
                                                {t("No data available")}
                                            </Alert>
                                        )
                                    }
                                </Box>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item xs={12} lg={4}>
                            <div className="reporting-card h-100">
                                <Typography className="title">{t("Amount of invoices paid by job")}</Typography>
                                <Box className="reporting-content">
                                    {totalExpenseByJob.jobs.length > 0
                                        ? (
                                            <StackedBarChart
                                                horizontal={true}
                                                currency="€"
                                                categories={totalExpenseByJob.jobs}
                                                series={[
                                                    {
                                                        name: t("Amount of invoices paid"),
                                                        data: totalExpenseByJob.paid,
                                                        color: 'var(--info-main-color)'
                                                    }
                                                ]}
                                            />
                                        )
                                        : (
                                            <Alert icon={false} severity="info" sx={{width: '100%'}}>
                                                {t("No data available")}
                                            </Alert>
                                        )
                                    }
                                </Box>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <div className="reporting-card h-100">
                                <Typography className="title">{t("Amount of invoices paid per contingent worker")}</Typography>
                                <Box className="reporting-content">
                                    {totalExpenseByFreelance.freelances.length > 0
                                        ? (
                                            <StackedBarChart
                                                horizontal={true}
                                                currency="€"
                                                categories={totalExpenseByFreelance.freelances}
                                                series={[
                                                    {
                                                        name: t("Amount of invoices paid"),
                                                        data: totalExpenseByFreelance.paid,
                                                        color: 'var(--secondary-main-color)'
                                                    }
                                                ]}
                                            />
                                        )
                                        : (
                                            <Alert icon={false} severity="info" sx={{width: '100%'}}>
                                                {t("No data available")}
                                            </Alert>
                                        )
                                    }
                                </Box>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item xs={12} lg={4}>
                            <div className="reporting-card h-100">
                                <Typography
                                    className="title">{t("Amount of invoices paid per assignment manager")}</Typography>
                                <Box className="reporting-content">
                                    {totalExpenseByUser.users.length > 0
                                        ? (
                                            <StackedBarChart
                                                horizontal={true}
                                                currency="€"
                                                categories={totalExpenseByUser.users}
                                                series={[
                                                    {
                                                        name: t("Amount of invoices paid"),
                                                        data: totalExpenseByUser.paid,
                                                        color: 'var(--primary-light-color)'
                                                    }
                                                ]}
                                            />
                                        )
                                        : (
                                            <Alert icon={false} severity="info" sx={{width: '100%'}}>
                                                {t("No data available")}
                                            </Alert>
                                        )
                                    }
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Stack>
            )
            }
        </Box>
    )
};