import React from "react";
import {Chip, Grid, Stack, Typography} from "@mui/material";
import Functions from "../../../../../utils/Functions";
import {useTranslation} from "react-i18next";
import {Display} from "../../../../Inputs/Display";
import Utils from "../../../../../utils/Utils";

export const AssessmentDetailSection = (
    {
        selectedAssessment,
        sessionType
    }
) => {
    const {t} = useTranslation();
    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    return <>
        <div style={{width: "100%", height: "100%", overflow: "auto"}}>
            <div className="common-board-container">
                <Grid container direction="row" columnSpacing={3} className="h-100">
                    <Grid item xs={12} className="h-100">
                        <div className="transparent standardform-card card-padding-wide h-100"
                             style={{alignItems: "start"}}>
                            <Stack spacing={1} alignItems="start" sx={{width: "100%"}}>
                                <Typography variant="label">{t("Assessment type")}</Typography>
                                <Chip
                                    label={t(selectedAssessment.assessorUser)}
                                    className={Functions.STATUS_EVALUATION_BG[selectedAssessment.assessorUser]}
                                />
                            </Stack>
                            <Display
                                label={t("Assessment Manager")}
                                value={selectedAssessment.assessorFullName}
                            />
                            <Display
                                label={t("Date")}
                                value={Utils.formatDate(selectedAssessment.date)}
                            />
                            <Display
                                label={t("Recommendation level")}
                                value={
                                    selectedAssessment.nps !== null
                                        ? <Chip
                                            label={selectedAssessment.nps}
                                            className={Utils.getRecommendationStatus(parseFloat(selectedAssessment.nps))}
                                        />
                                        : "-"
                                }
                            />
                            {selectedAssessment.comment && (
                                <Display
                                    value={selectedAssessment.comment}
                                />
                            )}
                            {isEnterpriseSession && (
                                <Display
                                    label={t("Shared with the contingent worker")}
                                    value={selectedAssessment.sharedWithTheFreelancer ? t("Yes") : t("No")}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    </>
};