import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import {useTranslation} from "react-i18next";

import './../index.css';
import './../page.css';
import "../../components/Forms/form.css";
import "../../components/Forms/FreelanceProfile/freelanceprofileform.css";

import {SlEnvolopeLetter} from "react-icons/sl";

import logosmall from '../../assets/smarteem_logo_small.png';
import {fetchPublicFreelanceProfile, fetchRequest} from "../../services/enterpriseCollaborationRequestService";
import {HelperCard} from "../../components/UI/HelperCard";
import {MessageComponent} from "../../components/UI/MessageComponent";
import {LanguageSelector} from "../../components/UI/LanguageSelector";
import {useDispatch} from "react-redux";
import SectionMenu from "../../components/UI/SectionMenu";
import {Box, Button, Typography} from "@mui/material";
import ResponsiveComponent from "../../components/UI/ResponsiveComponent";
import Functions from "../../utils/Functions";
import {useFreelanceProfileStore} from "../../store/freelanceProfileStore";
import {ProfileSection} from "../../components/Forms/FreelanceProfile/Sections/ProfileSection/ProfileSection";
import {AvailabilitySection} from "../../components/Forms/FreelanceProfile/Sections/AvailabilitySection";
import {ReferralSection} from "../../components/Forms/FreelanceProfile/Sections/ReferralSection";
import skillService from "../../services/skillService";
import {
    InformationAndDocumentSection
} from "../../components/Forms/FreelanceProfile/Sections/InformationAndDocumentSection/InformationAndDocumentSection";
import {useFormStore} from "../../store/formStore";
import {useToast} from "../../context/ToastProvider";
import {sendReferenceAccessFeedback} from "../../services/referralService";


export const PublicContingentWorker = ({profileSharing = false}) => {
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const {language} = i18n;
    const showToast = useToast();
    const {id} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const {
        freelanceData,
        setFreelanceData
    } = useFreelanceProfileStore();
    const {setShowMandatoryMark, referenceAccessData, setReferenceAccessData} = useFormStore();

    const [message, setMessage] = useState({});
    const [activeSection, setActiveSection] = useState(Functions.FREELANCE_PROFILE_SECTION.PROFILE);
    const [triggerApi, setTriggerApi] = useState(true);

    useEffect(() => {
        return () => {
            setShowMandatoryMark(true);
            setReferenceAccessData({
                referenceAccessRequestCode: false,
                referenceAccessAuthorization: false,
            });
        };
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if (triggerApi) {
                    if (code) {
                        const response = await sendReferenceAccessFeedback({id, code});
                        if (response?.message) {
                            showToast(t(response.message), response.success ? "success" : "warning", 10000);
                        }
                    } else {
                        const action = profileSharing ? fetchRequest : fetchPublicFreelanceProfile;
                        const fetchedData = await action(id);
                        if (!fetchedData?.error) {
                            let freelanceProfileData = fetchedData.FreelanceProfile;

                            if (freelanceProfileData?.FreelanceProfileSkills) {
                                const skills = freelanceProfileData.FreelanceProfileSkills.map(item => item.Skill);
                                freelanceProfileData.FreelanceProfileSkills = skillService.groupSkillsByType(skills);
                            }
                            if (freelanceProfileData?.FreelanceProfileJobKeywords) {
                                freelanceProfileData.FreelanceProfileJobKeywords = freelanceProfileData.FreelanceProfileJobKeywords?.map(item => item.JobKeyword);
                            }

                            const enterpriseUser = fetchedData?.enterpriseUser;

                            if (fetchedData?.id) {
                                setReferenceAccessData({
                                    ...referenceAccessData,
                                    id: fetchedData.id,
                                    referenceAccessRequestCode: fetchedData?.referenceAccessRequestCode ?? false,
                                    referenceAccessAuthorization: fetchedData?.referenceAccessAuthorization ?? false,
                                    firstName: enterpriseUser?.firstName,
                                    lastName: enterpriseUser?.lastName,
                                    email: enterpriseUser?.email,
                                    companyName: enterpriseUser?.EnterpriseProfile?.Company?.name
                                });
                            }

                            setShowMandatoryMark(false);
                            setFreelanceData(freelanceProfileData);

                            dispatch({
                                type: 'TEMPORARY_TOKEN',
                                payload: fetchedData.token,
                                userType: profileSharing ? 'Enterprise' : null
                            });
                        } else {
                            setMessage({type: 'error', message: fetchedData?.error});
                        }
                    }
                    setTriggerApi(false);
                }
            } catch (error) {
                setMessage({type: 'error', message: error.message});
            }
        })();
    }, [triggerApi]);

    const handleButtonClick = (componentName) => {
        setActiveSection(componentName);
    };

    const sectionMenuItems = [
        {key: Functions.FREELANCE_PROFILE_SECTION.PROFILE, name: 'Profile'},
        {key: Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY, name: 'Availabilities'},
        {key: Functions.FREELANCE_PROFILE_SECTION.REFERRAL, name: 'External referrals'}
    ];
    if (profileSharing) {
        sectionMenuItems.push({
            key: Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT,
            name: 'Informations and documents'
        });
    }

    const translation = language === "en"
        ? (
            <>
                <Typography sx={{mb: 1}}>With Smarteem, you can : </Typography>
                <ul className="general-ul">
                    <li>centralize and easily access the information and documents of your contingent workers</li>
                    <li>propose assignments to your contingent workers</li>
                    <li>sign contracts, NDAs and other documents electronically and have them signed by your contingent workers</li>
                    <li>check and validate invoices submitted by your contingent workers</li>
                </ul>
            </>
        )
        : (
            <>
                <Typography sx={{mb: 1}}>Avec Smarteem, vous pouvez :</Typography>
                <ul className="general-ul">
                    <li>centraliser et accéder facilement aux informations et documents de vos collaborateurs externes</li>
                    <li>proposer des missions à vos collaborateurs externes</li>
                    <li>signer et faire signer électroniquement vos contrats, NDA, et autres documents</li>
                    <li>vérifier et valider les factures soumises par vos collaborateurs externes</li>
                </ul>
            </>
        );

    const helperContent = (
        <>
            <Button
                variant="contained"
                color="secondary"
                size="medium"
                onClick={() => navigate("/")}
                fullWidth
            >{t("Create my account on Smarteem")}</Button>
            <HelperCard
                icon={<SlEnvolopeLetter/>}
                headerText={"Why use Smarteem?"}
                descriptionComponent={translation}
            />
        </>
    );

    return (
        <div className="onboarding-row-container layout">
            {code
                ? (
                    <Box
                        className="onboarding-column-container onboarding-column-left w-100"
                        sx={{padding: "4.2% 3% 3% 3%", overflow: "hidden"}}
                    >
                        <img
                            src={logosmall}
                            alt="Smarteem Logo"
                            className="app-logo"
                            onClick={() => navigate("/")}
                        />
                        <h1 className="onboarding-welcome-message">{t("Welcome to Smarteem")}</h1>
                        <div className="onboarding-column-container"><LanguageSelector/></div>
                    </Box>
                )
                : (
                    <>
                        <ResponsiveComponent mobile>
                            <Box sx={{width: "100%", padding: "10px"}}>
                                <img
                                    src={logosmall}
                                    alt="Smarteem Logo"
                                    className="app-logo"
                                    onClick={() => navigate("/")}
                                />
                            </Box>
                        </ResponsiveComponent>

                        <ResponsiveComponent tablet desktop>
                            <div className="onboarding-column-container onboarding-column-left">
                                <img
                                    src={logosmall}
                                    alt="Smarteem Logo"
                                    className="app-logo"
                                    onClick={() => navigate("/")}
                                />
                                <h1 className="onboarding-welcome-message">{t("Welcome to Smarteem")}</h1>
                                {freelanceData?.User && profileSharing && (
                                    <h2 className="onboarding-welcome-message">
                                        {freelanceData.User?.firstName + " " + freelanceData.User?.lastName + t(" shared his profile with you")}
                                    </h2>
                                )}
                                <div className="onboarding-column-container">
                                    <LanguageSelector/>
                                </div>
                            </div>
                        </ResponsiveComponent>

                        <div className="onboarding-column-container onboarding-column-right">
                            <div className="onboarding-card-w100">
                                <div id="freelanceProfileForm">
                                    {Object.keys(freelanceData).length > 0 &&
                                        <div className="form-layout" style={{marginTop: "2vh"}}>
                                            <div className="workflowmenu">
                                                <SectionMenu
                                                    color="info"
                                                    sectionMenuItems={sectionMenuItems}
                                                    activeSection={activeSection}
                                                    onSectionMenuClick={handleButtonClick}
                                                />
                                            </div>
                                            <div className="standardform standardform-w50">
                                                {activeSection === Functions.FREELANCE_PROFILE_SECTION.PROFILE && (
                                                    <ProfileSection
                                                        formData={freelanceData}
                                                        editPermissions={[]}
                                                        canModify={false}
                                                        fullWidth={true}
                                                    />
                                                )}
                                                {activeSection === Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY && (
                                                    <AvailabilitySection
                                                        formData={freelanceData}
                                                        setFormData={setFreelanceData}
                                                        editPermissions={[]}
                                                    />
                                                )}
                                                {activeSection === Functions.FREELANCE_PROFILE_SECTION.REFERRAL && (
                                                    <ReferralSection
                                                        formData={freelanceData}
                                                        setFormData={setFreelanceData}
                                                        canModify={false}
                                                    />
                                                )}
                                                {activeSection === Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT && (
                                                    <InformationAndDocumentSection
                                                        formData={freelanceData}
                                                        setFormData={setFreelanceData}
                                                        canModify={false}
                                                    />
                                                )}
                                            </div>
                                            <div className="helpercard-container">
                                                {helperContent}
                                            </div>
                                        </div>
                                    }
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <MessageComponent type={message.type} message={message.message} width="90%"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
        </div>
    );
};