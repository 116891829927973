import Functions from "../utils/Functions";

const roleHierarchy = {
    [Functions.USER_ROLE.SUPER_ADMIN]: 5,
    [Functions.USER_ROLE.ADMIN]: 4,
    [Functions.USER_ROLE.ADMINISTRATIVE_FINANCIAL]: 3,
    [Functions.USER_ROLE.STANDARD]: 2,
    [Functions.USER_ROLE.FREELANCER]: 1,
};

const modulePlans = {
    [Functions.MODULE.CRM_POOL]: [
        Functions.PLAN.ESSENTIAL,
        Functions.PLAN.STAFFING,
        Functions.PLAN.TALENT,
        Functions.PLAN.PROJECT,
        Functions.PLAN.INTEGRAL
    ],
    [Functions.MODULE.STAFFING]: [
        Functions.PLAN.STAFFING,
        Functions.PLAN.TALENT,
        Functions.PLAN.PROJECT,
        Functions.PLAN.INTEGRAL
    ],
    [Functions.MODULE.ATS]: [
        Functions.PLAN.TALENT,
        Functions.PLAN.INTEGRAL
    ],
    [Functions.MODULE.ASSIGNMENT]: [
        Functions.PLAN.PROJECT,
        Functions.PLAN.INTEGRAL
    ],
    [Functions.MODULE.REPORTING]: [
        Functions.PLAN.STAFFING,
        Functions.PLAN.TALENT,
        Functions.PLAN.PROJECT,
        Functions.PLAN.INTEGRAL
    ],
};

const PermissionService = {
    hasRole: (user, minRole) => {
        if (!user || !user.role || !roleHierarchy[user.role]) return false;

        if (!roleHierarchy[minRole]) {
            throw new Error(`Invalid role: ${minRole}`);
        }

        return roleHierarchy[user.role] >= roleHierarchy[minRole];
    },
    isGranted: (user, module, requiredPlans = []) => {
        if (!user || !user.role || !roleHierarchy[user.role]) return false;

        if(user.role === Functions.USER_ROLE.FREELANCER) return true;

        if (!requiredPlans.length) {
            requiredPlans = modulePlans[module] || [];
        }

        if (!requiredPlans.length) return true;

        return requiredPlans.includes(user.plan);
    }
};

export default PermissionService;