import React from "react";
import {useTranslation} from "react-i18next";
import {FaUserPlus} from "react-icons/fa";

import {HelperCard} from "../../../UI/HelperCard";
import {FieldHolder} from "../../FieldHolder";

import "./freelanceinfostep.css";
import {Grid} from "@mui/material";

export const FreelanceInfoStep = ({messageData, setMessageData, canModify}) => {
    const {t} = useTranslation();

    const handleChange = (e) => {
        setMessageData(e.target.value);
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <HelperCard
                        icon={<FaUserPlus/>}
                        headerText={"You have filled in all the necessary fields to add a contingent worker to Smarteem"}
                        descriptionText={"The contingent worker will receive an email asking them to complete their profile on Smarteem."}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Message to the contingent worker")}
                        type="multiline"
                        name="message"
                        id="message"
                        placeholder={t("Message")}
                        value={messageData}
                        onChange={handleChange}
                        canModify={canModify}
                        mandatoryFlag={false}
                    />
                </Grid>
            </Grid>
        </>
    );
};
