import React, {forwardRef, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Functions from "../../../../../utils/Functions";
import {createEvaluation, fetchEvaluationsByMission} from "../../../../../services/freelanceEvaluationService";
import {Table} from "../../../../Tables/Table";
import Utils from "../../../../../utils/Utils";
import {Box, Button, Chip, Grid, Stack, Typography} from "@mui/material";
import {MessageComponent} from "../../../../UI/MessageComponent";
import {FieldHolder} from "../../../FieldHolder";
import Popup from "../../../../Popup/Popup";
import {AssessmentForm} from "./AssessmentForm";
import {AssessmentDetailSection} from "./AssessmentDetailSection";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const AssessmentListSection = forwardRef((
    {
        formData,
        setFormData,
        objectAttachedType,
        isSection = true,
        sessionType,
        canModify
    },
    ref
) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);

    const [message, setMessage] = useState({});
    const [showAssessmentDialog, setShowAssessmentDialog] = useState(false);
    const [showAskSatisfactionDialog, setShowAskSatisfactionDialog] = useState(false);
    const [selectedAssessment, setSelectedAssessment] = useState({});

    const defaultFormDataAssessment = {
        assessorFirstName: connectedUser.userFirstName,
        assessorLastName: connectedUser.userLastName,
        assessorUser: Functions.SESSION_TYPE_ENTERPRISE,
        MissionId: formData?.id,
        date: "",
        nps: "",
        comment: ""
    };
    const [formDataAssessment, setFormDataAssessment] = useState(defaultFormDataAssessment);

    const defaultFormDataAskSatisfaction = {
        assessorFirstName: "",
        assessorLastName: "",
        assessorUserEmail: ""
    };
    const [formDataAskSatisfaction, setFormDataAskSatisfaction] = useState(defaultFormDataAskSatisfaction);

    const [assessments, setAssessments] = useState([]);
    const tableRef = useRef();
    const fetchAssessmentsRef = useRef();
    const [rowCount, setRowCount] = useState(0);
    const defaultFilterConfigs = {
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    };
    const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);

    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    const columns = [
        {
            field: 'assessorUser',
            type: 'singleSelect',
            headerName: t("Assessment type"),
            minWidth: 120,
            flex: 1.2,
            headerAlign: 'center',
            align: 'center',
            valueOptions: [
                {value: Functions.TEXT_CUSTOMER, label: t(Functions.TEXT_CUSTOMER)},
                {value: Functions.TEXT_MAIN_TYPE_ENTREPRISE, label: t(Functions.TEXT_MAIN_TYPE_ENTREPRISE)},
            ],
            renderCell: ({row}) => {
                return <Chip
                    label={t(row.assessorUser)}
                    title={t(row.assessorUser)}
                    size="small"
                    className={Functions.STATUS_EVALUATION_BG[row.assessorUser]}
                />
            }
        },
        {
            field: 'assessorFullName',
            type: 'string',
            headerName: t("Assessment Manager"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'left',
        },
        {
            field: 'date',
            type: 'date',
            headerName: t("Date"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => row.date && new Date(row.date),
            renderCell: ({row}) => {
                return Utils.formatDate(row.date);
            }
        },
        {
            field: 'nps',
            type: 'number',
            headerName: t("Recommendation level"),
            minWidth: 120,
            flex: 1.2,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return row.nps !== null ? <Chip
                    label={row.nps}
                    className={Utils.getRecommendationStatus(parseFloat(row.nps))}
                /> : <></>;
            }
        }
    ];

    if (isEnterpriseSession) {
        columns.push({
            field: 'sharedWithTheFreelancer',
            type: 'boolean',
            headerName: t("Shared with the contingent worker"),
            minWidth: 120,
            flex: 1.2,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return row.sharedWithTheFreelancer ? t("Yes") : t("No");
            }
        });
    }

    useEffect(() => {
        fetchAssessmentsRef.current(filterConfigs);
    }, [formData.id, filterConfigs]);

    fetchAssessmentsRef.current = async (query = {}) => {
        try {
            if (formData?.id) {
                const fetchedAssessments = await fetchEvaluationsByMission(formData.id, query, token);
                if (!fetchedAssessments?.error) {
                    setRowCount(fetchedAssessments.count);
                    setAssessments(fetchedAssessments.data);
                }
            }
        } catch (error) {
        }
    };

    const updateSectionStatus = () => {
        const newSectionStatus = Utils.updateStatusSection(
            formData.status_section,
            Functions.SECTION_FREELANCE_EVALUATION
        );
        setFormData({...formData, status_section: newSectionStatus});
    };

    const handleAskSatisfactionChange = (e) => {
        const {name, value} = e.target;
        setFormDataAskSatisfaction({...formDataAskSatisfaction, [name]: value});
    };

    const handleAskSatisfactionSave = async (e) => {
        e.preventDefault();

        let data = {
            ...formDataAskSatisfaction,
            MissionId: formData?.id,
            assessorUser: Functions.TEXT_CUSTOMER,
            date: new Date()
        };

        const verifyData = Utils.verifyDataAssessment(t, data, true);
        if (verifyData.type === "error") {
            setMessage(verifyData);
        } else {
            try {
                setMessage({type: "loading", message: t("Saving data")});

                const result = await createEvaluation(data, token);
                if (result.error) {
                    setMessage({type: "error", message: result.error});
                } else {
                    setShowAskSatisfactionDialog(false);
                    handleInitList();
                    updateSectionStatus();
                }
            } catch (error) {

            }
        }
    };

    const handleAssessmentSaveFinish = (success) => {
        if (success) {
            setShowAssessmentDialog(false);
            handleInitList();
            updateSectionStatus();
        }
    };

    const handleAssessmentDialogShow = () => {
        setFormDataAssessment(defaultFormDataAssessment);
        setShowAssessmentDialog(true);
    };

    const handleAskSatisfactionDialogShow = () => {
        setMessage({});
        setFormDataAskSatisfaction(defaultFormDataAskSatisfaction);
        setShowAskSatisfactionDialog(true);
    };

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    const handleAssessmentCellClick = (row) => {
        setSelectedAssessment(row);
    };

    const handleInitList = () => {
        setSelectedAssessment({});
        setFormDataAskSatisfaction(defaultFormDataAskSatisfaction);
        tableRef.current && tableRef.current.initTable();
        setFilterConfigs(defaultFilterConfigs);
    };

    if (ref) {
        ref.current = handleInitList;
    }

    return <>
        <div className="card standardform-card card-padding-wide" style={{display: "block", height: "100%"}}>
            {Object.keys(selectedAssessment).length === 0 && (
                <div className="common-board-container" style={{width: "100%"}}>
                    <Stack direction="row" justifyContent="space-between" spacing={1}>
                        <Typography variant="labelInfo">
                            {t("Assessments list")}
                        </Typography>
                        {canModify && (
                            <Stack direction="row" spacing={1} sx={{justifyContent: {xs: "start", md: "flex-end"}}}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleAssessmentDialogShow}
                                >
                                    {t("Add an assessment")}
                                </Button>
                                <Popup open={showAssessmentDialog}
                                       title={t("Evaluate the contingent worker's performance")}
                                       onDialogClose={() => setShowAssessmentDialog(false)}
                                >
                                    <Box sx={{width: {md: "38vw"}}}>
                                        <AssessmentForm
                                            assessment={formDataAssessment}
                                            canModify={canModify}
                                            onSaveFinish={handleAssessmentSaveFinish}
                                        />
                                    </Box>
                                </Popup>

                                <Button
                                    type="button"
                                    variant="contained"
                                    color="info"
                                    onClick={handleAskSatisfactionDialogShow}
                                >
                                    {t("Ask assessment to a customer")}
                                </Button>
                                <Popup open={showAskSatisfactionDialog}
                                       title={t("Ask a customer to evaluate the service")}
                                       onDialogClose={() => setShowAskSatisfactionDialog(false)}
                                >
                                    <Box sx={{width: {md: "30vw"}}}>
                                        <Grid container direction="column" spacing={2}>
                                            {message.type &&
                                                <Grid item xs={12}>
                                                    <MessageComponent
                                                        type={message.type}
                                                        message={message.message}
                                                        width="100%"
                                                    />
                                                </Grid>
                                            }

                                            <Grid item container xs={12} spacing={2}>
                                                <Grid item xs={6}>
                                                    <FieldHolder
                                                        fielddesc={t('Customer first name')}
                                                        type="text"
                                                        name="assessorFirstName"
                                                        id="assessorFirstName"
                                                        placeholder={t('Customer first name')}
                                                        value={formDataAskSatisfaction.assessorFirstName}
                                                        onChange={handleAskSatisfactionChange}
                                                        canModify={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FieldHolder
                                                        fielddesc={t('Customer last name')}
                                                        type="text"
                                                        name="assessorLastName"
                                                        id="assessorLastName"
                                                        placeholder={t('Customer last name')}
                                                        value={formDataAskSatisfaction.assessorLastName}
                                                        onChange={handleAskSatisfactionChange}
                                                        canModify={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FieldHolder
                                                    fielddesc={t('Email')}
                                                    type="email"
                                                    name="assessorUserEmail"
                                                    id="assessor_user_email"
                                                    placeholder={"Email"}
                                                    value={formDataAskSatisfaction.assessorUserEmail}
                                                    onChange={handleAskSatisfactionChange}
                                                    canModify={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={{display: "flex", justifyContent: "center", mt: 2}}>
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={handleAskSatisfactionSave}
                                                >
                                                    {t("Send")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Popup>
                            </Stack>
                        )}
                    </Stack>

                    <Box className="table-layout">
                        <Table
                            fullHeight={isSection}
                            ref={tableRef}
                            columns={columns}
                            data={assessments}
                            rowCount={rowCount}
                            pageSize={pageSize}
                            filterConfigs={filterConfigs}
                            onChange={handleDataGridChange}
                            onCellClick={handleAssessmentCellClick}
                        />
                    </Box>
                </div>
            )}
            {Object.keys(selectedAssessment).length > 0 && (
                <AssessmentDetailSection
                    formData={formData}
                    selectedAssessment={selectedAssessment}
                    sessionType={sessionType}
                />
            )}
        </div>
    </>
});