import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import useFileOpener from "../../../../../hooks/customHooks";
import Functions from "../../../../../utils/Functions";
import {Box, Button, Chip, Grid, IconButton, Popover, Stack, Typography} from "@mui/material";
import Utils from "../../../../../utils/Utils";
import {Display} from "../../../../Inputs/Display";
import {MessageComponent} from "../../../../UI/MessageComponent";
import {MdCircle, MdClose, MdMail, MdOutlineFileDownload} from "react-icons/md";
import {PDFViewer2} from "../../../../Pdf/PDFViewer2";
import {fetchFile} from "../../../../../services/fileService";
import missionRxjs from "../../../../../services/missionRxjs";
import {useToast} from "../../../../../context/ToastProvider";
import Popup from "../../../../Popup/Popup";
import "../missioncontent.css";
import InvoiceSendFileForm from "./InvoiceSendFileForm";
import {changeManyInvoiceStatus} from "../../../../../pages/Mission/Invoice/SelectedInvoiceFooter";
import PermissionService from "../../../../../services/permissionService";

export const InvoiceDetailSection = (
    {
        selectedInvoice,
        setSelectedInvoice,
        isSection = true,
        sessionType
    }
) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const showToast = useToast();
    const {loading, openFileInNewTab} = useFileOpener();

    const [message, setMessage] = useState({});
    const [showSendInvoiceDialog, setShowSendInvoiceDialog] = useState(false);

    const [activeFile, setActiveFile] = useState(null);
    const [activeFileName, setActiveFileName] = useState(null);
    const [sendInvoiceData, setSendInvoiceData] = useState({email: '', message: ''});
    const [sendHistoriesAnchorEl, setSendHistoriesAnchorEl] = useState(null);

    const sendHistoriesOpen = Boolean(sendHistoriesAnchorEl);
    const invoiceSendHistories = selectedInvoice?.InvoiceSendHistories ?? [];

    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;
    const isFreelanceSession = sessionType === Functions.SESSION_TYPE_FREELANCE;

    const isRejectedStatus = [Functions.TEXT_REJECTED, Functions.TEXT_CANCELED].includes(selectedInvoice?.status);
    const isOpenStatus = selectedInvoice?.status === Functions.TEXT_OPEN;
    const isValidatedStatus = selectedInvoice?.status === Functions.TEXT_VALIDATED;
    const isPaidStatus = selectedInvoice?.status === Functions.TEXT_PAID;

    useEffect(() => {
        const selectedFile = missionRxjs.getFactureFileObservable();
        const subscriptionFile = selectedFile.subscribe((data) => {
            if (data != null) {
                const urlFileBlob = URL.createObjectURL(data.theFile);
                setActiveFile(urlFileBlob);
            }
        });

        return () => {
            subscriptionFile.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (selectedInvoice?.id) {
            (async () => {
                await loadInvoiceFile(selectedInvoice);
            })();
        }
    }, [selectedInvoice.id]);


    const handleSendHistoriesPopoverOpen = (event) => {
        setSendHistoriesAnchorEl(event.currentTarget);
    };

    const handleSendHistoriesPopoverClose = () => {
        setSendHistoriesAnchorEl(null);
    };

    const handleSaveFinish = (success, data = {}) => {
        if (success) {
            showToast(t("The invoice has been sent"));

            const invoiceSendHistories = selectedInvoice.InvoiceSendHistories ?? [];
            invoiceSendHistories.push({email: data.email, createdAt: new Date()});

            setSelectedInvoice({
                ...selectedInvoice,
                InvoiceSendHistories: invoiceSendHistories
            });
        }
    };
    const loadInvoiceFile = async (invoice) => {
        try {
            setMessage({type: "loading", message: t("Fetching data")});

            const fetchedFile = await fetchFile(invoice.file, Functions.TEXT_DOCUMENT, token);
            setTimeout(() => {
                if (fetchedFile?.error) {
                    setMessage({type: "error", message: fetchedFile.error});
                } else {
                    setActiveFile(URL.createObjectURL(fetchedFile));
                    setActiveFileName(invoice.file);
                    setMessage({});
                }
            }, 1000);
        } catch (error) {
            setMessage({
                type: "error",
                message: t("File removed or moved"),
            });
        }
    };

    const handleClickAction = async (status) => {
        setMessage({});
        await changeManyInvoiceStatus({
            invoiceIds: [selectedInvoice.id],
            status,
            token,
            callback: (response) => {
                if (!response?.error) {
                    setSelectedInvoice({...selectedInvoice, status});
                    showToast(t("Invoice(s) successfully updated"));
                } else {
                    showToast(t("Error saving data"), "error");
                }
            }
        });
    };

    const hasInvoiceHeader = () => {
        if (isSection && !isRejectedStatus && !isPaidStatus) {
            if (isEnterpriseSession) {
                if (
                    isOpenStatus ||
                    (
                        isValidatedStatus &&
                        PermissionService.hasRole(connectedUser, Functions.USER_ROLE.ADMINISTRATIVE_FINANCIAL)
                    )
                ) {
                    return true;
                }
            }
            if (isFreelanceSession && isOpenStatus) {
                return true;
            }
        }
        return false;
    };

    const getInvoiceHeader = () => {
        if (!isSection || (!isRejectedStatus && !isPaidStatus)) {
            return (
                <Stack
                    direction={{xs: "column", md: "row"}}
                    alignItems="start"
                    justifyContent="space-between"
                    sx={{width: "100%"}}
                    spacing={!isSection ? 2 : 0}
                >
                    <h1 className="pagetitle mb-0">{!isSection ? selectedInvoice.name : ""}</h1>
                    <Stack direction="row" justifyContent="end" columnGap={1}>
                        {isEnterpriseSession && (
                            <>
                                {isOpenStatus && (
                                    <>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="light"
                                            onClick={() => handleClickAction(Functions.TEXT_REJECTED)}
                                        >
                                            {t("Reject the invoice")}
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleClickAction(Functions.TEXT_VALIDATED)}
                                        >
                                            {t("Validate the invoice")}
                                        </Button>
                                    </>
                                )}
                                {isValidatedStatus && (
                                    <>
                                        {PermissionService.hasRole(connectedUser, Functions.USER_ROLE.ADMINISTRATIVE_FINANCIAL) && (
                                            <Button
                                                type="button"
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleClickAction(Functions.TEXT_PAID)}
                                            >
                                                {t("Mark invoice as paid")}
                                            </Button>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {isFreelanceSession && isOpenStatus && (
                            <Button
                                type="button"
                                variant="contained"
                                color="light"
                                onClick={() => handleClickAction(Functions.TEXT_CANCELED)}
                            >
                                {t("Cancel this invoice")}
                            </Button>
                        )}
                    </Stack>
                </Stack>
            );
        }

        return null;
    };

    const handleFileOpen = async (value) => {
        await openFileInNewTab(value, Functions.TEXT_DOCUMENT, token);
    };

    return (
        <div style={{width: "100%", height: "100%", overflow: "auto"}}>
            <div className="common-board-container">
                {getInvoiceHeader()}

                <Grid container
                      direction="row"
                      columnSpacing={3}
                      sx={{height: `${hasInvoiceHeader() ? "calc(100% - 80px)" : "100%"}`}}
                >
                    <Grid item xs={3} className="h-100">
                        <div className="box-item standardform-card card-padding-wide h-100">
                            {selectedInvoice.id && (
                                <>
                                    <Stack spacing={1} alignItems="center" sx={{width: "100%"}}>
                                        <Typography variant="labelInfo">{selectedInvoice.name}</Typography>
                                        <Chip
                                            label={t(Functions.STATUS_INVOICE[selectedInvoice.status])}
                                            className={Functions.STATUS_INVOICE_BG[selectedInvoice.status]}
                                            sx={{minWidth: "8rem"}}
                                        />
                                    </Stack>
                                    <Display
                                        label={t("Amount")}
                                        value={`${selectedInvoice.amount} ${Utils.getCurrencySymbol(selectedInvoice.devise)}`}
                                    />
                                    <Display
                                        label={isEnterpriseSession ? t("Received the") : t("Sent on")}
                                        value={Utils.formatDate(selectedInvoice.createdAt)}
                                    />
                                    <Display
                                        label={t("Due date")}
                                        value={Utils.formatDate(selectedInvoice.dueDate)}
                                    />
                                    <Display
                                        label={t("Invoice.Validated")}
                                        value={
                                            selectedInvoice.validatedDate
                                                ? `${t("Yes")} - ${t("the")} ${Utils.formatDate(selectedInvoice.validatedDate)}`
                                                : t("No")
                                        }
                                    />
                                    <Display
                                        label={t("Paid")}
                                        value={
                                            selectedInvoice.paymentDate
                                                ? `${t("Yes")} - ${t("the")} ${Utils.formatDate(selectedInvoice.paymentDate)}`
                                                : t("No")
                                        }
                                    />

                                    {isEnterpriseSession && (
                                        <Stack direction="column" alignItems="flex-start" spacing={0.5}
                                               sx={{maxWidth: '100%'}}>
                                            <Typography variant="label"><span>{t("Sent by e-mail")}</span></Typography>
                                            <Stack className="MuiTypography-body1">
                                                {selectedInvoice?.InvoiceSendHistories?.length > 0
                                                    ? (
                                                        <>
                                                            <Typography
                                                                onClick={handleSendHistoriesPopoverOpen}
                                                                sx={{cursor: "pointer"}}
                                                            >{t("Yes")}</Typography>
                                                            <Popover
                                                                open={sendHistoriesOpen}
                                                                anchorEl={sendHistoriesAnchorEl}
                                                                sx={{pointerEvents: "none"}}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}
                                                            >
                                                                <Box sx={{p: 1, width: "280px"}}>
                                                                    <Stack className="w-100" alignItems="end">
                                                                        <IconButton
                                                                            onClick={handleSendHistoriesPopoverClose}
                                                                            size="small"
                                                                            sx={{pointerEvents: "all"}}
                                                                        >
                                                                            <MdClose size={12}/>
                                                                        </IconButton>
                                                                    </Stack>
                                                                    <Stack direction="column" spacing={0.5}
                                                                           sx={{px: 0.5}}>
                                                                        {invoiceSendHistories.map((history, index) => {
                                                                            return <Typography key={index}
                                                                                               sx={{fontSize: "14px"}}>
                                                                                <MdCircle size={5}
                                                                                          style={{marginRight: "5px"}}/>
                                                                                {t("Sent on {{date}} to {{email}}", {
                                                                                    date: Utils.formatDate(history.createdAt),
                                                                                    email: history.email
                                                                                })}
                                                                            </Typography>
                                                                        })}
                                                                    </Stack>
                                                                </Box>
                                                            </Popover>
                                                        </>
                                                    )
                                                    : t("No")
                                                }
                                            </Stack>
                                        </Stack>
                                    )}

                                    {selectedInvoice.supportingDocument &&
                                        <Display
                                            type="file"
                                            label={t("Invoice receipt")}
                                            value={selectedInvoice.supportingDocument}
                                        />
                                    }
                                    {selectedInvoice.commentFreelance &&
                                        <Display
                                            label={t("Message")}
                                            value={selectedInvoice.commentFreelance}
                                        />
                                    }
                                </>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs className="h-100">
                        <div className="box-item standardform-card card-padding-wide h-100"
                             style={{alignItems: "center"}}
                        >
                            {selectedInvoice.status && (
                                <>
                                    <Box sx={{width: "92%"}}>
                                        <MessageComponent
                                            type={message.type}
                                            message={message.message}
                                            width="100%"
                                        />
                                        {
                                            activeFileName &&
                                            ![Functions.TEXT_REJECTED, Functions.TEXT_CANCELED].includes(selectedInvoice.status) && (
                                                <Stack direction="row" justifyContent="start"
                                                       spacing={0.5}
                                                       sx={{width: "100%"}}>
                                                    <IconButton
                                                        disabled={loading}
                                                        color="primary"
                                                        title={t("Download")}
                                                        onClick={() => handleFileOpen(activeFileName)}
                                                    >
                                                        <MdOutlineFileDownload size={30}/>
                                                    </IconButton>

                                                    {isEnterpriseSession && (
                                                        <>
                                                            <IconButton
                                                                color="primary"
                                                                title={t("Send by e-mail")}
                                                                onClick={() => setShowSendInvoiceDialog(true)}
                                                            >
                                                                <MdMail size={30}/>
                                                            </IconButton>

                                                            <Popup
                                                                open={showSendInvoiceDialog}
                                                                title={t("Send by e-mail")}
                                                                onDialogClose={() => setShowSendInvoiceDialog(false)}
                                                            >
                                                                <InvoiceSendFileForm
                                                                    invoiceIds={[selectedInvoice.id]}
                                                                    onSaveFinish={handleSaveFinish}
                                                                    onClose={() => setShowSendInvoiceDialog(false)}
                                                                />
                                                            </Popup>
                                                        </>
                                                    )}
                                                </Stack>
                                            )
                                        }
                                    </Box>
                                    <div className="common-board-pdfviewerbox">
                                        <PDFViewer2
                                            activeFile={activeFile}
                                            fileName={activeFileName}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};