import React, {useState, useEffect, useRef} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Box, IconButton, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {FieldHolder} from "../Forms/FieldHolder";
import {MdAdd, MdCheck, MdClose, MdMoreHoriz} from "react-icons/md";

import './kanban.css';

export const Kanban = ({columns, datas, KanbanItemComponent, onItemClick, isDragDisabled = true}) => {

    const handleCellClick = ({id}) => {
        onItemClick?.(id);
    };

    return (
        <Box id="kanban" sx={{width: "100%", height: "100%"}}>
            <DragDropContext onDragEnd={() => console.log('drag end')}>
                <Droppable droppableId="board" direction="horizontal" type="BOARD">
                    {(provided) => (
                        <Stack
                            direction="row"
                            className="w-100 h-100"
                            gap={2.5}
                            sx={{overflowX: 'auto', pb: 1}}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {columns.map((column, columnIndex) => (
                                <Draggable
                                    key={column.key}
                                    draggableId={`column-${column.key}`}
                                    index={columnIndex}
                                    isDragDisabled={Boolean(column.isReadOnly || column.default || isDragDisabled)}
                                >
                                    {(provided) => (
                                        <Stack
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            className="h-100"
                                            gap={1}
                                            sx={{width: '320px'}}
                                        >
                                            <KanbanColumn
                                                provided={provided}
                                                column={column}
                                                onEdit={() => console.log('column edit')}
                                                onDelete={() => console.log('column delete')}
                                            />
                                            {!column.default && (
                                                <Droppable droppableId={`column-${column.key}`} type="ITEM">
                                                    {(provided, snapshot) => (
                                                        <Box
                                                            ref={provided.innerRef}
                                                            {...provided.droppableProps}
                                                            className="kanban-item-container"
                                                        >
                                                            {datas[column.key]?.map((data, dataIndex) => (
                                                                <Draggable
                                                                    key={data.id}
                                                                    draggableId={`item-${data.id}`}
                                                                    index={dataIndex}
                                                                    isDragDisabled={Boolean(isDragDisabled)}
                                                                >
                                                                    {(provided) => (
                                                                        <Box
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            onDoubleClick={() => handleCellClick(data)}
                                                                        >
                                                                            <KanbanItemComponent data={data}/>
                                                                        </Box>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {snapshot.isDraggingOver && (
                                                                <Box
                                                                    className="kanban-item"
                                                                    sx={{
                                                                        height: '100px',
                                                                        border: 'none',
                                                                        boxShadow: 'none',
                                                                        opacity: 0.5,
                                                                        transition: 'background-color 0.2s ease',
                                                                    }}
                                                                />
                                                            )}
                                                        </Box>
                                                    )}
                                                </Droppable>
                                            )}
                                        </Stack>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Stack>
                    )}
                </Droppable>
            </DragDropContext>
        </Box>
    );
};

const KanbanColumn = ({provided, column, onEdit, onDelete}) => {
    const {t} = useTranslation();

    const [editMode, setEditMode] = useState(false);
    const [columnData, setColumnData] = useState(column);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const formRef = useRef(null);

    const isDefault = Boolean(columnData?.default);

    useEffect(() => {
        if (editMode && formRef.current) {
            const titleField = formRef.current.elements.title;
            if (titleField) {
                titleField.focus();
                titleField.select();
            }
        }
    }, [editMode]);

    useEffect(() => {
        setColumnData(column);
    }, [column]);

    const handleMenuClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = () => {
        if (columnData.isReadOnly) return;
        setEditMode(true);
    };

    const handleBlur = (e) => {
        const {name, value} = e.target;
        setColumnData((prevData) => ({...prevData, [name]: value}));
        if (!columnData.default || !value) setEditMode(false);
        if (!columnData.default) {
            onEdit && onEdit({...columnData, [name]: value});
        }
    };

    const handleSave = () => {
        setEditMode(false);
        onEdit && onEdit(columnData);
    };

    const handleDelete = () => {
        handleMenuClose();
        if (columnData?.key) {
            onDelete && onDelete(columnData.key);
        }
    };

    return (
        <form ref={formRef} noValidate>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                className={`kanban-column-item ${column.color}`}
            >
                <Box className="w-100">
                    {editMode
                        ? (
                            <FieldHolder
                                type="text"
                                name="title"
                                id={`columnTitle${columnData.key || ""}`}
                                defaultValue={!isDefault ? t(columnData.title) : ""}
                                onBlur={handleBlur}
                                canModify={true}
                            />
                        )
                        : (
                            <Box {...provided.dragHandleProps}>
                                <Typography
                                    className="Mui-textTruncate"
                                    title={t(columnData.title)}
                                    onClick={isDefault ? handleItemClick : undefined}
                                    onDoubleClick={!isDefault ? handleItemClick : undefined}
                                    sx={{px: '14px', py: '5px'}}
                                >
                                    {isDefault
                                        ? (<><MdAdd size={18}/> {t("Add a column")}</>)
                                        : t(columnData.title)

                                    }
                                </Typography>
                            </Box>
                        )
                    }
                </Box>
                {!column.isReadOnly && !isDefault && (
                    <Box>
                        <IconButton
                            aria-controls="item-actions-menu"
                            aria-haspopup="true"
                            onClick={handleMenuClick}
                            size="small"
                        >
                            <MdMoreHoriz size={20}/>
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleDelete}>
                                <Typography color="secondary">{t("Delete")}</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                )}
                {editMode && column.default && (
                    <Stack direction="row" alignItems="center" gap={0.5}>
                        <IconButton
                            title={t("Save")}
                            onClick={handleSave}
                            color="secondary"
                            size="small"
                            sx={{border: '1px solid var(--secondary-main-color)', p: '2px'}}
                        >
                            <MdCheck size={20}/>
                        </IconButton>
                        <IconButton
                            title={t("Cancel")}
                            onClick={() => setEditMode(false)}
                            color="light"
                            size="small"
                            sx={{border: '1px solid var(--light-main-color)', p: '2px'}}
                        >
                            <MdClose size={20}/>
                        </IconButton>
                    </Stack>
                )}
            </Stack>
        </form>
    );
};