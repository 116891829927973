import React from "react";
import {Box, Chip, Grid, Stack, Typography} from "@mui/material";
import Functions from "../../../../../utils/Functions";
import {useTranslation} from "react-i18next";
import {Display} from "../../../../Inputs/Display";
import Utils from "../../../../../utils/Utils";
import {FreelanceSatisfactionForm} from "./FreelanceSatisfactionForm";

export const FreelanceSatisfactionDetailSection = (
    {
        selectedFeedback,
        sessionType,
        onInitList
    }
) => {
    const {t} = useTranslation();

    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    const handleFeedbackSaveFinish = (success) => {
        if (success) {
            onInitList && onInitList();
        }
    };

    return <>
        <div style={{width: "100%", height: "100%", overflow: "auto"}}>
            <div className="common-board-container">
                <Grid container direction="row" columnSpacing={3} className="h-100">
                    <Grid item xs={12} className="h-100">
                        <div className="transparent standardform-card card-padding-wide h-100"
                             style={{alignItems: "start"}}>
                            {
                                isEnterpriseSession ||
                                selectedFeedback?.assessmentFreelanceStatus === Functions.TEXT_VALIDATED
                                    ? (
                                        <>
                                            <Stack spacing={1} alignItems="start" sx={{width: "100%"}}>
                                                <Typography variant="label">{t("Status")}</Typography>
                                                <Chip
                                                    label={t(Functions.STATUS_FEEDBACK[selectedFeedback.assessmentFreelanceStatus])}
                                                    className={Functions.STATUS_FEEDBACK_BG[selectedFeedback.assessmentFreelanceStatus]}
                                                />
                                            </Stack>
                                            <Display
                                                label={t("Contingent worker")}
                                                value={selectedFeedback.Mission?.StaffedFreelance?.User.fullName}
                                            />
                                            <Display
                                                label={t("Date")}
                                                value={Utils.formatDate(selectedFeedback.createdAt)}
                                            />
                                            <Display
                                                label={t("Satisfaction level")}
                                                value={
                                                    selectedFeedback.nps !== null
                                                        ? <Chip
                                                            label={selectedFeedback.nps}
                                                            className={Utils.getRecommendationStatus(parseFloat(selectedFeedback.nps))}
                                                        />
                                                        : "-"
                                                }
                                            />
                                            {selectedFeedback.comment && (
                                                <Display
                                                    value={selectedFeedback.comment}
                                                />
                                            )}
                                        </>
                                    )
                                    : (
                                        <Box sx={{width: "100%"}}>
                                            <FreelanceSatisfactionForm
                                                feedback={selectedFeedback}
                                                canModify={true}
                                                onSaveFinish={handleFeedbackSaveFinish}
                                            />
                                        </Box>
                                    )
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    </>
};